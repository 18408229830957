import React from 'react';

const TextComponent = ({ text, alphaLimit }) => {
    const truncatedText = truncateTextByAlphaCount(text, alphaLimit);

    return (
        <>
            {truncatedText}
        </>
    );
};

export default TextComponent;


function truncateTextByAlphaCount(text, alphaLimit) {
    let alphaCount = 0;
    let i = 0;

    for (i = 0; i < text.length; i++) {
        if (/[a-zA-Z]/.test(text[i])) {
            alphaCount++;
        }
        if (alphaCount >= alphaLimit) {
            break;
        }
    }

    if (i < text.length - 1) {
        return text.slice(0, i + 1) + '...';
    }
    return text;
}