import $ from "jquery";


/*------------------back to top------------------*/
$(window).on("scroll", function() {
    var mainMenuTop = $(".header");
    if ($(window).scrollTop() >= 1) {
        mainMenuTop.addClass('navbar-area-fixed');
    } else {
        mainMenuTop.removeClass('navbar-area-fixed');
    }
    // 
    var ScrollTop = $('.back-to-top');
    if ($(window).scrollTop() > 1000) {
        ScrollTop.fadeIn(1000);
    } else {
        ScrollTop.fadeOut(1000);
    }
});

/*------------------back to top------------------*/
$(document).ready(function() {
    $(document).on('click', '.back-to-top', function() {
        $("html,body").animate({
            scrollTop: 0
        }, 200);
    });
});

$(window).on("scroll", function() {
    // 
    var ScrollTop = $('.back-to-top');
    if ($(window).scrollTop() > 1000) {
        ScrollTop.fadeIn(1000);
    } else {
        ScrollTop.fadeOut(1000);
    }
});