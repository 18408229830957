import React, { useEffect } from 'react';
import { Col, Row, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import thankyou from '../images/thankyou.png';
import userIcon from '../images/userIcon1.svg';
import breifIcon from '../images/breifIcon.svg';
import timeIcon from '../images/timeIcon.svg';
import meetgreetIcon from '../images/meetgreetIcon.svg';
import emailicon from '../images/emailwhite.png';
import phoneicon from '../images/phonewhite.png';
const BookingConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!data) {
      navigate('/');
    }
  }, [data, navigate]);

  // Check if data is undefined and return null (or a loading spinner) to prevent rendering errors
  if (!data) return null;

  const bookingDetailsData = data;
  console.log(bookingDetailsData);
  // Function to ensure the mobile number has a plus sign
  const ensurePlusSign = (number) => {
    if (number && !number.startsWith('+')) {
      return `+${number}`;
    }
    return number;
  };
  return (
    <>
      <section className='py-4 color1BG dropShadow resultPageTopRow' style={{ position: 'relative' }}>
        <Row xxl={'11'} xl={'11'} className='col-11 mx-auto'>
          <Col xxl={'11'} xl={'11'} className='mb-3 mb-xl-0'>
            <div className='d-flex align-items-center mb-5' style={{ gap: "10px" }}>
              <img src={thankyou} alt="thankyou" style={{ width: "72px" }} />
              <div>
                <b>Thank You for booking with Limowide</b>
                <p className='m-0'>A confirmation mail has been sent to your registered  E-mail address</p>
              </div>

            </div>
            <h6 className={'secondaryText fw-bold mb-4 ms-2'} style={{ fontSize: '22px' }}>Booking Summary </h6>

          </Col>
          <Col xxl={'8'} xl={'8'} className={'mb-4 mb-xl-0'}>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Booking Details</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PICKUP LOCATION</label><br />
                    <b>{bookingDetailsData?.pickup ?? ''}</b>
                  </Col>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                    <></>
                    :
                    <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                      <label>DROPOFF LOCATION</label><br />
                      <b>{bookingDetailsData?.destination}</b>
                    </Col>
                  }
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>DATE & TIME</label><br />
                    <b>{bookingDetailsData?.pickupDate}</b>
                  </Col>
                    {
                      bookingDetailsData?.return_date_time ? (
                        <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                          <label>RETURN DATE & TIME</label><br />
                          <b>{bookingDetailsData.return_date_time}</b>
                        </Col>
                      ) : null
                    }
   
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>BOOKING ID</label><br />
                    <b>{bookingDetailsData?.bookingId ?? ""}</b>
                  </Col>
                    {
                      bookingDetailsData?.return_booking_id ? (
                        <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                          <label>RETURN BOOKING ID</label><br />
                          <b>{bookingDetailsData.return_booking_id}</b>
                        </Col>
                      ) : null
                    }

                
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                      <><label>DURATION SELECTED</label><br /></> : <><label>ESTIMATED TRIP TIME</label><br /></> 
                    }
                    <b>{bookingDetailsData?.duration ? bookingDetailsData?.duration : ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                      <><label>KILOMETERS INCLUDED</label><br /></> : <><label>ESTIMATED DISTANCE</label><br /></> 
                    }
                    <b>{bookingDetailsData?.distance ? `${bookingDetailsData?.distance} ` : ""}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>FLIGHT NUMBER</label><br />
                    <b>{bookingDetailsData?.flightNumber || ""}</b>
                  </Col>         
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PIN FOR TRIP</label><br />
                    <b>{bookingDetailsData?.pin ?? ""}</b>
                  </Col>
                  {(bookingDetailsData?.destination === null || bookingDetailsData?.destination === 'null' || bookingDetailsData?.destination === "") ?
                    <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                      <label>NOTE</label><br />
                      <b>Hourly or city tour bookings are available for local travel within city limits, with drop-offs required to be within the same boundaries.</b>
                    </Col>
                    :
                    <></>
                  }
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Car Specifications</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <b>{bookingDetailsData?.car_raw?.manufacturer} {bookingDetailsData?.car_raw?.model} </b><br />
                    <span>{bookingDetailsData?.car_raw?.car_class.replace("_", " ")}</span>
                    <Row className='mt-4 gx-0 align-items-center justify-content-between mb-3'>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={userIcon} alt='icon' /><p className='mb-0 fw-semibold'>{bookingDetailsData?.car_raw?.num_passengers + ' Passengers'}</p></div>
                      </Col>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={breifIcon} alt='icon' /><p className='mb-0 fw-semibold'>{bookingDetailsData?.car_raw?.luggage_capacity + ' Suitcases'}</p></div>
                      </Col>
                    </Row>
                    <Row className='gx-0 align-items-center justify-content-between'>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={meetgreetIcon} alt='icon' /><p className='mb-0 fw-semibold'>{'Meet & Greet'}</p></div>
                      </Col>
                      <Col sm={'6'} className=''>
                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={timeIcon} alt='icon' /><p className='mb-0 fw-semibold'>{Number(bookingDetailsData?.car_raw?.waiting_time) > 0 ? `${bookingDetailsData?.car_raw?.waiting_time} min waiting time` : ""}</p></div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <img src={bookingDetailsData?.car_raw?.fleet_image} width={'350px'} alt="car" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Passenger Details</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>NAME</label><br />
                    <b>{`${bookingDetailsData?.firstName} ${bookingDetailsData?.lastName}`}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>MOBILE</label><br />
                    <b>{ensurePlusSign(bookingDetailsData?.mobile)}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>EMAIL</label><br />
                    <b>{bookingDetailsData?.email}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>PASSENGERS/BAGS</label><br />
                    <b>{bookingDetailsData?.passenger} Passenger{bookingDetailsData?.passenger > 1 ? 's' : ''} / {bookingDetailsData?.luggage} Bag{bookingDetailsData?.luggage > 1 ? 's' : ''}</b>
                  </Col>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>SPECIFIC INSTRUCTIONS</label><br />
                    <b>{bookingDetailsData?.specialInstructions ?? ""}</b>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '20px' }}>Cancellation Policy</h6>
                <Row className='d-md-flex align-items-center mb-4'>
                  <Col lg={'6'} className={'mb-4'} style={{ fontSize: '14px' }}>
                    <label>FREE CANCELLATION BEFORE</label><br />
                    <b>{!!bookingDetailsData?.cancellationDetails ? (

                      bookingDetailsData?.cancellationDetails
                        ?.freeCancellationUntil

                    ) : (
                      'Not Cancellable'
                    )}</b>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={'3'} xl={'3'} className={'mb-4 mb-xl-0'}>
            <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
              <Card.Body className='p-4'>
                <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Payment Summary</h6>
                <Col lg={'12'} className={'mb-4 d-flex align-items-center justify-content-between'} style={{ fontSize: '14px' }}>
                  <b>Trip Total</b>
                  <b>{bookingDetailsData?.fare}</b>
                </Col>
                <Col lg={'12'} className={'mb-4 d-flex align-items-center justify-content-between'} style={{ fontSize: '14px' }}>
                  <Button onClick={() => {
                    navigate("/booking-voucher-public-invoice", { state: bookingDetailsData });
                  }} variant={''} className={'loginbtn w-100'} > Download Voucher </Button>
                </Col>
              </Card.Body>
            </Card>
            {/*  */}
            <Card className={'darkgreenBG text-white mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
              <Card.Body className='p-5 text-center'>
                <h6 className={'text-white fw-bold mb-4'} style={{ fontSize: '26px' }}>Need Help?</h6>
                <p className='fw-bold text-white'>If you need any assisstance with regards to your booking please dont hesitate to contact us</p>

                <p className='text-white'><img src={emailicon} style={{ marginRight: "5px", width: "24px" }} /> support@limowide.com</p>
                <p className='text-white'><img src={phoneicon} style={{ marginRight: "5px", width: "24px" }} /> +44 7458 148595</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      {/*  */}
    </>
  );
};

export default BookingConfirmation;
