import React, { useEffect, useState, useRef,useCallback } from 'react';
import { Col, Form, Button, Row, InputGroup, Card } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import Toggle from 'react-toggle';
import axios from 'axios';
import { handleApiError } from '../utils/errorHandler';
import { getStoredUser } from '../utils/localStorageUtils';
import config from '../config';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import currenciesData from '../images/currencies.json';
import countriesData from '../images/countries.json';
import Loading from '../components/Loading';
import Trips from '../components/Trips';
import { toast } from 'react-toastify';
// 
import settingicon from '../images/settingicon.svg';
import tripsicon from '../images/tripsicon.svg';
import flighticon from '../images/flighticon.svg';
import signouticon from '../images/signout-con.svg';
import unlockicon from '../images/unlockicon.svg';
import mailinboxicon from '../images/mailinboxicon.svg';
import userIcon1 from '../images/userIcon2.svg';
import deleticon from '../images/deleticon.svg';
import userIcon5 from '../images/userIcon5.svg';
import vatidIcon from '../images/vatid.svg';
import companynameIcon from '../images/companyname.svg';

const Profile = () => {
  const navigate = useNavigate();
  const customertype = localStorage.getItem('userType');
  const storedUser = getStoredUser();
  const [countryName, setCountryName] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setEmail] = useState(storedUser.email);
  const [phoneNumber, setPhoneNumber] = useState(`+${storedUser.phone}`);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [newsletter, setNewsletter] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [newLogo,  setNewLogo] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [companyName, setCompanyName] = useState("");
  const [vatId, setVatId] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [streetaddress1, setstreetAddress1] = useState("");
  const [streetaddress2, setstreetAddress2] = useState("");
  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });


  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
  };


  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };



  const currencyOptions = currenciesData.map((country) => ({
    value: country.currency,
    label: (
      <div>
        <img
          src={country.flag}
          alt={country.name}
          style={{ width: '20px', marginRight: '10px' }}
        />
        {country.name}
      </div>
    ),
    currency: country.currency,
  }));

  const profilefetching = useCallback(() => {

    axios.get(`${config.userProfile}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${storedUser?.token?.access_token}`
      }
    }).then(result => {
      if (result?.data?.data) {
        const user = result?.data?.data;
        if (user.currency) {
          const apiResponse = currenciesData.find((currency) => currency.currency === user.currency);
          setSelectedCurrency({
            value: apiResponse.currency,
            label: (
              <div>
                <img
                  src={apiResponse.flag}
                  alt={apiResponse.name}
                  style={{ width: '20px', marginRight: '10px' }}
                />
                {apiResponse.name}
              </div>
            ),
            currency: apiResponse.currency,
          });
        }

        setNewsletter(user.newsletter);
        setfirstName(user.first_name);
        setlastName(user.last_name);
        setPhoneNumber(`+${user.phone}`);
        setPreview(user.logo);
        setCompanyName(user.company_name);
        setVatId(user.vat_id);
        setPincode(user.pincode);
        setCity(user.city);
        setCountry(user.country);
        setstreetAddress1(user.street_address_1);
        setstreetAddress2(user.street_address_2);
      }
    })
      .catch(error => {
        handleApiError(error)
      })
      .finally(() => {
        setLoading(false); // reset loading state
      });
  }, [storedUser?.token?.access_token]);
  // Update Profile
  const validateForm = () => {
    const newErrors = {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    };
    let isValid = true;

    if (!email) {
      newErrors.email = 'Email address is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid';
      isValid = false;
    }

    if (!firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }

    if (!lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }
    // Phone number validation
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };  
  const data = {
    first_name: firstName,
    last_name: lastName,
    phone: phoneNumber.replace(/^\+/, ''),
    is_newsletter: newsletter,
    currency_code: selectedCurrency?.currency ?? null,
  };

  if (customertype !== 'customer') {
    data.company_name = companyName;
    data.vat_id = vatId;
    data.pincode = pincode;
    data.country = country;
    data.street_address_1 = streetaddress1;
    data.street_address_2 = streetaddress2;
    data.city = city;
  }

  const handleSubmit = async () => {
    if (preview) {
      if (preview.startsWith('data:image/')) {
        data.logo = preview;
      }
    }

    // e.preventDefault();
    if (validateForm()) {
      setLoading(true); // set loading state to true
      axios.put(`${config.updateProfile}`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          "Authorization": `Bearer ${storedUser.token.access_token}`
        }
      },
      ).then(result => {
        toast.success(result.data.message);
        if (result?.data?.data) {
          const user = result?.data?.data;
          console.log(user.currency);
          const apiResponse = currenciesData.find((currency) => currency.currency === user.currency);
          setSelectedCurrency({
            value: apiResponse.currency,
            label: (
              <div>
                <img
                  src={apiResponse.flag}
                  alt={apiResponse.name}
                  style={{ width: '20px', marginRight: '10px' }}
                />
                {apiResponse.name}
              </div>
            ),
            currency: apiResponse.currency,
          });
          setNewsletter(user.newsletter);
          setfirstName(user.first_name);
          setlastName(user.last_name);
          if (storedUser) {

            storedUser.first_name = user.first_name;
            storedUser.last_name = user.last_name;
            localStorage.setItem('user', JSON.stringify(storedUser));
          }
        }
      })
        .catch(error => {
          handleApiError(error)
        })
        .finally(() => {
          setLoading(false); // reset loading state
        });
    }
    const pwdconfig = {
      method: "POST",
      url: `${config.changePassword}`,
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${storedUser?.token.access_token}`
      }
    };

    if (oldPassword && newPassword && confirmPassword) {
      await axios(pwdconfig)
        .catch((e) => {
          if (e.response.data.data && e.response.data.data.old_password) {
            toast.error(e.response.data.data.old_password[0]);
          }
          if (e.response.data.data && e.response.data.data.non_field_errors) {
            toast.error(e.response.data.data.non_field_errors[0]);
          }
        })
        .then((response) => {
          if (response) {
            console.log(response);
            toast.success("password Updated Successfully.");
          }
        });

    }

  };
  // Image Working
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setNewLogo(reader.result);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    setLoading(true);
    if(storedUser?.is_guest === true){
        navigate('/update-password');
    }
    if(storedUser?.type === 'driver'){
        navigate('/d-profile');
    }
    profilefetching();
    window.scrollTo(0, 0);
    const phoneNumberObj = parsePhoneNumberFromString(`+${storedUser.phone}`);
    if (phoneNumberObj) {
      setCountryName(phoneNumberObj?.country?.toLowerCase());
    }
    setTabIndex(1)
  }, [profilefetching, storedUser.phone]);
  return (
    <>
      {/*  */}
      <section className='py-4 color1BG'>
        <Col xxl={'10'} xl={'10'} lg={'11'} className='col-11 mx-auto'>
          <Form>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className={'accountTabs'} default={"ltr"}>
              <TabList>
                <Tab><img className='me-2 img-fluid' src={settingicon} alt='' /> Account</Tab>
                <Tab><img className='me-2 img-fluid' src={tripsicon} alt='' /> {storedUser?.type === "driver" ? "Jobs" : "Trips"} </Tab>
                <Tab><img className='me-2 img-fluid' src={flighticon} alt='' /> Flight Requests</Tab>
                <Tab><Link to={'/logout'}><img className='me-2 img-fluid' src={signouticon} alt='' /> Sign Out</Link></Tab>
              </TabList>
              <TabPanel>
                <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <Card.Body className='p-4 p-lg-5'>
                        <h6 className={'secondaryText fw-bold mb-0'} style={{ fontSize: '28px' }}>Account</h6>
                        <p>Update your logo, personal details, change password, newsletter, set currency, etc.</p>
                        <hr />
                        <Row className={''}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>YOUR NAME</span> <br />Your first and last name</Form.Label>
                          </Col>
                          <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={userIcon1} alt='' /></InputGroup.Text>
                              <Form.Control type={'text'} placeholder="Jhon" className={'mb-0 ps-0'} value={firstName} onChange={(e) => { setfirstName(e.target.value); }} isInvalid={!!errors.firstName} aria-label="Username" aria-describedby="basic-addon1" />
                              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                            </InputGroup>
                            {/*  */}
                            <InputGroup className="mb-0">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={userIcon1} alt='' /></InputGroup.Text>
                              <Form.Control type={'text'} placeholder="Duo" className={'mb-0 ps-0'} value={lastName} onChange={(e) => { setlastName(e.target.value); }} isInvalid={!!errors.lastName} aria-label="Username" aria-describedby="basic-addon1" />
                              <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row className={''}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>YOUR EMAIL</span> <br />This is also your Limowide’s username</Form.Label>
                          </Col>
                          <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                            <InputGroup className="mb-0">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={mailinboxicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'email'} placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} readOnly className={'mb-0 ps-0'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row className={''}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>PHONE NUMBER</span></Form.Label>
                          </Col>
                          <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                            <PhoneInput country={countryName} value={phoneNumber} onChange={handlePhoneChange} />
                          </Col>
                        </Row>
                        {(customertype === 'travelagent' || customertype === 'driver')  &&
                          <>
                            <hr />
                            <Row className={''}>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>{customertype === 'travelagent'&& 'COMPANY'} LOGO</span><br /> This will be displayed at your profile</Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <div className='d-md-flex align-items-center justify-content-between'>
                                  <Form.Group controlId="formFile" className="mb-3" style={{ display: 'none' }}>
                                    <Form.Control type="file" ref={fileInputRef} onChange={handleImageChange} />
                                  </Form.Group>
                                  <div className='userprofile1 d-flex align-items-center justify-content-center'>
                                    {preview ? <img src={preview} alt="logoimage" style={{ height: "100px" }} className="img-fluid" /> : <img className='img-fluid' style={{ height: "100px" }} src={userIcon5} alt='logoimage ' />}
                                  </div>
                                  <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                    <Button variant={''} className={'allView-btn mb-md-0 mb-3'} onClick={triggerFileInput}> Change Image</Button>
                                    <Button variant={''} className={'border-btn'} onClick={() => setPreview(null)}> Delete</Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <hr />
                            <Row className={''}>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>COMPANY NAME</span></Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={companynameIcon} alt='' /></InputGroup.Text>
                                  <Form.Control type={'text'} placeholder="Limowide Limowide" className={'mb-0 ps-0'} value={companyName} onChange={(e) => setCompanyName(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                              </Col>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>VAT ID</span></Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={vatidIcon} alt='' /></InputGroup.Text>
                                  <Form.Control type={'text'} placeholder="Jhon" className={'mb-0 ps-0'} value={vatId} onChange={(e) => setVatId(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                              </Col>
                            </Row>
                            <hr />
                            <Row className={''}>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>ADDRESS</span> <br />Change your address from here</Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <Form.Control type={'text'} placeholder="Jhon" className={'mb-0'} value={streetaddress1} onChange={(e) => setstreetAddress1(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                  <Form.Control type={'text'} placeholder="Jhon" className={'mb-0'} value={streetaddress2} onChange={(e) => setstreetAddress2(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                              </Col>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>COUNTRY</span></Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <Form.Select className={'mb-0'} value={country} onChange={(e) => setCountry(e.target.value)}>
                                    {countriesData.map((val, index) => {
                                      return (
                                        <option key={index} value={val.name}>{val.name}</option>
                                      )
                                    })}
                                  </Form.Select>
                                </InputGroup>
                              </Col>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>CITY</span></Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <Form.Control type={'text'} placeholder="Jhon" className={'mb-0'} value={city} onChange={(e) => setCity(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                              </Col>
                              <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                                <Form.Label className='pragraphText'><span className='fw-semibold blackText'>PINCODE</span></Form.Label>
                              </Col>
                              <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                                <InputGroup className="mb-3">
                                  <Form.Control type={'text'} placeholder="123456" className={'mb-0'} value={pincode} onChange={(e) => setPincode(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                                </InputGroup>
                              </Col>
                            </Row>
                          </>
                        }
                        <hr />
                        <Row className={''}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>CHANGE PASSWORD</span><br /> Change your old password to a new password, we recommend doing this often.</Form.Label>
                          </Col>
                          <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Enter Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className={'mb-0 ps-0'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                            {/*  */}
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Set New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className={'mb-0 ps-0'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                            {/*  */}
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={'mb-0 ps-0'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row className={''}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'4'} xl={'4'} lg={'5'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>SET CURRENCY</span><br /> Set your currency for your referrence.</Form.Label>
                          </Col>
                          <Col className={''} xxl={'8'} xl={'8'} lg={'7'}>
                            <InputGroup className="mb-0">
                              <Select
                                className='w-100'
                                options={currencyOptions}
                                value={selectedCurrency}
                                onChange={handleCurrencyChange}
                                placeholder="Select a Currency"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginRight: 10,
                                  }),
                                }}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row className={'align-items-center'}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'9'} xl={'9'} lg={'8'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>EMAIL NEWSLETTER</span><br /> Get the  Limowide Newsletter for the best travel deals and latest news. And remember, we HATE spam as much as you do—we will never, ever, sell or share your email address with spammers.</Form.Label>
                          </Col>
                          <Col xxl={'3'} xl={'3'} lg={'4'} className='text-end'>
                            <Toggle
                              defaultChecked={newsletter}
                              icons={false}
                              onChange={(e) => setNewsletter(e.target.checked)} />
                          </Col>
                        </Row>
                        <hr />
                        <Row className={'align-items-center mb-4'}>
                          <Col className={'mb-4 mb-lg-0'} xxl={'9'} xl={'9'} lg={'8'}>
                            <Form.Label className='pragraphText'><span className='fw-semibold blackText'>DELETE ACCOUNT</span><br /> Deleting your account will remove all the saved personal information & your trip details. </Form.Label>
                          </Col>
                          <Col className={'text-lg-end text-center'} xxl={'3'} xl={'3'} lg={'4'}>
                            <Button variant={''} className={'allView-btn orangeBG whiteText redBG border-0'} onClick={() => alert('Account Deleted')}><img className='img-fluid me-2' src={deleticon} alt='' /> Delete Account</Button>
                          </Col>
                        </Row>
                        <Button variant={''} className={'allView-btn'} onClick={handleSubmit} > Update</Button>
                      </Card.Body>
                    </>
                  )}
                </Card>
              </TabPanel>
              {/*  */}
              <TabPanel>
                <div>
                  <Trips />
                </div>
              </TabPanel>
              {/*  */}
              <TabPanel>

              </TabPanel>
              {/*  */}
              <TabPanel>

              </TabPanel>
            </Tabs>
          </Form>
        </Col>
      </section>
    </>
  );
};

export default Profile;
