import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import config from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleApiError } from '../utils/errorHandler';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import {
  LoginSocialFacebook
} from 'reactjs-social-login';
import { useGoogleLogin } from '@react-oauth/google';
// 
import googleimage from "../images/google.png";
import facebookimage from "../images/facebook.png";

import SocialLinks from '../components/SocialLinks';
const REDIRECT_URI = "https://sandbox.limowide.com/signin/";
const SignIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstname, setFirstName] = useState("");
  const [firstnameerror, setFirstNameerror] = useState("");
  const [lastname, setLastName] = useState("");
  const [lastnameerror, setLastNameerror] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [emailInputerror, setEmailInputerror] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordInputerror, setPasswordInputerror] = useState("");
  const [confirmpasswordInput, setConfirmPasswordInput] = useState("");
  const [confirmpasswordInputerror, setConfirmpasswordInputerror] = useState("");
  const [phoneNumbererror, setPhoneNumbererror] = useState("");
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
    setCountryCode(country.dialCode);
    setPhoneNumbererror('');
  };
  const onLogoutSuccess = useCallback(() => {
    // setProfile(null);
    // setProvider("");
  }, []);
  const onLoginSuccessFacebook = useCallback(
    async ({ provider, data }) => {
      const payload = {
        provider: provider,
        access_token: data?.accessToken,
        first_name: data?.first_name,
        last_name: data?.last_name,
      };
      const configs = {
        method: "POST",
        url: `${config.apiSocialLogin}`,
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        }
      };
      const loginRes = await axios(configs).catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.data?.email || e?.response?.data?.data?.password)
      });
      if (loginRes?.data?.token?.access_token) {
        toast.success("Logged In Success");
        localStorage.setItem("userType", loginRes?.data?.type);
        localStorage.setItem("last_session", new Date().toISOString());
        localStorage.setItem("user", JSON.stringify(loginRes?.data));
        localStorage.setItem("is_guest", loginRes?.data?.is_guest);
        localStorage.setItem("token", JSON.stringify(loginRes?.data?.token?.access_token));
        const redirectPath = localStorage.getItem('redirectAfterLogin');
        localStorage.removeItem('redirectAfterLogin');
        const isGuest = loginRes?.data?.is_guest;
        // Navigate based on is_guest value
        if (isGuest) {
          navigate('/update-password');
        } else {
          redirectPath !== null ? navigate(redirectPath) : navigate('/profile');
        }
      }
    },
    []
  );
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => onLoginSuccess(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });
  const onLoginSuccess = (codeResponse) => {
    try {
      // Log the response received from Google
      console.log(codeResponse);

      // Fetch user info from Google API using the access token
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${codeResponse.access_token}`,
          Accept: 'application/json'
        }
      })
        .then(response => {
          // Log the user info response
          console.log(response.data);
          const payload = {
            provider: "google",
            access_token: codeResponse.access_token,
            first_name: response.data.given_name, // Use response.data to access user info
            last_name: response.data.family_name
          };
          const configs = {
            method: "POST",
            url: `${config.apiSocialLogin}`,
            data: payload,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            }
          };
          axios(configs).catch((e) => {
            toast.error(e?.response?.data?.data?.email);
            toast.error(e?.response?.data?.data?.password);
          }).then(response => {
            toast.success("Logged In Success");
            localStorage.setItem("userType", response?.data?.type);
            localStorage.setItem("last_session", new Date().toISOString());
            localStorage.setItem("user", JSON.stringify(response?.data));
            localStorage.setItem("is_guest", response?.data?.is_guest);
            localStorage.setItem("token", JSON.stringify(response?.data?.token?.access_token));
            const redirectPath = localStorage.getItem('redirectAfterLogin');
            localStorage.removeItem('redirectAfterLogin');
            const isGuest = response?.data?.is_guest;
            // Navigate based on is_guest value
            if (isGuest) {
              navigate('/update-password');
            } else {
              redirectPath !== null ? navigate(redirectPath) : navigate('/profile');
            }
          });
        })
        .catch(error => {
          // Handle user info fetch error
          console.error('Error fetching user info:', error);
          handleApiError(error);
          setLoading(false); // Reset loading state
        });
    } catch (error) {
      // Catch any unexpected errors
      console.error('Error exchanging code for access token:', error);
      handleApiError(error);
      setLoading(false); // Reset loading state
    }
  };
  const validateForm = () => {
    let isValid = true;
    if (!firstname.trim()) {
      setFirstNameerror('First name is required');
      isValid = false;
    }
    if (!lastname.trim()) {
      setLastNameerror('Last name is required');
      isValid = false;
    }
    if (!emailInput.trim()) {
      setEmailInputerror('Email is required');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(emailInput)) {
      setLastNameerror('Email is invalid');
      isValid = false;
    }
    if (!passwordInput.trim()) {
      setPasswordInputerror('Password is required');
      isValid = false;
    } else if (passwordInput.length < 6) {
      setPasswordInputerror('Password must be at least 6 characters long');
      isValid = false;
    }
    if (confirmpasswordInput !== passwordInput) {
      setConfirmpasswordInputerror('Passwords do not match');
      isValid = false;
    }
    // Phone number validation
    if (!phoneNumber || !phoneNumber.trim()) {
      setPhoneNumbererror('Phone number is required');
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (loading) return; // prevent function if already loading

      setLoading(true); // set loading state to true
      axios.post(`${config.apiRegister}`, {
        first_name: firstname,
        last_name: lastname,
        phone: phoneNumber,
        isd_code: countryCode,
        email: emailInput,
        password: passwordInput,
        confirm_password: confirmpasswordInput
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        }
      },
      ).then(result => {
        toast.success(result.data.detail);
        setTimeout(() => {
          navigate('/signin')
        }, 5000); // 5000 milliseconds = 5 seconds
      })
        .catch(error => {
          handleApiError(error)
        })
        .finally(() => {
          setLoading(false); // reset loading state
        });
    }
  };
  return (
    <main className="container-fluid mt-5 pb-7 pt-lg-5 pt-4 bgimage">
      <Row className="justify-content-center gx-0">
        <Col lg={'8'} className="">
          <Card className="" style={{ border: 'none', backgroundColor: 'transparent', }}>
            <Row className="g-0">
              <Col md={'6'} className="d-none d-md-block">
                <div className='h-100 login-card-before shadow-lg' style={{ borderRadius: '30px 0 0px 30px' }}>
                  <div className="h-100 w-100 regidter-card-bg p-4 overflow-hidden" style={{ borderRadius: '30px 0 0px 30px' }}>
                    <div className='welcometext'>
                      <h2 className='FontFamilyProstoOne'>Welcome to <span className='mainText'>limowide</span></h2>
                      <p className='text-white'>Your trusted partner for airport transfer</p>
                      <div className="d-flex alig-items-center justify-content-center gap-3">
                          <SocialLinks />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={'6'} className="login-card-after">
                <div className='px-5 py-4 h-100 w-100 overflow-hidden bg-white shadow-lg' style={{ borderRadius: '0px 30px 30px 0px' }}>
                  <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>Create Account</h2>
                  <p className='text-center pragraphText' style={{ lineHeight: '20px' }}>Unlock exclusive benefits by registering with us today - join our community and start your journey!</p>
                  <form onSubmit={handleSubmit} className="loginform mt-5">
                    <Row className='align-center mb-3'>
                      <Col lg={'6'} className='mb-3 mb-lg-0'>
                        <Form.Control type="text" value={firstname} onChange={(e) => { setFirstName(e.target.value); setFirstNameerror(''); }} className="user-icon" placeholder="First Name" />
                        {firstnameerror && <span style={{ color: 'red' }}>{firstnameerror}</span>}
                      </Col>
                      <Col lg={'6'}>
                        <Form.Control type="text" value={lastname} onChange={(e) => { setLastName(e.target.value); setLastNameerror(''); }} className="user-icon" placeholder="Last Name" />
                        {lastnameerror && <span style={{ color: 'red' }}>{lastnameerror}</span>}
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Form.Control type="email" value={emailInput} onChange={(e) => { setEmailInput(e.target.value); setEmailInputerror(''); }} className="email-icon" placeholder="Email Address" />
                      {emailInputerror && <span style={{ color: 'red' }}>{emailInputerror}</span>}
                    </div>
                    <div className="mb-3">
                      <PhoneInput country={'us'} value={phoneNumber} onChange={handlePhoneChange} />
                      {phoneNumbererror && <span style={{ color: 'red' }}>{phoneNumbererror}</span>}
                    </div>
                    <div className="mb-3">
                      <Form.Control type="password" value={passwordInput} onChange={(e) => { setPasswordInput(e.target.value); setPasswordInputerror(''); }} className="password-icon" placeholder="Password" />
                      {passwordInputerror && <span style={{ color: 'red' }}>{passwordInputerror}</span>}
                    </div>
                    <div className="mb-3">
                      <Form.Control type="password" value={confirmpasswordInput} onChange={(e) => { setConfirmPasswordInput(e.target.value); setConfirmpasswordInputerror(''); }} className="password-icon" placeholder="Confirm Password" />
                      {confirmpasswordInputerror && <span style={{ color: 'red' }}>{confirmpasswordInputerror}</span>}
                    </div>
                    <div className='d-flex align-center mt-5' style={{ gap: '20px' }}>
                      <Button variant={''} type="submit" disabled={loading} className="signUpbtn w-100 btn">
                        {loading ? <div className="bouncing-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div> : 'Sign Up'}

                      </Button>
                    </div>
                    <div className="text-center my-3 orlogin">
                      <span>or log in with</span>
                    </div>
                    <div className="d-flex gap-2 justify-content-center">
                      <Link to="#!" onClick={() => login()}><img src={googleimage} alt="" /></Link>
                      <LoginSocialFacebook
                        appId="1226842592047274"
                        fieldsProfile={
                          'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        onLogoutSuccess={onLogoutSuccess}
                        redirect_uri={REDIRECT_URI}
                        onResolve={onLoginSuccessFacebook}
                        onReject={err => {
                          console.log(err);
                        }}
                      >
                        <Link to="#"> <img src={facebookimage} alt="" /> </Link>
                      </LoginSocialFacebook>

                    </div>
                    <p className="text-center mt-3 pragraphText">Already have an account? <Link to="/signin" className='fw-bolder blackText'>Sign In Now!</Link></p>
                  </form>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </main>
  );
};

export default SignIn;
