import React, { useEffect } from 'react';
import { Breadcrumb, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)',borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Cookie <span className=''>Policy</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Cookie Policy</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>    
      {/*  */}
      <section className='py-4'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <div className="flex space-y-4 flex-col">
            <p className="text-2xl font-bold">
              Cookie Policy for Limowide
            </p>
            <p>
              This is the Cookie Policy for Limowide, accessible
              from http://limowide.com/
            </p>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">What Are Cookies</p>
            <p>
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to your
              computer, to improve your experience. This page describes what
              information they gather, how we use it and why we sometimes need to
              store these cookies. We will also share how you can prevent these
              cookies from being stored however this may downgrade or ‘break’
              certain elements of the sites functionality.
            </p>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">How We Use Cookies</p>
            <p>
              We use cookies for a variety of reasons detailed below.
              Unfortunately, in most cases, there are no industry standard options
              for disabling cookies without completely disabling the functionality
              and features they add to this site. It is recommended that you leave
              on all cookies if you are not sure whether you need them or not in
              case they are used to provide a service that you use.
            </p>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">Disabling Cookies</p>
            <p>
              You can prevent the setting of cookies by adjusting the settings on
              your browser (see your browser Help for how to do this). Be aware
              that disabling cookies will affect the functionality of this and
              many other websites that you visit. Disabling cookies will usually
              result in also disabling certain functionality and features of this
              site. Therefore it is recommended that you do not disable cookies.
            </p>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">The Cookies We Set</p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>Account related cookies</p>
            </div>
            <p>
              If you create an account with us then we will use cookies for the
              management of the signup process and general administration. These
              cookies will usually be deleted when you log out however in some
              cases they may remain afterwards to remember your site preferences
              when logged out.
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>Login related cookies</p>
            </div>
            <p>
              We use cookies when you are logged in so that we can remember this
              fact. This prevents you from having to log in every single time you
              visit a new page. These cookies are typically removed or cleared
              when you log out to ensure that you can only access restricted
              features and areas when logged in.
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>Email newsletters related cookies</p>
            </div>
            <p>
              This site offers newsletter or email subscription services and
              cookies may be used to remember if you are already registered and
              whether to show certain notifications which might only be valid to
              subscribed/unsubscribed users.
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>Orders processing related cookies</p>
            </div>
            <p>
              This site offers e-commerce or payment facilities and some cookies
              are essential to ensure that your order is remembered between pages
              so that we can process it properly.
            </p>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">Third-Party Cookies</p>
            <p>
              In some special cases, we also use cookies provided by trusted third
              parties. The following section details which third party cookies you
              might encounter through this site.
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solution on the web for helping
                us to understand how you use the site and ways that we can improve
                your experience. These cookies may track things such as how long
                you spend on the site and the pages that you visit so we can
                continue to produce engaging content.
              </p>
            </div>
            <p>
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>
                From time to time we test new features and make subtle changes to
                the way that the site is delivered. When we are still testing new
                features these cookies may be used to ensure that you receive a
                consistent experience whilst on the site whilst ensuring we
                understand which optimisations our users appreciate the most.
              </p>
            </div>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>
                As we sell products it’s important for us to understand statistics
                about how many of the visitors to our site actually make a
                purchase and as such this is the kind of data that these cookies
                will track. This is important to you as it means that we can
                accurately make business predictions that allow us to monitor our
                advertising and product costs to ensure the best possible price.
              </p>
            </div>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>
                We also use social media buttons and/or plugins on this site that
                allow you to connect with your social network in various ways. For
                these to work the following social media sites including;
                (Facebook, Pintrest, Twitter, Linkedin, Instagram etc.,), will set
                cookies through our site which may be used to enhance your profile
                on their site or contribute to the data they hold for various
                purposes outlined in their respective privacy policies.
              </p>
            </div>
          </div>
          <div className="flex space-y-4 flex-col">
            <p className="text-lg font-bold">More Information</p>
            <p>
              Hopefully, that has clarified things for you and as was previously
              mentioned if there is something that you aren’t sure whether you
              need or not it’s usually safer to leave cookies enabled in case it
              does interact with one of the features you use on our site.
            </p>
            <p>
              For more general information on cookies, please read the “Cookies”
              article from the Privacy Policy Generator.
            </p>
            <p>
              However, if you are still looking for more information then you can
              contact us through one of our preferred contact methods:
            </p>
            <div className="flex space-x-4">
              <p className="text-3xl font-bold">*</p>
              <p>Email: support@limowide.com</p>
            </div>
          </div>
        </Col>
      </section>
    </>
  );
};

export default CookiePolicy;
