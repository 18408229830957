import React from 'react';
import { Card, Col, CardBody } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextComponent from '../components/TextComponent';
import sanitizeHtml from 'sanitize-html';
// import image
import rightArrow from '../images/rightArrow.svg';

const BlogBoxUL = (props) => {
    const sanitizeOptions = {
        allowedTags: [], // No tags allowed, only text content
        allowedAttributes: {} // No attributes allowed
    };
    return (
        <>
            <Col xxl={props.counter === 0 ? '12' : '6'} xl={props.counter === 0 ? '12' : '6'} className="mb-4" >
                <Card className="">
                    <div className='cardImg'><Link to={`/blog-detail/${props.slug}`} className=""><img className='img-fluid h-100' src={props.blogMainImg} alt='img' /></Link></div>
                    <CardBody className='px-4'>
                        <small className='d-flex mb-0'>{props.locationText}</small>
                        <Link className='title mb-3 d-block' to={`/blog-detail/${props.slug}`}>{props.title}</Link>
                        <p className=''><TextComponent text={sanitizeHtml(props.paragraph, sanitizeOptions)} alphaLimit={60} /></p>
                        <p style={{ fontSize: '14px' }}>By <Link className='fw-bold px-2' to={'/'}>{props.autherName}</Link> {props.date}</p>
                        <Link to={`/blog-detail/${props.slug}`} className='readmore d-flex align-items-center'>Read More <img className='ms-2 img-fluid' width={'10px'} src={rightArrow} alt='icon' /></Link>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default BlogBoxUL;