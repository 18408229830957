import React, { useEffect } from 'react';
import { Breadcrumb, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)', borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Terms & <span className=''>Conditions</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Terms & Conditions</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>
      {/*  */}
      <section className='py-4' style={{ fontFamily: 'var(--FontFamily_Figtree)' }}>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <h3>Introduction</h3>
          <p style={{ textAlign: 'justify' }}>Welcome and thank you for your interest in Limowide, ("Limowide") and our web-based service available at www.Limowide.com. The following Terms of Use are a legally binding agreement between you and Limowide regarding your use of the Limowide website, mobile applications, networks and other features or services, collectively known as the “Service”.</p>
          <p style={{ textAlign: 'justify' }}>Limowide and the term “we” or the “us” refers to the company that provides the service. Any person, company or business that makes a booking with us can use the terms “you” or “your”.</p>
          <p style={{ textAlign: 'justify' }}>Limowide, through its booking platform www.Limowide.com, offers transport services provided by available transport companies registered with Limowide (hereinafter referred as Transport  Provider ) to car rental customers (hereinafter referred to as you). The term "car rental" refers to limousines with a driver (booking classes: Standard, Business, and First Class) and private taxis.</p>
          <p style={{ textAlign: 'justify' }}>Please read the following terms of use carefully. by clicking "I accept" when registering for an account, downloading a Limowide mobile application, accessing, browsing, or using the service, you acknowledge that you have read, understood, and agree to be bound by the following terms and conditions, including the limowide privacy policy, any applicable end user licence agreement, and any additional terms or future modifications (collectively, the "terms''). If you are not eligible or do not agree to the Terms, please do not use the Service.</p>
          <h3>1. Eligibility</h3>
          <p style={{ textAlign: 'justify' }}>You must be at least eighteen (18) years old to use the Service. By agreeing to the Terms, you represent and warrant to us that:</p>
          <ul>
            <li>you are at least eighteen (18) years of age;</li>
            <li>you have not previously been suspended or terminated from the Service;</li>
            <li>your registration and use of the Service is in compliance with all applicable laws and regulations. If you are using the Service on behalf of an entity, organisation or company (collectively, a "Subscribing Organisation"), you are an authorised representative of such Subscribing Organisation with the power to bind such organisation to these Terms and you agree to be bound by these Terms on behalf of such Subscribing Organisation. In such cases, "you" in these Terms refers to your Subscribing Organisation and any person authorised to use the Service on behalf of the Subscribing Organisation, including you.</li>
          </ul>
          <h3>2. Scope</h3>
          <p style={{ textAlign: 'justify' }}>Limowide Provides  the passenger services to  you via www.Limowide.com, the booking platform provided by Limowide. Limowide enables the Transport  Provider to offer their services to you on the Limowide website. The Transport  Provider posts their transport service offer including their qualifications, vehicles, prices and other data relevant to their services on Limowide and Limowide presents this service offer to you in the name of and for the account of the Transport  Provider. Via Limowide, you can search for available vehicles and transport capacities and book them through the said platform. Limowide acts as an intermediary between you and the Transport  Provider in regard to these passenger transport services. The commercial relationship established for the purpose of such passenger transport is solely between you and the Transport  Provider with Limowide.</p>
          <h3>3. Agreement</h3>
          <p style={{ textAlign: 'justify' }}>It is of great importance to Limowide that full transparency is maintained towards all parties and that all parties have confidence in the services offered. For this reason, the General Terms and Conditions of Limowide apply as the basis for all business relations between you and Limowide. They apply to all offers made to you for passenger transport via Limowide, the mediation of such services by Limowide and any other use of the platform or its services. Any general terms and conditions of Transport  Provider with Limowide or you shall only become part of the contract if their applicability has been expressly agreed and Limowide has given its written consent.</p>
          <h3>4. End User Licence Agreement (EULA)</h3>
          <p style={{ textAlign: 'justify' }}>Your use of any mobile application or other downloadable software provided by Limowide (each, an "Application") is subject to an End User Licence Agreement. The applicable EULA depends on the platform on which the App is designed to run and the features of the App. The EULA will be presented to you when you download and/or install the App and will be accessible through the App. Applications are deemed to be part of the Service and all such EULAs are hereby incorporated into and made a part of the Terms by reference.</p>
          <h3>5. Conclusion and Modification of the contract</h3>
          <blockquote>
            <h5>5.1. Arrangement of private taxi services by taxi companies registered with Limowide</h5>
            <p style={{ textAlign: 'justify' }}>With regard to private taxi service, these are passenger transport services provided by private taxi companies (Transport  Provider) registered with Limowide, Limowide acts exclusively as a booking agent for such private taxi companies and presents their service offers on Limowide. You search and find suitable transport services on Limowide and select them. Limowide then forwards your requests to the appropriate company who is the selected service Transport Provider. You will receive a provisional, non-binding reservation confirmation from Limowide immediately after the request has been completed. The contract for the provision of transport services is not concluded until the order is accepted by the Transport  Provider. The conclusion of this contract is documented by a binding booking confirmation sent to you by Limowide on behalf of the Transport  Provider. The transport service is provided by the Transport  Provider. Limowide may be entrusted by contract with the management of the accounts of the passenger transport services for you  in the name and on behalf of the Transport  Provider.</p>
            <h5>5.2. Additional terms</h5>
            <p style={{ textAlign: 'justify' }}>Your use of the Service is subject to any additional terms, rules or guidelines applicable to specific services and features that we may post from time to time (the "Additional Terms"). All such Additional Terms are hereby incorporated by reference into and made a part of the Terms.</p>
            <h5>5.3. Modification of the terms</h5>
            <p style={{ textAlign: 'justify' }}>We reserve the right to change the Terms at any time at our sole discretion. Please check the Terms periodically for changes. Your continued use of the Service after changes are posted constitutes your binding acceptance of those changes. If a change to the Terms materially alters your rights or obligations, we will endeavour to notify you of the change, for example by sending you an email at the address we have on record for you, or by displaying a pop-up window or other notification on the Service when you log in. Non-substantive changes will be effective upon posting, and substantive changes will be effective upon the earlier of (a) your continued use of the Service with actual knowledge of the change, or (b) thirty (30) days after the change. For the avoidance of doubt, disputes arising under this Agreement will be resolved in accordance with the terms and conditions in effect at the time the dispute arose.</p>
          </blockquote>
          <h3>6. Account Registration</h3>
          <p style={{ textAlign: 'justify' }}>To access most features of the Limowide, you must register for an account. When you register for an account, you may be asked to provide us with some information about yourself (such as your email address or other contact information). You agree that the information you provide to us is and will remain accurate and up to date. If you register, you will be asked to choose a password. You are solely responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account. If you have any reason to believe that your account is no longer secure (for example, in the event of loss, theft or unauthorised disclosure or use of your password), you agree to notify us immediately at <a href="">support@Limowide.com</a></p>
          <h3>7. User Rights and Obligations</h3>
          <p>Your registration on Limowide entitles you to use the technical resources provided by Limowide.</p>

          <p>In order to effectively register, you must provide the necessary information. This includes, for example, entering your full name, address, and telephone number in the registration form. This information will be treated confidentially and will not be disclosed to third parties.</p>

          <p>You are obliged to provide Limowide with true and complete personal data at all times, in accordance with Limowide's requirements, and to keep this data up to date.</p>

          <p>Furthermore, you are obliged to use Limowide in such a way that no impairment, overload, or damage is caused to Limowide and that the purpose pursued by this platform is not jeopardized or circumvented. You will not bypass or modify any security measures taken by Limowide, neither by yourself nor by third parties.</p>

          <p>All rights to the software and services provided on Limowide remain with Limowide. You may not copy, modify, reverse engineer, decompile, or distribute the software made available to you.</p>

          <p>You shall keep your username and password in a safe place and shall not disclose them to third parties or allow third parties to access Limowide. You are solely responsible for the confidentiality and security of your account. You must inform Limowide immediately of any unauthorized use by third parties.</p>

          <p>You undertake not to bypass Limowide when concluding contracts with the transport companies registered with Limowide if they are part of the services offered by Limowide.</p>

          <p>The registration with Limowide for an "Individual Account" can be canceled by you at any time. For "Corporate Account" users, a notice period of 2 weeks applies.</p>

          <p>You are obliged to inform Limowide immediately if you are late, if the transport order has been canceled, or if the driver is late and/or cannot be found.</p>

          <p>It is your responsibility to check that the booking details on the booking confirmation correspond to the details entered when making the booking and to inform Limowide immediately of any discrepancies.</p>

          <p>Personal data of third parties, which you receive or become aware of (in particular the contact details of the driver), will only be processed by you on behalf of and for the purposes of Limowide and will not be passed on to uninvolved third parties. Any other regulation shall only be valid if consent has been given.</p>

          <p>If you do not comply with your obligations, Limowide is entitled to exclude you from individual or all functions of Limowide temporarily or, in case of severe violations, permanently in order to prevent misuse of Limowide.</p>

          <p>You are obliged to check the booking data on the booking confirmation for consistency with the data you entered when making the booking and to inform Limowide immediately of any discrepancies.</p>
          <h3>8. Obligations of Limowide</h3>
          <blockquote>
            <h5>8.1. Provision of services</h5>
            <p style={{ textAlign: 'justify' }}>Via Limowide, you can directly contact the Transport  Provider providing the transport service as a potential passenger for a transport order. In this context, the data of the Transport  Provider is transmitted to Limowide for identification purposes after a booking has been made by you. In addition to the driver's name (first name and surname), the driver's telephone number, driver's licence number and, if applicable, rating are also transmitted. Your basic data (in particular name and telephone number) shall be transmitted to the transport company and the respective driver carrying out the order before the journey for identification purposes and in order to be able to contact them if necessary.</p>
            <h5>8.2. Limits of the service</h5>
            <p style={{ textAlign: 'justify' }}>The services provided by Limowide do not include Internet access. You are personally responsible for the maintenance of the Internet access, the system requirements, the configuration and efficiency of your terminal equipment for the use of Limowide and the updating of the necessary software, and bear the costs thereof. Also the car pictures are only for representation purpose only and varies country to country.</p>
            <h5>8.3. Collection management</h5>
            <ol>
                <li>On the basis of individual contracts with the transport companies registered with Limowide, Limowide acquires ownership of all claims against you based on transport services provided by the transport companies registered with Limowide and is entitled to collect the respective payments in its own name and on its own account.</li>
                <li>Limowide collects the fees due on the basis of the price communicated by the Transport  Provider and agreed with you, directly by charging your credit card (or other payment method, if applicable) through the Payment Service Transport Provider. All fees and charges are inclusive of VAT.</li>
            </ol>
          </blockquote>
          <h3>9. Prices, Fees and Terms of Payment</h3>
          <p style={{textAlign:'justify'}}>All prices listed on our website are inclusive of taxes, airport parking, admin and agency fees. The transfer details on your booking page will list other features (if any) that are included in the price.</p>
          <p style={{textAlign:'justify'}}>Unless otherwise stated in the prices shown in the search results, road bridge etc. tolls are excluded. You may have to pay this to the Transport  Provider separately.</p>
          <p style={{textAlign:'justify'}}>Any extras or other requests you make, may incur additional costs that must be paid directly to the Transport  Provider Please note that, Transport  Provideryou are in contract with may not accept the requests which you demand after the cancellation time. In such cases, it is up to the Transport Transport Provider to charge you in full. See Baggage, Additional Passengers, Special Requests, Disabled Or Special Needs Passengers and Child Passengers below for more.</p>
          <p style={{textAlign:'justify'}}>The credit or debit card you use to pay the price for the booking will be charged by our bussiness partner Stripe immediately at the time of your demand for booking and a payment confirmation e-mail will be sent to your provided mail address. However, the payment will not be transferred to the company within three business days, starting from the next business day from the day you were supposed to receive the service. Thus, if there is a problem with the Transport Service you have or have not received, you can contact us within three working days regarding your payment. For more, please see our Cancellation Policy.</p>
          <p style={{textAlign:'justify'}}>We actually get paid in dollars on Limowide.com but the price may be displayed in your preferred currency and is estimated based on the current exchange rates. Final payment will be settled in the currency of choice of the Transport Company. The currency will be displayed on the booking details page. You may be charged a fee by your bank even if the charge is processed in the original currency of your payment method. Please check our business partner <a href="">Stripe’s Terms and Conditons</a> for more.</p>
          <p style={{textAlign:'justify'}}>Note that fees or charges added by your card Provider are not refundable by us or the Transport Company. You will need to contact your card Provider.</p>
          <p style={{textAlign:'justify'}}>From time to time we run promotions and discounts. Specific terms and conditions need to be checked for each of the promotions.</p>
            <ol>
              <li>The use of Limowide's services within an "Individual Account'' on Limowide is free of charge. For the use of Limowide's services as part of a "Corporate Account" on Limowide, Limowide reserves the right to contractually agree on a fixed monthly user fee.</li>
              <li>In addition, Limowide reserves the right to offer the USERS, in the name and on behalf of the registered transport companies, different booking classes with differently priced service standards.</li>
              <li>There are three booking classes for car rental (Standard Class, Business Class and First Class) and one booking class for taxis (Taxi). The booking classes differ in terms of vehicle category, waiting time and services provided (in particular drinks, newspapers in the car, etc.).</li>
            </ol>
          <h3>10. Electronic payment</h3>
          <ol>
            <li>Limowide uses the system of electronic payment by credit card and other electronic payment methods. Limowide works with a reputable electronic payment Transport Provider, which manages the credit card details and processes the payments. Limowide does not store your payment information.</li>
            <li>When you make a booking, Limowide authorises your  credit card payment to the payment Provider, who then debits your  account with the agreed amount after you have completed your journey with the relevant Transport  Provider registered with Limowide. In order to authorise the said debit, you  must authorise the final amount to be debited at the end of the trip by PIN or signature. Without this authorization, Limowide will not debit your account.</li>
            <li>The amount to be charged shall be based on the price agreed between you and the Transport  Provider registered with Limowide, plus any additional charges resulting from any of the above changes (in particular, route charges, waiting times, etc.). However, such changes to the price are only valid if they are approved by you at the end of the journey.</li>
          </ol>
          <h3>11. Booking</h3>
          <p style={{textAlign: 'justify'}}>Please ensure that all information provided at the time of booking, including pick-up details, is accurate. Neither we nor the drivers are responsible for incorrect trip information provided by you. Refer to our Cancellation Policy for further details.</p>
          <p style={{textAlign: 'justify'}}>If your booking is with a Transport Company outside the UK (whether in the EEA or not), you acknowledge that your personal information necessary for the booking will be transferred to the overseas Transport Company to facilitate your reservation. For full details, refer to our Privacy Policy, available upon request and on our website.</p>
          <p style={{textAlign: 'justify'}}>The map and road-route displayed on the site serve as a preliminary guide. The actual service provided may vary in terms of distance, time, and route chosen by the Transport  Provider for your convenience. Please consider this when booking and specifying your destination.</p>
          <p style={{textAlign: 'justify'}}>Your booking is confirmed upon receiving a booking confirmation at the email address provided during booking. You may need to present these details to the driver to board the vehicle.</p>
          <p style={{textAlign: 'justify'}}>Once confirmed, the driver will be waiting at the pick-up location and time specified during booking. The driver may contact you a few hours before the scheduled pick-up time, or you can reach out to them using the information provided in your booking confirmation email.</p>
          <h3>12. Service Changes and Cancellation</h3>
          <blockquote>
            <h5>12.1. The Service</h5>
            <p style={{ textAlign: 'justify' }}>All passengers are responsible for their conduct during the journey. A charge may be made to cover cleaning costs if the vehicle is damaged, stained, or otherwise negatively affected by passengers.</p>
            <p style={{ textAlign: 'justify' }}>It is not allowed to eat, drink, or smoke in the vehicle.</p>
            <p style={{ textAlign: 'justify' }}>Children in cars should be secured in a way that is appropriate for their height, weight, and age.</p>
            <p style={{ textAlign: 'justify' }}>It is not permitted to transport more passengers than what is required by insurance or licensing.</p>
            <h5>12.2. Cancellation</h5>
            <ol>
              <li>If the Transport Provider is a taxi company, bookings may be cancelled or rescheduled by the USER free of charge until the scheduled departure time. After that, cancellation and rescheduling shall no longer be possible and a non-attendance fee subject to the taxi fare regulations shall be charged.</li>
              <li>If a booking for a private hire vehicle company is cancelled or rescheduled, a difference is made between the time limit for cancellation and the time limit for rescheduling:</li>
                <ol type="a">
                  <li>The time limit for cancellation is 24 hours before the scheduled departure time for standard bookings. Cancellation is free of charge prior to the time limit for cancellation. After the time limit for cancellation has passed, the full fare for the booking will be charged in the event of a cancellation.</li>
                  <li>The time limit for rescheduling is 24 hours before the scheduled departure time for standard bookings. Rescheduling is free of charge prior to the time limit for rescheduling. After the time limit for rescheduling has passed, the full fare for the booking will be charged in the event of a rescheduling.</li>
                </ol>
                <li>If the booking is rescheduled either before or after the aforesaid time limits, the fare may be recalculated and a new Transport Provider may be defined if appropriate. This depends on the details of rescheduling and availability of the Transport Provider. You shall be immediately notified of any changes in the rescheduled booking.</li>
                <li>No extra costs will be charged for any changes in the travel time due to the late departure or arrival of a plane, coach, bus or train. You  shall notify the Transport Provider of such delays without delay.</li>
            </ol>
          </blockquote>       
          <h3>13. Modification</h3>
          <ol style={{textAlign:'justify'}}>
            <li>You may modify the reservation by choosing the details provided to you at the time of booking and sent in your confirmation email.</li>
            <li>You must guarantee that the information recorded on your reservation is accurate.</li>
            <li>Any changes to the Booking details may result in an increase in the price of the Booking, which must be accepted by you and is subject to availability of the Service.</li>
            <li>Changes are subject to the cancellation policy communicated for the reservation. Once the cancellation deadline has expired, no change will be possible and will never result in a refund of the Reservation value or any form of compensation to you.</li>
            <li>You are solely responsible for the timely processing of changes to the reservation.</li>
            <li>Cancellation of a Reservation outside of the Cancellation Policy will in no event result in a refund of the value of the Flight or the value of the Feature.</li>
            <li>Unless otherwise specified at the time of booking, Cancellation will be in the form of a voucher or coupon code for the value paid for the Reservation, valid for one year from the time of Cancellation, to be redeemed by the Booking Party at the same URL originally used to make the booking.</li>
            <li>The value of the refund will be the full amount paid for the reservation and not the full price of the trip. Coupons and discounts applied will not be taken into account in the refund amount.</li>
          </ol>
          <h3>14. Meeting the Transport  Provider</h3>
          <p style={{textAlign:'justify'}}>It is your responsibility to wait for the transfer at the location specified in your booking confirmation email or as otherwise agreed between you and the Transport  Provider. You should arrive at this location at least 15 minutes prior to the Pick-up Departure Time agreed with, or advised by, the Transport Provider.</p>
          <p style={{textAlign:'justify'}}>As the arrival time from your Pick-up Point to your Destination may vary depending on traffic and many other conditions, we recommend that you calculate this time using Google and similar applications at the time of booking. The estimated time given by the Transport Provider is for the sole purpose of assisting you is not binding and you will not be able to claim compensation if you are late at your destination according to this estimated time of arrival.</p>
          <p style={{textAlign:'justify'}}>If you do not cancel the transportation services within the cancellation period specified by the time you selected your transportation services, even if you do not use the transportation services (for example, you are a "no show"), you will not be entitled to a refund.</p>
          <p style={{textAlign:'justify'}}>If you are delayed and your selected Transport Service does not offer a delay time, you must contact your Transport  Provider as soon as possible. Alternative collection arrangements are subject to the availability of the Transport  Provider and it is at their discretion whether to wait for you or charge you the full fare for no-show. Failure to contact your Transport  Provider will be considered a no show and you will not be entitled to a refund.</p>
          <p style={{textAlign:'justify'}}>If the Transport  Provider does not arrive to collect you and has not contacted you before your departure time, you should contact your Transport  Provider as soon as possible. If you cannot contact the Transport  Provider, make sure you do not miss your flight or other connections and make alternative transport arrangements. Notify Limowide as soon as possible (within three working days at the latest) using the special link sent to your provided email address with proof that your driver did not meet you at the designated pick-up location. If this is the fault of the Transport  Provider or the driver, you will be entitled to a full refund.</p>
          <p style={{textAlign:'justify'}}>If the Transport  Provider fails to collect you for any reason other than the fault of the Transport  Provider or the Driver or for any reason beyond the reasonable control of the Transport  Provider or the Driver, or if you are delayed for any reason, this may result in the Transport  Provider (or Driver) being unable to fulfil your booking.</p>
          <h3>15. Airport Pickups and Drop Offs</h3>
          <p style={{textAlign:'justify'}}>All Airport bookings for Pickups are carried out from Pick up points which are usually designated points on all airports in the U.K. and elsewhere. If the driver deems an alternative pickup point is appropriate the driver or company will inform the passenger prior to pick up.</p>
          <p style={{textAlign:'justify'}}>The Driver will not come in the Arrivals Hall to collect passenger. This is known as ‘Meet and Greet’ and is separate service only available upon request and incur extra charges.</p>
          <p style={{textAlign:'justify'}}>In the event the driver has to go inside the Arrivals Hall to collect passenger, additional charges will be applied including Parking Charges and time taken to collect passenger based on Hourly Rate of £20.</p>
          <p style={{textAlign:'justify'}}>All Airport Drop Offs are carried out at the Drop Off point usually outside most Airports in the U.K. and elsewhere. If the driver deems an alternative drop off point is appropriate, the driver or company may inform the passenger prior to drop off or during journey.</p>
          <p style={{textAlign:'justify'}}>If the driver is asked/required to go inside the car park to drop off the passenger, additional car park charges will be applied.</p>
          <h3>16. Baggage</h3>
          <p style={{textAlign:'justify'}}>You are entitled to carry baggage within the limits of quantity and size for the carriage selected at the time of booking. Oversized baggage (and other items such as golf bags, skis, etc.) must be declared at the time of booking and may result in additional fees/charges being payable to the Carrier. Extra or oversized baggage is subject to the availability of space in the hold and your booking may be refused if the Carrier is unable to accommodate it. You may be required to upgrade your vehicle type.</p>
          <p style={{textAlign:'justify'}}>However, the Transport Provider may charge a fee for excess baggage or refuse to carry the excess baggage without prior agreement.</p>
          <h3>17. Additional Passenger</h3>
          <p style={{textAlign:'justify'}}>Our listed transport companies do not charge for additional passengers, but as the features and capacity of the vehicle to be selected will vary according to the number of passengers, we recommend that you contact the company directly for additional passengers after booking. Depending on the situation, the Transport  Provider may provide you with a different vehicle and charge you extra for it, or refuse to provide the service for additional passengers.</p>
          <p style={{textAlign:'justify'}}>Please note that you can always cancel your booking within 24 hours and make a new booking by adding an additional number of passengers.</p>
          <h3>18. Special Requests Or Requirements</h3>
          <p style={{textAlign:'justify'}}>All special requests, including VIP escort and meet and greet services, are subject to the ability of the Carrier to accommodate such requests. We cannot guarantee that any special request will be met by the Carrier. We recommend that you contact the Transport  Provider directly prior to your pick-up time to confirm your special requirements. Additional charges may apply and if so you will need to pay these directly to the carrier.</p>
          <h3>19. Child Passengers And Child Seat</h3>
          <p style={{textAlign:'justify'}}>For certain destinations you may have booked (e.g. the European Union), children under the age of two may travel without a child seat as long as they are seated in an adult seat. However, the relevant authorities state that children must use an appropriate child seat until they are 12 years old or reach a height of 135 cm.</p>
          <p style={{textAlign:'justify'}}>Please note that all children and infants, regardless of age, count as occupants of the vehicle and must be included in the total number of passengers at the time of booking.</p>
          <p style={{textAlign:'justify'}}>The Transport Provider will endeavour to provide child seats where requested (and in some cases paid for by you at the time of booking). Please ensure that you are aware of local regulations when travelling with children. Please note that we cannot guarantee that child seats requested at the time of booking will be provided. All special requests, including child or baby seats, are subject to the carrier's ability to accommodate such requests. Also, Child seats are not mandatory in certain countries therefore drivers are not obliged to provide one.</p>
          <h3>20. Disabled Or Special Needs Passengers</h3>
          <p style={{textAlign:'justify'}}>We and our listed Service Providers are not specialist disabled Transport  Providers but will use reasonable endeavours to meet your requirements. If you or any member of your party has a medical condition or disability that may affect your ability to use our standard services, please use our listing feature to accommodate your special needs. You can always let us know of your requirements by writing us an order note prior to booking so that we can ensure that the correct vehicle is booked to meet your needs.</p>
          <h3>21. Last Minute Bookings</h3>
          <p style={{textAlign:'justify'}}>Each Transport  Provider has different pre-bookings times, therefore it is not possible for every company and vehicle to accept last minute passengers. Fortunately, this is also a listing feature of Limowide.</p>
          <p style={{textAlign:'justify'}}>If you need to make a reservation close to the time you want to be picked up, the minimum number of hours required to book before the pick up time will vary depending on the Transport Provider listed. We recommend that you choose the Transport  Provider carefully and check their minimum hour limit so that you can take advantage of the fast booking service we offer.</p>
          <h3>22. Severability</h3>
          <p style={{textAlign:'justify'}}>If any provision or part of any provision of these terms and conditions is found by any court or other competent authority to be illegal, invalid or unenforceable, then that provision or part of that provision will be struck out of these terms and conditions and the remainder of these terms and conditions will apply as if the offending provision or part of that provision had never been agreed.</p>
          <h3>23. Applicable Law And Jurisdiction</h3>
          <p style={{textAlign:'justify'}}>These terms and conditions and any dispute or claim arising out of or in connection with them (including any non-contractual dispute or claim) shall be governed by and construed in accordance with English law and the parties agree to submit to the exclusive jurisdiction of the Courts of England and Wales. Any dispute or claim arising out of or in connection with these terms and conditions (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English courts.</p>
          <p style={{textAlign:'justify'}}>All services provided are subject to above terms and conditions. By booking with us online, by phone, chat, email or directly with our driver, you are agreeing to the terms and conditions mentioned here.</p>
          <h3>24. Complaints</h3>
          <p style={{textAlign:'justify'}}>Because of the contract between you and the Transport  Provider, any queries or concerns regarding your service should be addressed to the Transport  Provider. If you have a problem during your journey, this must be reported to the Transport Provider immediately upon arrival at your destination. The Transport  Provider's contact details are on the booking confirmation email we send when your booking is confirmed.</p>
          <p style={{textAlign:'justify'}}>If you do not follow this procedure, there will be less opportunity to investigate and rectify your complaint. However, as payment is not transferred to the transport provider  within three working days, if there is a problem with the transport service you have or have not received, you can contact us within three working days regarding your payment.</p>
          <h3>25. Contact Information</h3>
          <p style={{textAlign:'justify'}}>If you have any questions about Limowide, the Service or the Terms, please contact us at <a href="">support@Limowide.com</a>.</p>
          <h3>26. Privacy</h3>
          <p style={{textAlign:'justify'}}>We care about the security of you and your information. Please be assured that we will not use your sensitive information for any purpose. You can see the details of all the personal information we collect to provide you with transport services. We protect your data according to the UK GDPR and take the privacy of our users seriously. Your name and contact details are shared with driver only to carry out the service and will be removed afterwards.</p>
          <h3>27. User Content</h3>
          <blockquote>
            <h5>27.1. Limited licence granted to Limowide</h5>
            <p style={{ textAlign: 'justify' }}>By posting User Content, you grant Limowide a worldwide, non-exclusive, transferable, assignable, fully paid-up, royalty-free, perpetual, irrevocable right and license to host, transmit, display, perform, reproduce, modify, distribute and redistribute, and otherwise exploit your User Content, in whole or in part, in any media formats and through any media channels (now known or hereafter developed). You shall retain all copyright and other proprietary rights in any User Content that you post to the Service.</p>
            <p style={{ textAlign: 'justify' }}>It is not allowed to eat, drink, or smoke in the vehicle.</p>
            <p style={{ textAlign: 'justify' }}>Children in cars should be secured in a way that is appropriate for their height, weight, and age.</p>
            <p style={{ textAlign: 'justify' }}>It is not permitted to transport more passengers than what is required by insurance or licensing.</p>
            <h5>27.2. User content representations and warranties</h5>
            <p style={{textAlign:'justify'}}>You are solely responsible for your User Content and the consequences of posting or publishing User Content. By posting and publishing User Content, you affirm, represent and warrant that:</p>
            <p style={{textAlign:'justify'}}>You are the creator and owner of, or have the necessary licences, rights, consents and permissions to use and authorise Limowide and the users of the Service to use and distribute your User Content as necessary to exercise the licences granted by you in this Section and in the manner contemplated by Limowide and the Terms;</p>
            <p style={{textAlign:'justify'}}>Your User Content and the use thereof as contemplated herein does not and will not: </p>
            <ol type='a'>
              <li>infringe, violate or misappropriate the rights of any third party, including any copyright, trademark, patent, trade secret, moral right, right of privacy, right of publicity or any other intellectual property or proprietary right;</li>
              <li>libel, defame, slander or otherwise violate the privacy, publicity or other proprietary rights of any other person;</li>
              <li>contain viruses, adware, spyware, worms or other malicious code;</li>
              <li>violate any applicable law or regulation.</li>
            </ol>
            <h5>27.3 User Content Disclaimer</h5>
            <p style={{textAlign:'justify'}}>We have no obligation to edit or control the User Content that you and other users post or publish, and we are not responsible or liable in any way for User Content. You understand that in using the Service you will be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent or objectionable. You agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against Limowide with respect thereto. We expressly disclaim any and all liability in connection with user content. If we are notified by a user or content owner that User Content allegedly does not comply with the Terms, we may investigate the allegation and determine in our sole discretion whether to remove the User Content, which we reserve the right to do at any time and without notice. For clarity, Limowide does not permit copyright infringing activities on the Service.</p>
            <h5>27.4 Liability, liquidated damages</h5>
            <p style={{textAlign:'justify'}}>Subject to the following provisions of this clause, except in respect of death or personal injury caused by the Company’s negligence, or that of its servants or agents, the Company’s liability to the Customer for loss and/or damage caused by the negligence of the Company and/or its servants or agents, or otherwise which arises out of or in connection with the provision of the Services or their use by the Customer shall be limited as follows:</p>
            <p style={{textAlign:'justify'}}>In relation to the Services generally, the Company’s liability shall not exceed £100;</p>
            <p style={{textAlign:'justify'}}>In the case of lost or damaged Goods (including where relevant luggage of Customers traveling in Passenger Vehicles), the Company’s liability shall not exceed £100 unless the Customer has notified the Company that the Goods have a value in excess of £100 and the Company has agreed in writing to be responsible for the repair or replacement of the Goods up to a greater value and the Customer shall indemnify the Company against any Passenger claiming sums in excess of such limits.</p>
            <ol>
              <li>The liability of Limowide is limited or excluded as follows, with the exception of liability under the Product Liability Act and due to injury to life, body or health: In case of negligence, the liability of Limowide is limited to the compensation of the typically foreseeable damage. In the event of slight negligence, Limowide shall only be liable if Limowide has breached an obligation whose fulfilment is a precondition for the proper performance of the contract, in particular for the use of the booking platform in a manner which is commercially reasonable with regard to the interests of both parties, and on the fulfilment of which you may rely.</li>
              <li>The foregoing limitations shall also apply in favour of Limowide's legal representatives and vicarious agents in the event that claims are asserted directly against Limowide.</li>
              <li>Furthermore, Limowide does not assume any liability for the correctness and completeness of the information transmitted to Limowide and forwarded by Limowide in due time and in unchanged form, or for the timely receipt of such information by the transport company registered with Limowide or, as the case may be, you. Limowide shall not be liable for any loss of data on part of you, as it is your responsibility to ensure regular and proper data backup.</li>
              <li>Limowide shall not be liable for any costs resulting from delays caused by  you or  third parties.</li>
              <li>To the maximum extent permitted by applicable law, in no event will the Limowide entities or any other party involved in creating, producing, operating or delivering the service be liable for any incidental, special, consequential or punitive damages, whether based on warranty, contract, tort (including negligence), statute or any other legal theory, whether or not the Limowide entities have been advised of the possibility of such damages, arising from (i) your access to or use of, or the inability to access or use, the service; or (ii) any purchase of any third party product or service based on information contained in the Limowide service. You expressly acknowledge that the Limowide entities are not liable for the defamatory, offensive, or illegal conduct of other users or third parties, and that the risk of injury from the foregoing rests entirely with you. Further, the Limowide entities shall have no liability to you or any third party for any third party content uploaded to or downloaded from the site or through the service.</li>
            </ol>
          </blockquote>   
          <h3>28. Prohibited Conduct</h3>     
          <p style={{textAlign:'justify'}}>BY USING THE SERVICE, YOU AGREE NOT TO:</p>   
          <ol>
            <li>Rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licences granted herein or any Materials (as defined below)</li>
            <li>Post, upload or distribute any defamatory, libellous or inaccurate User Content or any other content;</li>
            <li>Post, upload or distribute any User Content or other content that is unlawful or that a reasonable person would find objectionable, profane, abusive, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially, ethnically or otherwise objectionable;</li>
            <li>Impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with a person or entity, access another's account on the Service without authorization, forge another's digital signature, misrepresent the origin, identity or content of any information transmitted through the Service, or engage in any other similar fraudulent activity;</li>
            <li>Remove any copyright or other proprietary notices from the Service or any User Content;</li>
            <li>Make unsolicited offers, promotions, solicitations, junk mail or spam to other users of the Service. This includes, but is not limited to, unsolicited advertising, promotional or solicitation material, mass mailing of commercial advertising, chain mail, informational announcements, charitable solicitations, and petitions for signatures;</li>
            <li>Use the Service for any unlawful purpose or in violation of any local, state, national or international law, including, without limitation, laws governing intellectual property and other proprietary rights, data protection and privacy, or the collection, storage, transmission or access of educational records;</li>
            <li>Defame, harass, abuse, threaten or defraud users of the Service, or collect or attempt to collect personal information about users or third parties without their consent,</li>
            <li>Use the Service for any commercial purpose other than the internal business purposes of maintaining and managing school records without our consent;</li>
            <li>Remove, bypass, disable, damage or otherwise interfere with any security features of the Service or User Content, features that prevent or restrict the use or copying of any content accessible through the Service, or features that enforce limitations on the use of the Service or User Content;</li>
            <li>Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Service or any part thereof (including any Application), except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation;</li>
            <li>Modify, adapt, translate or create derivative works based on the Service or any part thereof, except and only to the extent that the foregoing restriction is expressly prohibited by applicable law;</li>
            <li>Intentionally interfere with or damage the operation of the Service or any other user's enjoyment of the Service by any means, including uploading or otherwise distributing viruses, adware, spyware, worms or other malicious code.</li>
          </ol>
          <h3>29. Termination of Use; Discontinuation and Modification of the Service</h3>
          <p style={{textAlign:'justify'}}>If you breach any of the Terms, your permission to use the Service will automatically terminate. You also agree that we may suspend or terminate your access to and use of the Service and any accounts you may have in connection with the Service at any time without notice to you, including: (i) when we determine, in our sole discretion, that such action is reasonable to comply with legal requirements or to protect the rights or interests of Limowide or any third party; or (ii) in connection with any general discontinuance of the Service. We also reserve the right to modify the Service at any time without notice to you. We will not be liable for any changes to the Service or for any suspension or revocation of your access to or use of the Service, provided that if we terminate your access to the Service for any reason other than your breach of these Terms, you may be entitled to have your Rewards distributed as described in the Terms. You may terminate your account at any time by contacting customer support at support@Limowide.com. If you terminate your account, you will remain liable for all outstanding fees and charges, if any, in respect of your use of the Service prior to termination.</p>
          <p style={{textAlign:'justify'}}>If any vehicle or driver is put at risk by you or another passenger either through violence, abuse, discrimination or passing of any comments either racial or otherwise, we reserve the right to cancel or refuse any booking with immediate effect. No refunds will be given for any trip that is cancelled during the hire.</p>
          <h3>30. Ownership; Proprietary Rights</h3>
          <p style={{textAlign:'justify'}}>The Service is owned and operated by Limowide. The visual interfaces, graphics, design, compilation, information, computer code (including source code or object code), products, software, services and all other elements of the Service provided by Limowide (the "Materials") are protected by United Kingdom copyright, trade dress and trademark laws, international conventions and all other relevant intellectual property and proprietary rights and applicable laws. Except for User Content provided by and owned by users of the Service, all Materials contained in the Service are the property of Limowide or its subsidiaries or affiliates and/or third party licensors. All trademarks, service marks and trade names are the property of Limowide or its affiliates and/or third party licensors. Except as expressly authorized by Limowide, you agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials. Limowide reserves all rights to the Materials not expressly granted in these Terms and Conditions.</p>
          <h3>31. Indemnity</h3>
          <p style={{textAlign:'justify'}}>You agree that you are personally responsible for your use of the Service and you agree to defend, indemnify and hold harmless Limowide and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (collectively, the "Limowide Entities") from and against any and all claims, liabilities, damages, losses and expenses, including reasonable attorneys' and accountants' fees and costs, arising out of or in any way connected with (i) your access to, use of or alleged use of the Service; (ii) your breach of the Terms or any representation, warranty or agreement referred to herein or any applicable law or regulation; (iii) your violation of any third party rights, including without limitation any intellectual property, publicity, confidentiality, property or privacy rights; or (iv) any dispute or matter between you and any third party. We reserve the right, at our own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you agree to cooperate with our defence of such claim.</p>
          <h3>32. Dispute resolution and arbitration</h3>
          <p style={{textAlign:'justify'}}>In the interest of resolving disputes between you and Limowide in the most expedient and cost-effective manner, you and Limowide agree that all disputes arising in connection with these Terms of Service shall be resolved by binding arbitration. Arbitration is more informal than litigation. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than a court, and may be subject to very limited review by a court. Arbitrators may award the same damages and relief that a court may award. Our agreement to arbitrate disputes includes, but is not limited to, all claims arising out of or relating to any aspect of these Terms, whether based on contract, tort, statute, fraud, misrepresentation or any other legal theory, and whether the claims arise during or after the termination of these Terms. You understand and agree that by entering into these Terms, you and Limowide each waive the right to a trial by jury or to participate in a class action.</p>
          <h3>General</h3>
          <p style={{textAlign:'justify'}}>The Terms, together with the Privacy Policy and any other agreements expressly incorporated by reference herein, constitute the entire and exclusive understanding and agreement between you and Limowide with respect to your use of and access to the Service, and, except as expressly permitted above, may only be modified by a written agreement signed by the parties' authorized representatives. You may not assign or transfer the Terms or any of your rights hereunder, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign the Terms at any time without notice to you. A failure to require performance of any provision shall not affect our right to require performance at any time thereafter, nor shall a waiver of any breach or default of the Terms constitute a waiver of any subsequent breach or default or a waiver of the provision itself. The use of section headings in the Terms is for convenience only and shall not affect the interpretation of any provision. If any part of the Terms is held to be invalid or unenforceable, the unenforceable part will be given effect as far as possible and the remaining parts will remain in full force and effect.</p>
        </Col>
      </section>
    </>
  );
};

export default TermsConditions;
