import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import config from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleApiError } from '../utils/errorHandler';
// 
import linkedin from "../images/Linkedin.png";
import twitter from "../images/Twitter.png";
import Facebook1 from "../images/Facebook1.png";
import Whatsapp from "../images/Whatsapp.png";
import Pinterest from "../images/Pinterest.png";
import BouncingLoader from '../components/BouncingLoader';
const SignIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emailInputerror, setEmailInputerror] = useState("");
  const validateForm = () => {
    let isValid = true;
    if (!emailInput.trim()) {
      setEmailInputerror('Email is required');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(emailInput)) {
      setEmailInputerror('Email is invalid');
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (loading) return; // prevent function if already loading

      setLoading(true); // set loading state to true
      axios.post(`${config.forgotPassword}`, {
        email: emailInput
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        }
      },
      ).then(result => {
        toast.success(result.data.detail);
        sessionStorage.setItem("reset_pwd_mail", emailInput);
        navigate("/reset-password");
      })
      .catch(error => {
        handleApiError(error.response.data.detail)
      })
      .finally(() => {
        setLoading(false); // reset loading state
      });
    }
  };
  return (
    <main className="container-fluid mt-5 pb-7 bgimage">
      <Row className="justify-content-center">
        <Col lg={'8'} className="">
          <Card className="shadow-lg" style={{ border: 'none' }}>
            <Row className="g-0">
              <Col md={'6'} className="d-none d-md-block">
                <div className="h-100 w-100 login-card-bg p-4">
                  <div className='welcometext'>
                    <h2 className='FontFamilyProstoOne'>Welcome Back!</h2>
                    <p>Your trusted partner for airport transfer</p>
                    <div className="d-flex alig-items-center justify-content-center gap-3">
                      <Link to={'/'} className=''><img className='img-fluid' src={linkedin} alt='icon' /></Link>
                      <Link to={'/'} className=''><img className='img-fluid' src={twitter} alt='icon' /></Link>
                      <Link to={'/'} className=''><img className='img-fluid' src={Facebook1} alt='icon' /></Link>
                      <Link to={'/'} className=''><img className='img-fluid' src={Pinterest} alt='icon' /></Link>
                      <Link to={'/'} className=''><img className='img-fluid' src={Whatsapp} alt='icon' /></Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={'6'} className="px-5 py-4">
                <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>Forgot Password</h2>
                <p className='text-center pragraphText' style={{ lineHeight: '20px' }}>Sign in for seamless access or create an account to unlock exclusive perks.</p>
                <form  onSubmit={handleSubmit}  className="loginform mt-5">
                  <div className="mb-3">
                    <Form.Control type="email" value={emailInput} onChange={(e) => { setEmailInput(e.target.value); setEmailInputerror(''); }}  className="email-icon" placeholder="Email Address" required />
                    {emailInputerror && <span style={{ color: 'red' }}>{emailInputerror}</span>}
                  </div>
                  <Button variant={''}  type="submit" className="loginbtn w-100">
                    {loading ? <BouncingLoader/> : 'Submit'}  </Button>
                  <p className="text-center mt-3 pragraphText">Don't have an account? <Link to="/signin" className='fw-bolder blackText'>Sign In</Link></p>
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </main>
  );
};

export default SignIn;
