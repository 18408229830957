import React,{useEffect} from 'react';
import { Breadcrumb,Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const RegisterGuide = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)',borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Register <span className=''>Guide</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Register Guide</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>        
      {/*  */}
      <section className='py-4'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>content</Col>
      </section>
    </>
  );
};

export default RegisterGuide;
