import { useEffect } from 'react';
import Linkedin from "../images/Linkedin.png";
import Twitter from "../images/Twitter.png";
import Facebook1 from "../images/Facebook1.png";
import Pinterest from "../images/Pinterest.png";
import Whatsapp from "../images/Whatsapp.png";
import Insta from "../images/Insta.png";
const SocialLinks = () => {
  return (
    <>
            <a className='' target='_blank' href='https://www.linkedin.com/company/limowide/'><img className='img-fluid' src={Linkedin} alt='Linkedin'/></a>
            <a className='' target='_blank' href='https://twitter.com/limowide?s=08'><img className='img-fluid' src={Twitter} alt='Twitter'/></a>
            <a className='' target='_blank' href='https://www.facebook.com/limowide'><img className='img-fluid' src={Facebook1} alt='Facebook1'/></a>
            <a className='' target='_blank' href='https://www.pinterest.de/limowide/'><img className='img-fluid' src={Pinterest} alt='Pinterest'/></a>
            <a className='' target='_blank' href='https://wa.me/447458148595'><img className='img-fluid' src={Whatsapp} alt='Whatsapp'/></a>
            <a className='' target='_blank' href='https://www.instagram.com/limowide/'><img className='img-fluid' src={Insta} alt='Instagram'/></a>
    </>
  );
};

export default SocialLinks;