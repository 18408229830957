import React, { useEffect, useState } from "react";
import { Table, Modal, Card, Button, Form } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { getStoredUser } from "../utils/localStorageUtils";
import { handleApiError } from "../utils/errorHandler";
import { addDays, format, parse } from 'date-fns';
import Loading from "./Loading";
import config from "../config";
import axios from 'axios';
import userIcon4 from '../images/userIcon4.svg';
import breifIcon from '../images/breifIcon.svg';
import PhoneIcon from '../images/phoneicon1.svg';
import EmailIcon from '../images/emailVector.svg';
import aVector from '../images/aVector.svg';
import bVector from '../images/bVector.svg';
import { toast } from 'react-toastify';
import BouncingLoader from "./BouncingLoader";
function Trips() {
    const navigate = useNavigate();
    const storedUser = getStoredUser();
    const [menu, setMenu] = useState("upcoming");
    const [trips, setTrips] = useState([]);
    const [tripbooking_id, setTripBookingId] = useState(false);
    const [isLoading, setLoadingTrips] = useState(false);
    const [userType, setUserType] = useState(localStorage.getItem('userType'));
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
    const [modifymodalIsOpen, setModifymodalIsOpen] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passenger, setpassenger] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [flightNumberinput, setFlightNumberinput] = useState(false);
    const [specialInstructions, setSpecialInstructions] = useState(false);
    const [pickupSign, setPickupSign] = useState(false);
    const closeModal = () => {
        setIsConfirmModalOpened(false);
        setTripBookingId(false);
    };
    const closeModalDetail = () => {
        setModifymodalIsOpen(false);
        setTripBookingId(false);
    };
    const loadTrips = async () => {
        setLoadingTrips(true);
        axios.get(`${config.TripsData}${menu.toLowerCase()}/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
                "Authorization": `Bearer ${storedUser.token.access_token}`
            }
        }).then(result => {
            if (result?.data?.data) {
                if (result?.data?.data) {
                    setTrips(result?.data?.data);
                }
            }
        })
            .catch(error => {
                handleApiError(error)
            })
            .finally(() => {
                setLoadingTrips(false);
            });
    };

    useEffect(() => {
        return () => {
            setMenu("upcoming");
        };
    }, []);

    useEffect(() => {
        loadTrips();
    }, [menu]);

    const cancelBooking = async (booking_id) => {
        const configs = {
            method: "GET",
            url: `${config.CancelBooking}${booking_id}/`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
                "Authorization": `Bearer ${storedUser.token.access_token}`
            }
        };
        await axios(configs)
            .catch((e) => {
                toast.error(e.message);
                console.log(e);
            })
            .then((res) => {
                if (res?.data?.data?.is_cancel) {
                    toast.success(res?.data?.message || "Booking  is canceled successful ");
                    setIsConfirmModalOpened(false);
                    setTripBookingId(false);
                    setTimeout(
                        function () {
                            loadTrips();
                        }.bind(this),
                        1000
                    );
                } else {
                    toast.error(res?.data?.message);
                }
            });
    };
    function formatBookingDate() {
        // const parsedDate = parse(dateStr, 'dd MMM-yyyy', new Date());
        // return format(parsedDate, 'dd-MM-yyyy HH:mm');
        const currentDate = new Date();
        const nextDay = addDays(currentDate, 1);
        return format(nextDay, 'dd-MM-yyyy HH:mm');
    }
    const bookAgain = (trip) => {
        localStorage.removeItem("booking_id");
        if (trip?.destination === null) {
            const pickupCoor = trip?.point_a;
            const [latitude, longitude] = pickupCoor.split(',').map(Number);
            const params = new URLSearchParams();
            params.append("date", formatBookingDate());
            params.append("return_date", null);
            params.append("return_pickup_time", null);
            params.append("time", '00:00');
            params.append("pickup", trip?.pickup);
            params.append("pickup_lat", latitude);
            params.append("pickup_lng", longitude);
            params.append("drop_lat", null);
            params.append("drop_lng", null);
            params.append("dropoff", null);
            params.append("pass", 1);
            params.append("bags", 1);
            params.append("pickup_type", "place");
            params.append("dropoff_type", "place");
            params.append("perhour", 'on');
            params.append("timebased", 1);
            params.append("booking_type", "hourly");
            const paramsObject = {};
            for (const [key, value] of params) {
                paramsObject[key] = value;
            }
            // Step 2: Store Object in localStorage
            localStorage.setItem('search_param', JSON.stringify(paramsObject));
            navigate("/results?" + params.toString());




            //    navigate("/results?date=" + formatBookingDate(trip?.booking_date) + "&return_date=&return_pickup_time=&time=00:00&pickup=" + trip?.pickup + "&pickup_lat="+latitude+"&pickup_lng="+longitude+"&drop_lat=&drop_lng=&dropoff=" + trip?.destination + "&pass=1&bags=1&booking_type=hourly");
        } else {
            const pickupCoor = trip?.point_a;
            const [latitude, longitude] = pickupCoor.split(',').map(Number);
            const destinationCoor = trip?.point_b;
            const [latituded, longituded] = destinationCoor.split(',').map(Number);
            const params = new URLSearchParams();
            params.append("date", formatBookingDate());
            params.append("return_date", null);
            params.append("return_pickup_time", null);
            params.append("time", '00:00');
            params.append("pickup", trip?.pickup);
            params.append("pickup_lat", latitude);
            params.append("pickup_lng", longitude);
            params.append("drop_lat", latituded);
            params.append("drop_lng", longituded);
            params.append("dropoff", trip?.destination);
            params.append("pass", 1);
            params.append("bags", 1);
            params.append("pickup_type", "place");
            params.append("dropoff_type", "place");
            params.append("booking_type", "p_to_p");
            const paramsObject = {};
            for (const [key, value] of params) {
                paramsObject[key] = value;
            }
            // Step 2: Store Object in localStorage
            localStorage.setItem('search_param', JSON.stringify(paramsObject));
            navigate("/results?" + params.toString());
            // navigate("/results?date=" + formatBookingDate(trip?.booking_date) + "&return_date=&return_pickup_time=&time=00:00&pickup=" + trip?.pickup + "&pickup_lat="+latitude+"&pickup_lng="+longitude+"&drop_lat="+latituded+"&drop_lng="+longituded+"&dropoff=" + trip?.destination + "&pass=1&bags=1&booking_type=p_to_p");
        }
    };
    const modifydetails = (trip) => {
        setModifymodalIsOpen(true);
        setpassenger(trip?.passenger_name);
        setmobile(trip?.passenger_mobile);
        setPickupSign(trip?.special_instructions);
        setSpecialInstructions(trip?.special_instructions);
        setFlightNumberinput(trip?.flight);
        setTripBookingId(trip.booking_id);       
    };
    const handleInputChange = (event) => {
        setpassenger(event.target.value); // Update state with input value
    };
    const handleInputMobileChange = (event) => {
        setmobile(event.target.value); // Update state with input value
    };
    const handleInputflightNumberChange = (event) => {
        setFlightNumberinput(event.target.value); // Update state with input value
    };
    const handleInputspecialInsChange = (event) => {
        setSpecialInstructions(event.target.value); // Update state with input value
    };
    const handleInputPickupsignChange = (event) => {
        setPickupSign(event.target.value); // Update state with input value
    };
    const UpdateDetials = async () => {
        setLoading(true);
        const url = `${config.BookingUpdateDetails(tripbooking_id)}`;
      
        const data = {
          passenger_name: passenger,
          passenger_mobile: mobile,
          pickup_sign: pickupSign,
          special_instructions: specialInstructions,
          flight_no: flightNumberinput
        };
      
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            "Authorization": `Bearer ${storedUser.token.access_token}`
        };
        // Simple validation to check if all fields are non-empty
        const areFieldsValid = Object.values(data).every(value => value.trim() !== "");

        if (!areFieldsValid) {
        toast.error("All fields must be filled!");
        return;  // Stop execution if validation fails
        }
        try {
          const response = await axios.put(url, data, { headers });
          if(response.status === 200){
            setLoading(false);
            toast.success("Booking Modify successfully");
            setModifymodalIsOpen(false);
          }
        } catch (error) {
           setLoading(false); 
          console.error('Error modifying booking details:', error);
        }
    };
    
    return (
        <>
            <div className='text-center mb-5'>
                {storedUser?.type === "driver" ?
                    <>
                        <h6 className={'secondaryText fw-bold mb-0'} style={{ fontSize: '28px' }}>Jobs</h6>
                        <p>See your Jobs details, upcoming and cancelled jobs list and other details</p>
                    </>
                    :
                    <>
                        <h6 className={'secondaryText fw-bold mb-0'} style={{ fontSize: '28px' }}>Trips</h6>
                        <p>See you Trips details, Past, Upcoming, current Booking list with driver specifications and more details.</p>
                    </>
                }

            </div>
            <Tabs className={'tripTabs'} default={"ltr"}>
                <div className='d-md-flex align-items-center justify-content-between mb-4'>
                    <p className='mb-md-0 mb-4' style={{ fontWeight: '600' }}>{trips?.length} {menu} bookings</p>
                    <TabList>
                        <Tab onClick={() => setMenu("upcoming")}> Upcoming</Tab>
                        <Tab onClick={() => setMenu("past")}>Past</Tab>
                        {userType === "driver" ? null : (
                            <Tab onClick={() => setMenu("cancel")}> Cancelled</Tab>
                        )}

                    </TabList>
                </div>
                <TabPanel>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-96">
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <Card className='whiteBG mb-4' style={{ border: 'none', overflow: 'hidden', borderRadius: '24px', }}>
                                <Card.Body className='p-0'>
                                    <Table responsive className='m-0 mb-3 tableContent d-md-block d-none'>
                                        <thead>
                                            <tr>
                                                <th>Booking</th>
                                                <th>Date</th>
                                                <th>Pickup/Dropoff</th>
                                                <th>Passanger</th>
                                                {userType === "driver" ? null : (
                                                <th>Cancellation</th>
                                                )}
                                                {userType === "driver" ? null : (
                                                <th>Price</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(trips) && trips.length > 0 ? (
                                                trips.map((trip, index) => (
                                                    <>
                                                        <tr key={`trip-row-${trip?.booking_id}-${index}`} className="spaceUnder">
                                                            <td>#{trip?.booking_id}</td>
                                                            <td>{trip?.booked_on}</td>
                                                            <td>
                                                                <div className="d-flex mb-3">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={aVector} alt='icon' /></div>
                                                                    <div>{trip?.pickup}</div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={bVector} alt='icon' /></div>
                                                                    <div>{trip?.destination}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <p className="d-flex mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" src={userIcon4} alt='icon' /> <strong>{trip?.passenger_name}</strong></p>
                                                                    <p className="d-flex  mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={PhoneIcon} alt='icon' /> <strong>{trip?.passenger_mobile}</strong></p>
                                                                    <p className="d-flex  mb-3" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={EmailIcon} alt='icon' /> <strong>{trip?.passenger_email}</strong></p>
                                                                    <div className="d-flex" style={{ gap: '10px' }}>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={userIcon4} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_passengers}</strong></div></p>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={breifIcon} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_bags}</strong></div></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {userType === "driver" ? null : (
                                                            <td><p className='redText m-0' style={{ fontSize: '14px' }}>*Free cancellation until {trip?.cancellationDetails?.freeCancellationUntil}</p></td>
                                                            )}
                                                            {userType === "driver" ? null : (
                                                            <td><p className='m-0 fw-bold darkgreenText' style={{ fontSize: '18px' }}>{trip?.fare_human}</p></td>
                                                            )}
                                                            <div className="d-none justify-content-center groupingbtn" style={{ gap: '12px' }}>
                                    
                                                                {userType === "driver" ? null : (
                                                                    <Button onClick={() => {
                                                                        navigate("/booking-voucher", {
                                                                            state: {
                                                                                ...trip,
                                                                                firstName: storedUser?.first_name,
                                                                                lastName: storedUser?.last_name,
                                                                                bookingId: trip.booking_id,
                                                                                pickupDate: trip.booked_on,
                                                                                mobile: trip.passenger_mobile,
                                                                                passenger: trip.num_passengers,
                                                                                luggage: trip.num_bags,
                                                                                distance: `${parseInt(trip.distance) / 1000 || "N/A"} KM`,
                                                                                flightNumber: trip.flight,
                                                                                specialInstructions: trip.special_instructions,
                                                                                car: trip.category,
                                                                                waitTime: trip.waitTime,
                                                                                car_raw: { fleet_image: `${trip.carImage}` }
                                                                            },
                                                                        });
                                                                    }} style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} className='allView-btn'>Detail</Button>
                                                                )}
                                                                {userType === "driver" ? null : (
                                                                    <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => { bookAgain(trip); }} className='allView-btn'>Rebook</Button>
                                                                )}
                                                             
                                                                {userType === "driver" ? null : (
                                                                    <Button style={{ height: '40px', fontSize: '14px', maxWidth: '160px', minWidth: '160px' }} onClick={() => {
                                                                        navigate("/booking-voucher", {
                                                                            state: {
                                                                                ...trip,
                                                                                firstName: storedUser?.first_name,
                                                                                lastName: storedUser?.last_name,
                                                                                bookingId: trip.booking_id,
                                                                                pickupDate: trip.booked_on,
                                                                                mobile: trip.passenger_mobile,
                                                                                passenger: trip.num_passengers,
                                                                                luggage: trip.num_bags,
                                                                                distance: `${parseInt(trip.distance) / 1000 || "N/A"} KM`,
                                                                                flightNumber: trip.flight,
                                                                                specialInstructions: trip.special_instructions,
                                                                                car: trip.category,
                                                                                waitTime: trip.waitTime,
                                                                                car_raw: { fleet_image: `${trip.carImage}` }
                                                                            },
                                                                        });
                                                                    }} className='allView-btn'>Download Voucher</Button>
                                                                )}
                                                                {userType === "driver" ? null : (
                                                                    <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => {
                                                                        setIsConfirmModalOpened(true);
                                                                        setTripBookingId(trip.booking_id);
                                                                    }}
                                                                        disabled={!trip.cancellationDetails.cancellable} className='allViewCancel-btn'>Cancel</Button>
                                                                )}
                                                            </div>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="2">No record</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {Array.isArray(trips) &&
                                        trips?.map((trip, index) => (
                                        <>
                                            <div className="mb-4" style={{border:'1px solid #fae069',padding: '10px',borderRadius: '24px'}}>
                                                <div className="w-100 list-unstyled d-md-none d-flex">
                                                    <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '12px 12px 0px 0px'}}>Booking</div>
                                                    <div style={{padding: '5px',width:'75%'}}>#{trip?.booking_id}</div>
                                                </div>
                                                <div className="w-100 list-unstyled d-md-none d-flex">
                                                    <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 0px 0px'}}>Date</div>
                                                    <div style={{padding: '5px',width:'75%'}}>{trip?.booked_on}</div>
                                                </div>
                                                <div className="w-100 list-unstyled d-md-none d-flex">
                                                    <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 0px 0px'}}>Pickup/Dropoff</div>
                                                    <div style={{padding: '5px',width:'75%'}}>
                                                    <div className="d-flex mb-3">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={aVector} alt='icon' /></div>
                                                                        <div>{trip?.pickup}</div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={bVector} alt='icon' /></div>
                                                                        <div>{trip?.destination}</div>
                                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="w-100 list-unstyled d-md-none d-flex">
                                                    <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 0px 0px'}}>Passanger</div>
                                                    <div style={{padding: '5px',width:'75%'}}>
                                                    <div>
                                                                        <p className="d-flex mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" src={userIcon4} alt='icon' /> <strong>{trip?.passenger_name}</strong></p>
                                                                        <p className="d-flex  mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={PhoneIcon} alt='icon' /> <strong>{trip?.passenger_mobile}</strong></p>
                                                                        <p className="d-flex  mb-3" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={EmailIcon} alt='icon' /> <strong>{trip?.passenger_email}</strong></p>
                                                                        <div className="d-flex" style={{ gap: '10px' }}>
                                                                            <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={userIcon4} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_passengers}</strong></div></p>
                                                                            <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={breifIcon} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_bags}</strong></div></p>
                                                                        </div>
                                                                    </div>
                                                    </div>
                                                </div>
                                                {userType === "driver" ? null : (
                                                    <div className="w-100 list-unstyled d-md-none d-flex">
                                                        <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 0px 0px'}}>Cancellation</div>
                                                        <div style={{padding: '5px',width:'75%'}}><p className='redText m-0' style={{ fontSize: '14px' }}>*Free cancellation until {trip?.cancellationDetails?.freeCancellationUntil}</p></div>
                                                    </div>
                                                )}
                                                {userType === "driver" ? null : (
                                                    <div className="w-100 list-unstyled d-md-none d-flex">
                                                        <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 0px 0px'}}>Price</div>
                                                        <div style={{padding: '5px',width:'75%'}}><p className='m-0 fw-bold darkgreenText' style={{ fontSize: '18px' }}>{trip?.fare_human}</p></div>
                                                    </div>
                                                )}
                                                <div className="w-100 list-unstyled d-md-none d-flex">
                                                    <div style={{width:'25%',background:'#0d210c',padding: '5px', color: 'white',borderRadius: '0px 0px 12px 12px'}}>Action</div>
                                                    <div style={{padding: '5px',width:'75%', padding: '3px', gap: '4px', display:'flex', overflow:'scroll'}}>
                                                    {userType === "driver" && (
                                                                        <Button
                                                                            className='allView-btn'
                                                                            style={{ height: '40px', fontSize: '14px', maxWidth: '160px', minWidth: '160px' }}
                                                                            onClick={() => {
                                                                                navigate(`/my-job/${trip?.execution?.executionCode}`);
                                                                            }}
                                                                        //disabled={!trip?.execution?.canStartJourney}
                                                                        >
                                                                            Start Journey
                                                                        </Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button onClick={() => {
                                                                            navigate("/booking-voucher", {
                                                                                state: {
                                                                                    ...trip,
                                                                                    firstName: storedUser?.first_name,
                                                                                    lastName: storedUser?.last_name,
                                                                                    bookingId: trip.booking_id,
                                                                                    pickupDate: trip.booked_on,
                                                                                    mobile: trip.passenger_mobile,
                                                                                    passenger: trip.num_passengers,
                                                                                    luggage: trip.num_bags,
                                                                                    distance: `${parseInt(trip.distance) / 1000 || "N/A"} KM`,
                                                                                    flightNumber: trip.flight,
                                                                                    specialInstructions: trip.special_instructions,
                                                                                    car: trip.category,
                                                                                    waitTime: trip.waitTime,
                                                                                    car_raw: { fleet_image: `${trip.carImage}` }
                                                                                },
                                                                            });
                                                                        }} style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} className='allView-btn'>Detail</Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => { bookAgain(trip); }} className='allView-btn'>Rebook</Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '130px', minWidth: '130px' }} onClick={() => { modifydetails(trip); }} className='allView-btn'>Modify Detail</Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '130px', minWidth: '130px' }} onClick={() => { bookAgain(trip); }} className='allView-btn'>Modify Booking</Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '160px', minWidth: '160px' }} onClick={() => {
                                                                            navigate("/booking-voucher", {
                                                                                state: {
                                                                                    ...trip,
                                                                                    firstName: storedUser?.first_name,
                                                                                    lastName: storedUser?.last_name,
                                                                                    bookingId: trip.booking_id,
                                                                                    pickupDate: trip.booked_on,
                                                                                    mobile: trip.passenger_mobile,
                                                                                    passenger: trip.num_passengers,
                                                                                    luggage: trip.num_bags,
                                                                                    distance: `${parseInt(trip.distance) / 1000 || "N/A"} KM`,
                                                                                    flightNumber: trip.flight,
                                                                                    specialInstructions: trip.special_instructions,
                                                                                    car: trip.category,
                                                                                    waitTime: trip.waitTime,
                                                                                    car_raw: { fleet_image: `${trip.carImage}` }
                                                                                },
                                                                            });
                                                                        }} className='allView-btn'>Download Voucher</Button>
                                                                    )}
                                                                    {userType === "driver" ? null : (
                                                                        <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => {
                                                                            setIsConfirmModalOpened(true);
                                                                            setTripBookingId(trip.booking_id);
                                                                        }}
                                                                            disabled={!trip.cancellationDetails.cancellable} className='allViewCancel-btn'>Cancel</Button>
                                                                    )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </Card.Body>
                            </Card>
                        </>
                    )}

                </TabPanel>
                <TabPanel>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-96">
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <Card className='whiteBG mb-4' style={{ border: 'none', overflow: 'hidden', borderRadius: '24px', }}>
                                <Card.Body className='p-0'>
                                    <Table responsive className='m-0 tableContent'>
                                        <thead>
                                            <tr>
                                                <th>Booking</th>
                                                <th>Date</th>
                                                <th>Pickup / Dropoff </th>
                                                <th>Passanger</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(trips) &&
                                                trips?.map((trip, index) => (
                                                    <>
                                                        <tr key={`triprer-row-${trip?.booking_id}-${index}`} className="spaceUnder">
                                                            <td>#{trip?.booking_id}</td>
                                                            <td>{trip?.booked_on}</td>
                                                            <td>
                                                                <div className="d-flex mb-3">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={aVector} alt='icon' /></div>
                                                                    <div>{trip?.pickup}</div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={bVector} alt='icon' /></div>
                                                                    <div>{trip?.destination}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <p className="d-flex mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" src={userIcon4} alt='icon' /> <strong>{trip?.passenger_name}</strong></p>
                                                                    <p className="d-flex  mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={PhoneIcon} alt='icon' /> <strong>{trip?.passenger_mobile}</strong></p>
                                                                    <p className="d-flex  mb-3" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={EmailIcon} alt='icon' /> <strong>{trip?.passenger_email}</strong></p>
                                                                    <div className="d-flex" style={{ gap: '10px' }}>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={userIcon4} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_passengers}</strong></div></p>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={breifIcon} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_bags}</strong></div></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><p className='m-0 fw-bold darkgreenText' style={{ fontSize: '24px' }}>{trip?.fare_human}</p></td>
                                                            {userType === "driver" ? null : (

                                                                <div className="d-none justify-content-center groupingbtn" style={{ gap: '12px' }}>

                                                                    <Button style={{ height: '40px', fontSize: '14px', maxWidth: '84px', minWidth: '84px' }} onClick={() => { bookAgain(trip); }} className='allView-btn'>Rebook</Button>


                                                                </div>
                                                            )}
                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </TabPanel>
                <TabPanel>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-96">
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <Card className='whiteBG mb-4' style={{ border: 'none', overflow: 'hidden', borderRadius: '24px', }}>
                                <Card.Body className='p-0'>
                                    <Table responsive className='m-0 tableContent'>
                                        <thead>
                                            <tr>
                                                <th>Booking</th>
                                                <th>Date</th>
                                                <th>Pickup / Drop-off</th>
                                                <th>Passanger</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(trips) &&
                                                trips?.map((trip, index) => (
                                                    <>
                                                        <tr key={`tripss-row-${trip?.booking_id}-${index}`}>
                                                            <td>#{trip?.booking_id}</td>
                                                            <td>{trip?.booked_on}</td>
                                                            <td>
                                                                <div className="d-flex mb-3">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={aVector} alt='icon' /></div>
                                                                    <div>{trip?.pickup}</div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="me-2"><img className="img-fluid w-100" style={{ maxWidth: '30px', minWidth: '30px' }} src={bVector} alt='icon' /></div>
                                                                    <div>{trip?.destination}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <p className="d-flex mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" src={userIcon4} alt='icon' /> <strong>{trip?.passenger_name}</strong></p>
                                                                    <p className="d-flex  mb-1" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={PhoneIcon} alt='icon' /> <strong>{trip?.passenger_mobile}</strong></p>
                                                                    <p className="d-flex  mb-3" style={{ gap: '10px', fontSize: '14px', borderRadius: '100px', backgroundColor: '#f1f1f1', padding: '5px 10px', maxWidth: '250px', minWidth: '250px' }}><img className="img-fluid" style={{ width: '20px' }} src={EmailIcon} alt='icon' /> <strong>{trip?.passenger_email}</strong></p>
                                                                    <div className="d-flex" style={{ gap: '10px' }}>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={userIcon4} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_passengers}</strong></div></p>
                                                                        <p className='position-relative m-0'><div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '40px', minWidth: '40px', maxHeight: '40px', minHeight: '40px', borderRadius: '100px', backgroundColor: '#f1f1f1' }}><img className="img-fluid" src={breifIcon} alt='icon' /></div> <div className="position-absolute end-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ maxWidth: '20px', minWidth: '20px', maxHeight: '20px', minHeight: '20px', backgroundColor: '#FAE069', borderRadius: '100px', top: '-10px' }}><strong>{trip?.num_bags}</strong></div></p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><p className='m-0 fw-bold darkgreenText' style={{ fontSize: '24px' }}>{trip?.fare_human}</p></td>
                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </TabPanel>
            </Tabs>
            <Modal show={isConfirmModalOpened} onHide={closeModal} centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body className="p-0">
                    <p>Are you sure you want to cancel this booking?</p>
                    <div className='d-flex align-items-center justify-content-end'>
                        <Button onClick={() => {
                            setIsCanceling(true);
                            cancelBooking(tripbooking_id).finally(() => {
                                setIsCanceling(false);
                            });
                        }}
                            disabled={isCanceling} className={'allView-btn'}>{isCanceling ? <BouncingLoader /> : 'Confirm'} </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={modifymodalIsOpen} onHide={closeModalDetail} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px'}} id="contained-modal-title-vcenter">
                        Modify Detail Of Booking No. {tripbooking_id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 mt-3 text-center">
                <Card.Body className=''>
                  <Form.Control type='text' className='mb-3' value={[passenger]} onChange={handleInputChange}   placeholder='Passanger Name'></Form.Control>
                  <Form.Control type='text' className='mb-3' value={[mobile]} onChange={handleInputMobileChange} placeholder='Mobile No'></Form.Control>
                  <Form.Control type='text' className='mb-3' value={[flightNumberinput]} onChange={handleInputflightNumberChange} placeholder='Flight Number'></Form.Control>
                  <Form.Control type='text' className='mb-3' value={[specialInstructions]} onChange={handleInputspecialInsChange} placeholder='Special Instructions'></Form.Control>
                  <Form.Control type='text' className='mb-3' value={[pickupSign]} onChange={handleInputPickupsignChange} placeholder='Pickup Sign'></Form.Control>
                  <Button variant={''} onClick={UpdateDetials} disabled={loading} type="button" className="loginbtn w-100">
                    {loading ? <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div> : 'Submit'}
                  </Button>
                </Card.Body>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Trips;