import React, { useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import icon1 from '../images/icon1.svg';
import icon2 from '../images/icon2.svg';
import icon3 from '../images/icon3.svg';


const HowItWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <main>
        <section className="banner d-flex align-items-center justify-content-center image_01" style={{height: 'calc(100vh - 50vh)' }}>
          <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
            <h1>How It <span className=''>Work</span></h1>
          </Col>
        </section>
        {/*  */}
        <section className='howItWork position-relative' style={{ paddingBottom: '60px', paddingTop: '60px' }}>
          <Col xxl={'9'} xl={'10'} className='col-11 mx-auto bgImage1'>
           
            <Row className=''>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon1} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>01</span>
                  <h5 className='secondaryText mb-2 position-relative' style={{ fontWeight: '600', fontSize: '18px', }}>SELECT INFORMATION</h5>
                </div>
                <p className='mb-0'>Enter your pickup location and time and select your car.</p>
              </Col>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon2} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>02</span>
                  <h5 className='secondaryText mb-2' style={{ fontWeight: '600', fontSize: '18px', }}>PROCEED TO PAYMENT</h5>
                </div>
                <p className='mb-0'>Check the booking details and select your preferred payment method.</p>
              </Col>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon3} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>03</span>
                  <h5 className='secondaryText mb-2' style={{ fontWeight: '600', fontSize: '18px', }}>ENJOY THE RIDE</h5>
                </div>
                <p className='mb-0'>Our Limowide driver will pick you up at the specified place and time.</p>
              </Col>
            </Row>
            <ol class="mt-4">
              <li class="mb-3"><strong>Sign Up:</strong> Join our Affiliate & Webmasters Program by filling out a simple application form on our website. Once approved, you’ll gain access to our marketing materials and affiliate dashboard.</li>
              <li class="mb-3"><strong>Promote Limowide:</strong>  Utilize your website, blog, social media, or any other platform to promote our services using the provided links and promotional content.</li>
              <li class="mb-3"><strong>Earn Commissions:</strong>  Whenever a customer books a service through your referral link, you earn a commission. It’s that simple!</li>
              <li class="mb-3"><strong>Get Paid:</strong>  Enjoy timely payments through various available methods. Your earnings are just a few clicks away.</li>
            </ol>
          </Col>
        </section>
      </main>
    </>
  );
};

export default HowItWork;
