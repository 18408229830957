import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import visa from "../images/visa-svgrepo-com.svg";
import threed from "../images/3d-secure-svgrepo-com.svg";
import master from "../images/mastercard-svgrepo-com.svg";
import logo from "../images/logo.png";
import SocialLinks from './SocialLinks';

const Footer = () => {
  const [activeTab, setActiveTab] = useState('UK');

  const renderContent = () => {
    switch (activeTab) {
      case 'UK':
        return (
          <ul className="list-unstyled">
            <li className='whiteText'>Address: 1 Peach Place,<br />
              Wokingham, RG40 1LY, UK</li>
            <li><Link to={'tel:+447458148595'} className=''>Phone: +44 7458 148595</Link></li>
            <li><Link to={'mailto:support@limowide.com'} className=''>Email: support@limowide.com</Link></li>
           </ul>
        );
      case 'India':
        return (
          <ul className="list-unstyled">
            <li className='whiteText'>Address: 86, 7th St,<br /> Azhagammal Nagar,<br />
            Nerkundram Chennai - 600107 <br /> Tamil Nadu, India</li>
            <li><Link to={'tel:+919884273784'} className=''>Phone: +91 98842 73784</Link></li>
            <li><Link to={'mailto:support@limowide.com'} className=''>Email: support@limowide.com</Link></li>
          </ul>
        );
      case 'England':
        return (
          <ul className="list-unstyled">
            <li className='whiteText'>Address: Sharjah Media City,<br />
            Sharjah, UAE</li>
            <li><Link to={'tel:+15854451333'} className=''>Phone: +1 (585) 445-1333</Link></li>
            <li><Link to={'mailto:support@limowide.com'} className=''>Email: support@limowide.com</Link></li>
          </ul>
        );
      default:
        return null;
    }
  };
  return (
    <footer className="pt-5 pb-4 footer">
      <Col xxl={'10'} xl={'10'} lg={'10'} className="col-11 mx-auto">
        <div className='mb-5 d-md-flex align-items-center justify-content-between text-center'>
          <Link to={'/'} className='mb-4 mb-md-0 d-inline-block'><img className='img-fluid' style={{ width: '120px', }} src={logo} alt='logo' /></Link>
          <div className='d-flex align-items-center justify-content-center' style={{ gap: '25px' }}>
            <SocialLinks />
          </div>
        </div>
        {/*  */}
        <Row className="">
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Company</h5>
            <ul className="list-unstyled">
              <li><Link to={'/how-it-works'} className="">How it works</Link></li>
              {/* <li><Link to={'/newsletter'} className="">Newsletter</Link></li> */}
              {/* <li><Link to={'/careers'} className="">Careers</Link></li> */}
              {/* <li><Link to={'/investors'} className="">Investors</Link></li> */}
              <li><Link to={'/blogs'} className="">Blogs</Link></li>
              <li><Link to={'/destinations'} className="">Destinations</Link></li>
            </ul>
          </Col>
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Services</h5>
            <ul className="list-unstyled">
              <li><Link to={'/airport-transfer'} className="">Airport Transfer</Link></li>
              <li><Link to={'/limousine-service'} className="">Limousine Service</Link></li>
              <li><Link to={'/private-taxi'} className="">Private Taxi</Link></li>
              <li><Link to={'/chauffeur-service'} className="">Chauffeur Service</Link></li>
              <li><Link to={'/city-tour'} className="">City Tour</Link></li>
            </ul>
          </Col>
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Contact</h5>
            <div>
              <div className="tabs">
                <button onClick={() => setActiveTab('UK')} className={`btn-tab-footer ${activeTab === 'UK' ? 'active' : ''}`}>ENGLAND</button>
                <button onClick={() => setActiveTab('India')} className={`btn-tab-footer ${activeTab === 'India' ? 'active' : ''}`}>INDIA</button>
                <button onClick={() => setActiveTab('England')} className={`btn-tab-footer ${activeTab === 'England' ? 'active' : ''}`}>UAE</button>
              </div>
              <div className="tab-content">
                {renderContent()}
              </div>
            </div>
          </Col>
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Partnership</h5>
            <ul className="list-unstyled">
              <li><Link to={'/business-solutions'} className="">Business Solutions</Link></li>
              <li><Link to={'/travel-agencies'} className="">Travel Agencies</Link></li>
              <li><Link to={'/affiliate-webmasters'} className="">Affiliate & Webmasters</Link></li>
              {/* <li><Link to={'/drive-with-us'} className="">Drive with Us</Link></li> */}
              <li><Link to={'/become-a-partner'} className="">Become A Partner</Link></li>
              {/* <li><Link to={'/register-guide'} className="">Register & Guide</Link></li> */}
            </ul>
          </Col>
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Legal</h5>
            <ul className="list-unstyled">
              <li><Link to={'/privacy-policy'} className="">Privacy Policy</Link></li>
              <li><Link to={'/terms-conditions'} className="">Terms & Conditions</Link></li>
              <li><Link to={'/cookie-policy'} className="">Cookie Policy</Link></li>
            </ul>
          </Col>
          <Col xl={'2'} lg={'4'} md={'4'} sm={'4'} className="mb-4 mb-xl-0 px-4 px-sm-0">
            <h5 className="title">Help</h5>
            <ul className="list-unstyled">
              <li><Link to={'/help-center'} className="">Help Center</Link></li>
              <li><Link to={'/get-support'} className="">Get Support</Link></li>
            </ul>
          </Col>
        </Row>
        <hr className='color1Text' />
        <div className="d-md-flex justify-content-between align-items-center text-center">
          <p className="mb-3 mb-md-0 whiteText" style={{ fontSize: '14px', }}>&copy; 2024 Limowide. All rights reserved</p>
          <div className='d-flex align-items-center justify-content-center'>
            <img className='img-fluid' width={'50px'} src={threed} alt='cards' />
            <img className='img-fluid' width={'65px'} src={visa} alt='cards' />
            <img className='img-fluid' width={'65px'} src={master} alt='cards' />
          </div>
        </div>
      </Col>
    </footer>
  );
};

export default Footer;
