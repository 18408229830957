import React,{useEffect} from 'react';
import { Breadcrumb,Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const TourDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className='py-4 breadcrumb_wrap'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto' style={{position:'relative', zIndex:'1'}}>
          <h4 className='FontFamilyFigtree text-center whiteText' style={{fontSize:'28px', fontWeight:'600'}}>Tour Detail</h4>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Tour Detail</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>
      {/*  */}
      <section className='py-4'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>content</Col>
      </section>
    </>
  );
};

export default TourDetail;

