import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';

// Define the map container style and initial zoom
const mapContainerStyle = {
  height: '300px',
  width: '100%',
  borderRadius:'45px'
};

const Map = ({ defaultLocation, destination, origin }) => {
  // Load the Google Maps JavaScript API and other libraries
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCQ5KzDHt2llFGTexTHNMAdwfJ24XsVzK4', // Replace with your API key
    libraries: ['places'], // Include additional libraries if needed
  });

  // Handle the loading state and errors
  // if (loadError) return <div>Error loading maps</div>;
  // if (!isLoaded) return <div>Loading maps...</div>;

  const [directions, setDirections] = useState(null);

  // Create directions service instance using useCallback
  // const directionsService = useCallback(() => new window.google.maps.DirectionsService(), []);

  // Calculate the route
  const calculateRoute = () => {
    const service = new window.google.maps.DirectionsService();
    service.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  // Calculate the route when the map is loaded or when origin/destination changes
  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded, origin, destination]);

  return (
    <div>
      <GoogleMap
        center={defaultLocation}
        zoom={5}
        mapContainerStyle={mapContainerStyle}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
};

export default Map;