import React, { useState, useRef } from 'react';

import { Col, Card, Form, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { isAuthenticated } from '../utils/auth';
import { Link, useLocation } from 'react-router-dom';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import companyLogo from '../images/logo.png';
import whiteedit from '../images/white-edit.svg';
import userIcon5 from '../images/userIcon5.svg';
import { getStoredUser } from '../utils/localStorageUtils';
import DriverFooter from '../components/DriverFooter';
const DriverProfile = (props) => {
  const [newLogo, setNewLogo] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const storedUsers = getStoredUser();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  // Image Working
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setNewLogo(reader.result);
    }
  };
  return (
    <>
      <div style={{ backgroundColor: '#F3F3F3' }} className='pb-4 position-relative'>
        <section className='myJob secondaryBG position-relative'>
          <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
            <h4 className="mainText fw-bold" style={{ fontSize: '22px', }}>My Profile</h4>
          </Col>
        </section>
        <section className='myJob py-0' style={{ marginBottom: '78px' }}>
          <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
            <Form className='driver-profile-form'>
              <Card className='whiteBG' style={{ borderColor: '#fff', borderRadius: '30px', boxShadow: '0px 0px 5px 0px #DDDDDD', }}>
                <Card.Body className='p-4'>
                  <div className='mb-3'>
                    <Form.Group controlId="formFile" className="mb-3" style={{ display: 'none' }}>
                      <Form.Control type="file" ref={fileInputRef} onChange={handleImageChange} />
                    </Form.Group>
                    <div className='position-relative w-100 mx-auto' style={{ maxWidth: '100px', minWidth:'100px' }}>
                      <div className='userprofile1 d-flex align-items-center justify-content-center' style={{ margin: '0 auto', border: '4px solid #162238' }}>
                        {preview ? <img src={preview} alt="logoimage" style={{ height: "100px" }} className="img-fluid" /> : <img className='img-fluid' style={{ height: "100px" }} src={userIcon5} alt='logoimage ' />}
                      </div>
                      <div className='position-absolute' style={{ right: '0px', bottom: '0px' }}>
                          <Button variant={''} className={'w-100 h-100 d-flex align-items-center justify-content-center'} style={{ maxWidth: '30px', minWidth: '30px', minHeight: '30px', maxHeight: '30px', backgroundColor: '#162238', borderRadius: '100px', border: '2px solid #FAE069' }} onClick={triggerFileInput}><img src={whiteedit} width={'20px'} alt="" /></Button>
                        </div>
                    </div>

                  </div>
                  <Form.Control type='text' className='mb-3' placeholder='Name'></Form.Control>
                  <Form.Control type='text' className='mb-3' placeholder='Mobile No'></Form.Control>
                  <Form.Control type='email' className='mb-3' placeholder='Email'></Form.Control>
                  <Form.Select className='mb-3'>
                    <option value='' selected>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </Form.Select>
                  <Form.Control type='email' className='mb-3' placeholder='Licence No'></Form.Control>
                  <Form.Control type='file' className='mb-3' placeholder='Upload Licence Image'></Form.Control>
                  <Form.Select className='mb-3'>
                    <option value='' selected>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </Form.Select>
                  <Button variant={''} onClick='' disabled={loading} type="button" className="loginbtn w-100">
                    {loading ? <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div> : 'Submit'}
                  </Button>
                </Card.Body>
              </Card>
            </Form>
          </Col>
        </section>
        <DriverFooter />
      </div>
    </>
  );
};

export default DriverProfile;
