import React from "react";
import animation from "../images/animation_lmteny3f.json";
import Lottie from "lottie-react";

function Loading() {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Lottie
        className="loader-gif"
        animationData={animation}
        loop={true}
      />
    </div>
  );
}

export default Loading;
