import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Button, Form, Row, InputGroup, Card } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import emailjs from "@emailjs/browser";
// 
import image01 from '../images/image01.jpg';
import image02 from '../images/image02.jpg';
import image03 from '../images/image03.jpg';
import image04 from '../images/image04.jpg';
import image05 from '../images/image05.jpg';
import image06 from '../images/image06.png';
import companyicon from '../images/companyicon.svg';
import mailinboxicon from '../images/mailinboxicon.svg';
import userIcon3 from '../images/userIcon3.svg';
import locationicon from '../images/locationicon.svg';
import webicon from '../images/webicon.svg';

// import { Link } from 'react-router-dom';
// Validation schema
const schema = yup.object().shape({
  companyName: yup.string().required('Company Name is required'),
  userName: yup.string().required('Full Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  mobile: yup.string().required('Mobile is required'),
  city: yup.string().required('Operating City is required'),
  website: yup.string().url('Invalid URL').required('Website is required'),
  businessType: yup.array().of(yup.string()).min(1, 'Select at least one business type')
});

const BecomeaPartner = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: '',
      userName: '',
      email: '',
      mobile: '',
      city: '',
      website: '',
      businessType: []
    }
  });

  const onSubmit = async (data) => {
    console.log(data)
    try {
      emailjs
      .send("service_e66i1jl", "template_6u5vhz9", data, "N7Gll-SNSDmw3kFkG")
      .then(
        (result) => {
          // console.log(result.text);
          alert("Our Team will reach out to you, Thanks!");
          navigate("/");
        },
        (error) => {
          console.log(error.text);
        }
      );
    } catch (error) {
      // Handle error response
      console.error(error);
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const currentValues = getValues('businessType');
    const updatedValues = checked
      ? [...currentValues, id]
      : currentValues.filter((type) => type !== id);
    setValue('businessType', updatedValues);
  };

  return (
    <>
      <Helmet>
        <title>Become a Partner with Limowide</title>
        <link rel="canonical" href="https://limowide.com/become-a-partner" /> 
        <meta name="title" content="Become a partner with limowide" />
        <meta name="description" content="Discover the potential of partnership with Limowide Airport Transfer & Private Taxi." />
      </Helmet>
      <section className='py-4 color1BG'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto' style={{ position: 'relative', zIndex: '1' }}>
          <Row>
            <Col lg={'8'} className='order-1'>
              <img style={{ borderRadius: '24px' }} className={'img-fluid mb-5'} src={image06} alt='icon' />
              <h5 className='FontFamilyProstoOne secondaryText allTile'>Become a Partner With Limowide</h5>
              {/*  */}
              <Row className='align-items-center gx-0 mb-5'>
                <Col xxl={'6'} xl={'6'} className='text-center mb-3 mb-xl-0'><img style={{ borderRadius: '16px' }} className={'img-fluid'} src={image02} alt='icon' /></Col>
                <Col xxl={'6'} xl={'6'}>
                  <h5 className='mb-2 fw-semibold text-uppercase darkgreenText'>Fleets</h5>
                  <p className='mb-0' style={{textAlign:'justify'}}>Join our fleet business program and elevate your revenue opportunities with our premium airport transfer service. As a partner, you'll benefit from our extensive network, cutting-edge technology, and dedicated support team. Whether you operate a taxi company, shuttle service, or rental car business, partnering with us opens doors to new clientele and increased profits. Experience seamless integration and grow your business with our trusted airport transfer solutions.</p>
                </Col>
              </Row>
              {/*  */}
              <Row className='align-items-center gx-0 mb-5' >
                <Col xl={'6'} className='text-center mb-3 mb-xl-0 order-1'>
                  <div className='text-lg-end text-start'>
                    <h5 className='mb-2 fw-semibold text-uppercase darkgreenText'>Tour Operator</h5>
                    <p className='mb-0' style={{textAlign:'justify'}}>Join our fleet business program and elevate your revenue opportunities with our premium airport transfer service. As a partner, you'll benefit from our extensive network, cutting-edge technology, and dedicated support team. Whether you operate a taxi company, shuttle service, or rental car business, partnering with us opens doors to new clientele and increased profits. Experience seamless integration and grow your business with our trusted airport transfer solutions.</p>
                  </div>
                </Col>
                <Col xl={'6'} className='text-center order-0 order-xl-1'><img style={{ borderRadius: '16px' }} className={'img-fluid'} src={image01} alt='icon' /></Col>
              </Row>
              {/*  */}
              <Row className='align-items-center gx-0 mb-5' >
                <Col xl={'6'} className='text-center mb-43 mb-xl-0' ><img style={{ borderRadius: '16px' }} className={'img-fluid'} src={image03} alt='icon' /></Col>
                <Col xl={'6'}>
                  <h5 className='mb-2 fw-semibold text-uppercase mb-3 darkgreenText'>Trevel Agency</h5>
                  <p className='mb-0' style={{textAlign:'justify'}}>Expand your travel agency's offerings by partnering with our premier airport transfer service. You can enhance your customers' travel experience, ensuring seamless transitions from airport to accommodation. Benefit from our competitive rates, drivers, and diverse fleet of vehicles to provide your clients with the convenience they deserve. Join forces with us today and elevate your agency's reputation as a trusted provider.</p>
                </Col>
              </Row>
              {/*  */}
              <Row className='align-items-center gx-0 mb-5' >
                <Col xl={'6'} className='text-center mb-3 mb-lg-0 order-1'>
                  <div className='text-lg-end text-start'>
                    <h5 className='mb-2 fw-semibold text-uppercase darkgreenText'>Corporate</h5>
                    <p className='mb-0' style={{textAlign:'justify'}}>Join forces with our airport transfer service and elevate your corporate travel experience. As a partner, your organization will enjoy seamless, reliable transportation solutions tailored to your needs. With our extensive fleet of vehicles and dedicated team, we ensure punctuality, professionalism, and comfort for every journey. Partner with us to streamline your corporate travel arrangements and leave a lasting impression on your clients and employees.</p>
                  </div>
                </Col>
                <Col xl={'6'} className='text-center order-0 order-xl-1'><img style={{ borderRadius: '16px' }} className={'img-fluid'} src={image04} alt='icon' /></Col>
              </Row>
              {/*  */}
              <Row className='align-items-center gx-0' >
                <Col xl={'6'} className='text-center mb-3 mb-lg-0'><img style={{ borderRadius: '16px' }} className={'img-fluid'} src={image05} alt='icon' /></Col>
                <Col xl={'6'}>
                  <h5 className='fw-semibold text-uppercase mb-2 darkgreenText'>Events Company</h5>
                  <p className='mb-0' style={{textAlign:'justify'}}>As an events company, partnering with an airport transfer service adds value and convenience for our clients. By collaborating with a reliable transfer service, we ensure seamless transportation solutions for attendees, enhancing the overall event experience. With their expertise in logistics and our dedication to creating memorable events, our partnership promises efficient and stress-free travel arrangements, leaving a lasting impression on our clients.</p>
                </Col>
              </Row>
            </Col>
            <Col lg={'4'} className='order-lg-1 order-0'>
              <Form className='accountTabs' onSubmit={handleSubmit(onSubmit)}>
                <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px' }}>
                  <Card.Body className='p-lg-5'>
                    <div className='text-center py-3 mb-4'>
                      <h6 className='fw-semibold mb-3' style={{ fontWeight: '700', fontSize: '28px' }}>Enroll With Us</h6>
                      <p className='mb-0'>Unlock exclusive benefits by registering with us today - join our community and start your journey!</p>
                    </div>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={companyicon} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="companyName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='text'
                            placeholder="Company Name"
                            className={`mb-0 ps-0 ${errors.companyName ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.companyName && <div className="invalid-feedback">{errors.companyName.message}</div>}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={userIcon3} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="userName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='text'
                            placeholder="Full Name"
                            className={`mb-0 ps-0 ${errors.userName ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.userName && <div className="invalid-feedback">{errors.userName.message}</div>}
                      </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={userIcon3} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="mobile"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='text'
                            placeholder="Full Name"
                            className={`mb-0 ps-0 ${errors.mobile ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.mobile && <div className="invalid-feedback">{errors.mobile.message}</div>}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={mailinboxicon} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='email'
                            placeholder="Email Address"
                            className={`mb-0 ps-0 ${errors.email ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={locationicon} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='text'
                            placeholder="Operating City"
                            className={`mb-0 ps-0 ${errors.city ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.city && <div className="invalid-feedback">{errors.city.message}</div>}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1">
                        <img className='img-fluid' src={webicon} alt='icon' />
                      </InputGroup.Text>
                      <Controller
                        name="website"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type='text'
                            placeholder="Website"
                            className={`mb-0 ps-0 ${errors.website ? 'is-invalid' : ''}`}
                          />
                        )}
                      />
                      {errors.website && <div className="invalid-feedback">{errors.website.message}</div>}
                    </InputGroup>
                    <Form.Label className='fw-semibold'>Select your business type:</Form.Label>
                    <Row className='align-items-center' style={{ gap: '15px' }}>
                      <Col xxl='3' xl='3'>
                        <Form.Check
                          type="checkbox"
                          id="fleets"
                          className='fw-semibold'
                          label="Fleets"
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                      <Col xxl='3' xl='3'>
                        <Form.Check
                          type="checkbox"
                          id="Corporate"
                          className='fw-semibold'
                          label="Corporate"
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                      <Col xxl='3' xl='3'>
                        <Form.Check
                          type="checkbox"
                          id="tour-operator"
                          className='fw-semibold'
                          label="Tour Operator"
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                      <Col xxl='3' xl='3'>
                        <Form.Check
                          type="checkbox"
                          id="travel-agency"
                          className='fw-semibold'
                          label="Travel Agency"
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                      <Col xxl='4' xl='4'>
                        <Form.Check
                          type="checkbox"
                          id="events-company"
                          className='fw-semibold'
                          label="Events Company"
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                    </Row>
                    {errors.businessType && <div className="invalid-feedback d-block">{errors.businessType.message}</div>}
                    <Button type='submit' variant='' className='mt-4 mw-100 allView-btn' style={{ borderRadius: '100px', minWidth: '100%', height: '50px' }}>
                      Enroll Now
                    </Button>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Col>
      </section>
    </>
  );
};

export default BecomeaPartner;
