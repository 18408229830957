const config = {
    configUrl: 'https://service.limowide.com/api/v1/configs/',
    forgotPassword: 'https://service.limowide.com/api/v1/forgot-password/',
    ResetPassword: 'https://service.limowide.com/api/v1/reset-password/',
    apiLogin: 'https://service.limowide.com/api/v1/login/',
    apiGuestLogin: 'https://service.limowide.com/api/v1/guestlogin/',
    apiSocialLogin: 'https://service.limowide.com/api/v1/socail/login/',
    apiRegister: 'https://service.limowide.com/api/v1/sign-up/',
    apiSearch: 'https://service.limowide.com/api/v1/booking/search/',
    userProfile: 'https://service.limowide.com/api/v1/user/detail/',
    updateProfile: 'https://service.limowide.com/api/v1/user/update-detail/',
    changePassword: 'https://service.limowide.com/api/v1/change-password/',
    BookingConfirm: 'https://service.limowide.com/api/v1/booking/confirm/',
    paymentInitiate: 'https://service.limowide.com/api/v1/payment/initiate/',
    paymentCharge: 'https://service.limowide.com/api/v1/payment/charge/',
    paymentChargeSaveCard: 'https://service.limowide.com/api/v1/payment/charge/savedcard/', 
    paymentCardRemove: 'https://service.limowide.com/api/v1/payment/card/remove/',
    paymentCapture: 'https://service.limowide.com/api/v1/payment/capture/',
    Blogs: 'https://service.limowide.com/api/v1/blogs/',
    Cities: 'https://service.limowide.com/api/v1/seo/city/cities-list/',
    CitiesFilter: (continent,limit) => `https://service.limowide.com/api/v1/seo/city/cities-filter-list/${continent}/${limit}/`,
    BlogLatest: 'https://service.limowide.com/api/v1/blogs/latest/',
    getBookingDetailUrl: (id) => `https://service.limowide.com/api/v1/booking/${id}/`,
    getBlogDetailUrl: (slug) => `https://service.limowide.com/api/v1/blogs/${slug}/`,
    getCitySeoUrl: (slug) => `https://service.limowide.com/api/v1/seo/city/${slug}/`,
    CitySeoLimousineService: 'https://service.limowide.com/api/v1/seo/city/limousine-service/',
    CitySeoChauffeurService: 'https://service.limowide.com/api/v1/seo/city/chauffeur-service/',
    CitySeoParivatTaxi: 'https://service.limowide.com/api/v1/seo/city/private-taxi/',
    CitySeoAirportTransfer: 'https://service.limowide.com/api/v1/seo/city/airport-transfer/',
    CitySeoCityTour: 'https://service.limowide.com/api/v1/seo/city/best-city-tour-operator/',
    CancelBooking: 'https://service.limowide.com/api/v1/booking/cancel/',
    TripsData: 'https://service.limowide.com/api/v1/booking/user/trips/'
};
export default config;