import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import pin from "../images/mappin.png";
const Pointer = ({ text, color }) => (
  <div className="w-12">
    <img src={pin} alt="mappin" width={'30px'} />
    <p className="text-white">{text}</p>
  </div>
);

const Polyline = (props) => {
  const { markers, map, maps } = props;

  let geodesicPolyline = new maps.Polyline({
    path: markers,
    geodesic: true,
    strokeColor: "#00a1e1",
    strokeOpacity: 1.0,
    strokeWeight: 4,
  });
  geodesicPolyline.setMap(map);
  return <></>;
};
const defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 11,
};
const Map = (props) => {
  const { pickupGeometry, dropoffGeometry } = props;
  const [locationFrom, setLocationFrom] = useState({
    lat: null,
    lng: null,
  });
  const [locationTo, setLocationTo] = useState({
    lat: null,
    lng: null,
  });
  const [pathCoordinates, setPathCoordinates] = useState([
    {
      lat: null,
      lng: null,
    },
    {
      lat: null,
      lng: null,
    },
  ]);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  useEffect(() => {
    if (pickupGeometry && dropoffGeometry) {
      setLocationFrom(pickupGeometry);
      setLocationTo(dropoffGeometry);
      setPathCoordinates([pickupGeometry, dropoffGeometry]);
    } else {
      const position = { lat: null, lng: null };
      setLocationFrom(position);
      setLocationTo(position);
    }
  }, [pickupGeometry, dropoffGeometry]);
  const onMapLoaded = (map, maps) => {
    fitBounds(map, maps);
    setMap(map);
    setMaps(maps);
    setMapsLoaded(true);
  };
  const fitBounds = (map, maps) => {
    var bounds = new maps.LatLngBounds();
    for (let marker of pathCoordinates) {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    }
    map.fitBounds(bounds);
  };

  const afterMapLoadChanges = () => {
    return (
      <div style={{ display: "none" }}>
        <Polyline map={map} maps={maps} markers={pathCoordinates} />
      </div>
    );
  };
  return (
    <div style={{ height: "200px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCQ5KzDHt2llFGTexTHNMAdwfJ24XsVzK4" }}
        center={{ lat: 8.835203, lng: 77.5046101 }}
        zoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
      >
        <Pointer
          lat={locationFrom.lat}
          lng={locationFrom.lng}
          text="PickUp"
          color="text-red-800"
        />
        <Pointer
          lat={locationTo.lat}
          lng={locationTo.lng}
          text="Drop off"
          color="text-blue-800"
        />
        {mapsLoaded ? afterMapLoadChanges() : ""}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
