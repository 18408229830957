import React from 'react';

// import 
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FeaturedPostsUL = (props) => {
    return (
        <>
            <Col xxl={'6'} xl={'6'} lg={'6'} className={''}>
                <Card className='mb-4'>
                    <Row className={'align-items-center gx-0'}>
                        <Col xxl={'5'} xl={'5'} lg={'5'} className={''}>
                            <div className='cardImg'><img className='img-fluid' src={props.cardImg} alt='icon' /></div>
                        </Col>
                        <Col xxl={'7'} xl={'7'} lg={'7'} className={''}>
                            <Card.Body>
                                <small className='d-block mb-2'>{props.topText}</small>
                                <Link to={`/blog-detail/${props.slug}`} className={'title d-block mb-3'}>{props.title}</Link>
                                <p>{props.date}</p>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}

export default FeaturedPostsUL;