import React, { useState } from 'react';
import { Card, Col, Modal, Button, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';
import userIcon from '../images/userIcon1.svg';
import breifIcon from '../images/breifIcon.svg';
import timeIcon from '../images/timeIcon.svg';
import meetgreetIcon from '../images/meetgreetIcon.svg';
const VehicleUL = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = `${location.pathname}${location.search}${location.hash}`;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const loginrouting = () => {
        localStorage.setItem('redirectAfterLogin', currentPath);
        navigate('/signin')
    };
    const {
        car: {
            fleet_image,
            car_class,
            fare,
            manufacturer,
            num_passengers,
            luggage_capacity,
            waiting_time,
            meet_greet,
            model,
            id,
        },
   
        goToPassenger,
    } = props;


    return (
        <>
            <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px' }} id={id}>
                <Card.Body className='p-md-4'>
                    <Row className={'align-items-center roline'}>
                        <Col xxl={'6'} xl={'6'} lg={'6'} md={'6'} className={'mb-3 mb-lg-5'}>
                            <p className='mb-0 text-center'><img className='img-fluid mb-3' src={fleet_image} style={{ width: '300px' }} alt='carIMG' /></p>
                            <Row className='gx-0 align-items-center justify-content-between mb-3'>
                                <Col sm={'6'} className='col-6'>
                                    <div className='d-flex align-items-center'><img className='img-fluid me-2' src={userIcon} alt='icon' /><p className='mb-0 fw-semibold'>{num_passengers + ' Passangers'}</p></div>
                                </Col>
                                <Col sm={'6'} className='col-6'>
                                    <div className='d-flex align-items-center'><img className='img-fluid me-2' src={breifIcon} alt='icon' /><p className='mb-0 fw-semibold'>{luggage_capacity + ' Suitcases'}</p></div>
                                </Col>
                                <Col sm={'6'} className='col-6'>
                                    <div className='d-flex align-items-center'><img className='img-fluid me-2' src={meetgreetIcon} alt='icon' /><p className='mb-0 fw-semibold'>{meet_greet === true ? 'Meet & Greet' : 'N/A'}</p></div>
                                </Col>
                                <Col sm={'6'} className='col-6'>
                                    <div className='d-flex align-items-center'><img className='img-fluid me-2' src={timeIcon} alt='icon' /><p className='mb-0 fw-semibold'>{waiting_time + ' min waiting time'}</p></div>
                                </Col>
                            </Row>
                          
                        </Col>
                        <Col xxl={'6'} xl={'6'} lg={'6'} md={'6'} className={'px-lg-5'}>
                            <h4 className='title'>{car_class.replace("_", " ")} - {manufacturer} {model}</h4>
                            <p className=''>or similar</p>
                            <div class="d-md-block d-flex align-items-center">
                                <div class="mb-md-4 mb-0">
                                    <h5 className='mb-0 price'>{fare}</h5>
                                    <p className='mb-0'>All Price includes taxes.</p>
                                </div>
                                {isAuthenticated() ? <Button onClick={() => goToPassenger(props.car)} className={'bookNow-btn ms-auto'}>Book Now</Button> : <Button onClick={openModal} variant={''} className={'bookNow-btn ms-auto'}>Book Now</Button>}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Modal show={modalIsOpen} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login or Guest Checkout
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 mt-3 text-center">
                    <div className='d-flex align-items-center justify-content-end' style={{ gap:'5px'}}>
                        <Button onClick={loginrouting} className={'allView-btn'}>Log In</Button>
                        <Button onClick={() => goToPassenger(props.car)} className={'border-btn'} >Guest Checkout</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default VehicleUL;
