import React, { useEffect, useState, useRef } from 'react';
import { Col, Card, Form, Button, Row, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import { handleApiError } from '../utils/errorHandler';
import { getStoredSearch } from '../utils/Search.jsx';
import { getStoredUser } from '../utils/localStorageUtils.jsx';
import config from '../config';
import Map from "../components/Map.jsx";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
// 
import Loading from '../components/Loading';
import VehicleUL from '../components/VehicleUL';

// 

import checkicon from '../images/checkicon.svg';
import whatsappIMG from '../images/whatsappIMG.svg';
import userIcon from '../images/userIcon1.svg';
import breifIcon from '../images/breifIcon.svg';
import timeIcon from '../images/timeIcon.svg';
import meetgreetIcon from '../images/meetgreetIcon.svg';
import paymentCard from '../images/paymentCard.svg';
import paymentCard1 from '../images/paymentCard1.svg';
import paymentCard2 from '../images/paymentCard2.svg';
import BouncingLoader from '../components/BouncingLoader.jsx';
import ResultSearch from '../components/ResultSearch.jsx';
const ResultsPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const storedUser = getStoredUser();
  const storedSearch = getStoredSearch();

  const [Loader, setLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locationDetails, setLocationDetails] = useState(false);
  const [cars, setCars] = useState([]);
  const [selectedcar, setSelectedCar] = useState({});
  const [tabIndex, setTabIndex] = useState(1);
  const [timezone, setTimezone] = useState(null);
  const [guestToken, setGuestToken] = useState(null);
  // Payment Tab States
  const [email, setEmail] = useState(storedUser?.email ?? null);
  const [firstName, setFirstName] = useState(storedUser?.first_name ?? null);
  const [lastName, setLastName] = useState(storedUser?.last_name ?? null);
  const [flightNumber, setFlightNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(storedUser?.phone ?? null);
  const [specialInstructions, setSpecialInstructions] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [paymentIntendId, setPaymentIntendId] = useState("");
  const [isPaymentPageReady, setPaymentPageReady] = useState(false);
  const [cardlists, setCardList] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [removingCard, setRemovingCard] = useState([]);
  const [cardName, setCardName] = useState('');
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
    is_invoice: false,
    is_credit_card: false,
  });
  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    flightNumber: '',
    specialInstructions: '',
    phoneNumber: ''
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const goToPassenger = (car) => {
    setSelectedCar(car);
    setTabIndex(2)
    localStorage.setItem("selected_car", car.id);
  };


  // Payment Tab Handling
  var bookingDetailsData = useRef({
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: phoneNumber,
    flightNumber: flightNumber,
    specialInstructions: specialInstructions,
    fare: selectedcar?.fare,
    bookingId: selectedcar?.id,
    passenger: locationDetails?.passengers,
    luggage: locationDetails?.bags,
    waitingTime: selectedcar?.waiting_time,
    car: selectedcar?.car_class,
    pickupLocation: locationDetails.pickupLocation?.label,
    dropLocation: locationDetails?.dropoffLocation?.label,
    duration: locationDetails?.duration,
    distance: locationDetails?.distance,
    pickupDate: locationDetails?.pickupDate,
    pickupTime: locationDetails?.pickupTime,
    return_booking_id: locationDetails?.return_booking_id,
    return_date_time: locationDetails?.return_date_time,
    pin: "",
    car_raw: selectedcar.car_raw,
  });
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
  };
  const validateInputs = () => {
    const newErrors = {
      email: '',
      firstName: '',
      lastName: '',
      flightNumber: '',
      phoneNumber: ''
    };
    let isValid = true;

    if (!email) {
      newErrors.email = 'Email address is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid';
      isValid = false;
    }

    if (!firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }

    if (!lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }
    if (storedSearch?.pickup_type === 'airport' || storedSearch?.dropoff_type === 'airport') {
      if (!flightNumber || flightNumber.length > 8) {
        newErrors.flightNumber = 'Flight number is required';
        isValid = false;
      }
    }
    // Phone number validation
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleGuestLoginSuccess = () => {
    if (validateInputs()) {
      setLoader(true);
      axios.post(`${config.apiGuestLogin}`, {
        name: `${firstName} ${lastName}`,
        email: email,
        phone: phoneNumber
      }, {
        headers: {
          Accept: "application/json",
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          "Content-Type": "application/json",
        }
      }).then(response => {
        if (response.data.token) {
          console.log(response.data.token.access_token);
          setGuestToken(response.data.token.access_token);
          handleSubmit();
        }
      }).catch(error => {
        toast.error("Guest login failed", error);
        setLoader(false);
      }).finally(() => {
        setLoader(false);
      });
    }
  };
  useEffect(() => {
    if (guestToken) {
      handleSubmit();
    }
  }, [guestToken]);

  useEffect(() => {
    if (storedUser?.type === "travelagent") {

      const paymentMethods = {
        is_invoice: false,
        is_credit_card: false,
      };

      axios.get(`${config.userProfile}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          "Authorization": `Bearer ${storedUser?.token.access_token}`
        }
      })
        .then((res) => {
          const data = res.data?.data;

          if (data.is_invoice) paymentMethods.is_invoice = true;
          if (data.is_credit_card) paymentMethods.is_credit_card = true;

          setAvailablePaymentMethods(paymentMethods);
        })
        .catch((err) => {
          setAvailablePaymentMethods({
            is_invoice: !!storedUser?.is_invoice,
            is_credit_card: !!storedUser?.is_credit_card,
          });
        })
        .finally(() => {

        });
    } else {
      setAvailablePaymentMethods({
        is_invoice: !!storedUser?.is_invoice,
        is_credit_card: !!storedUser?.is_credit_card,
      });
    }
  }, []);


  const handleSubmit = () => {
    if (validateInputs()) {
      setLoader(true);
      const token = storedUser?.token?.access_token || guestToken;

      // if (!token) {
      //   toast.error('auth token required.');
      //   setLoader(false);
      //   return; // Do not proceed if no token is available
      // }
      localStorage.setItem("selected_car", selectedcar.id);

      const requestData = {
        end_point: storedSearch?.dropoff,
        flight_no: flightNumber,
        passenger_email: email,
        passenger_mobile: phoneNumber,
        passenger_name: `${firstName} ${lastName}`,
        source: "WEB",
        start_point: storedSearch?.pickup,
        sys_id: selectedcar.id,
        timezone: timezone,
        special_instructions: specialInstructions,
      };

      // Conditionally add `special_instructions` only if `specialInstructions` has a value
      if (storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour')
        delete requestData.end_point
      if (!requestData.special_instructions)
        delete requestData.special_instructions;

      if (!requestData.flight_no) delete requestData.flight_no;

      axios.post(`${config.BookingConfirm}`, requestData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          "Authorization": `Bearer ${token}`
        }
      },
      ).then(bookingResponse => {
        if (bookingResponse?.data.status === 200 && bookingResponse?.data?.data?.booking_id && bookingResponse?.data?.data?.is_confirm) {
          //   bookingDetailsData.current.luggage = bookingResponse?.data?.data?.data?.traveler?.num_bags ?? bookingDetailsData.current.luggage;

          // // @ts-ignore
          // bookingDetailsData.current.cancellationDetails =
          //   bookingResponse?.data?.data?.data.cancellationDetails;

          setBookingId(bookingResponse.data.data.booking_id);
          initiatePayment(bookingResponse.data.data.booking_id);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
        .catch(error => {
          setLoader(false);
        })
        .finally(() => {
          setLoader(false); // reset loading state
        });
    }
  };

  const initiatePayment = async (booking_id) => {
    const token = storedUser?.token?.access_token || guestToken;

    if (!token) {
      toast.error('auth token required.');
      setLoader(false);
      return; // Do not proceed if no token is available
    }
    axios.post(`${config.paymentInitiate}`, {
      booking_id: booking_id
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${token}`
      }
    },
    ).then(paymentReqDetails => {
      if (paymentReqDetails?.data?.data?.id) {
        setPaymentIntendId(paymentReqDetails?.data?.data?.id);
      }
      if (paymentReqDetails?.data?.data?.card_list?.data) {
        setCardList(paymentReqDetails.data.data.card_list.data);
        setCustomerId(paymentReqDetails?.data?.data?.customer_id);
      }
      setPaymentPageReady(true);
    })
      .catch(error => {
        handleApiError(error)
      })
      .finally(() => {
        setLoader(false); // reset loading state
      });
  };
  // 
  const CardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#000000",
        "::placeholder": {
          color: "#000000",
        },
      },
    },
    hidePostalCode: true,
  };

  const confirmBooking = async (card) => {
    setLoader(true);
    const tokena = storedUser?.token?.access_token || guestToken;

    if (!tokena) {
      toast.error('auth token required.');
      setLoader(false);
      return; // Do not proceed if no token is available
    }
    const orderData = {};
    const cardElement = elements.getElement("card");
    try {
      // Step 1: Create a token using the card details
      const { token } = await stripe.createToken(cardElement);

      if (token.error) {
        throw new Error(token.error.message);
      }

      const { card: tokenCard, ...tokenObject } = token;
      orderData.stripeToken = tokenObject;
      orderData.card_id = tokenCard.id;
      orderData.booking_id = bookingId; // Ensure bookingId is defined in your component
      orderData.is_save_card = isSaveCard; // Ensure isSaveCard is defined in your component
      // Step 2: Create a PaymentMethod using the card details
      const paymentMethodRequest = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email, // Ensure email is defined in your component
        },
      });
      if (paymentMethodRequest.error) {
        toast.error(paymentMethodRequest.error.message);
      }
      const paymentMethod = paymentMethodRequest.paymentMethod;
      const configs = {
        method: "POST",
        url: `${config.paymentCharge}`,
        data: {
          booking_id: bookingId,
          intent_id: paymentIntendId,
          method_id: paymentMethod.id,
          stripeToken: orderData.stripeToken,
        }, headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          "Authorization": `Bearer ${tokena}`
        }
      };

      const paymentData = await axios(configs);

      if (paymentData.data?.data?.requiresAction) {
        const confirmResult = await stripe.confirmCardPayment(paymentData.data.data.clientSecret);

        if (confirmResult.error) {
          toast.error("Your card was not authenticated, please try again");
          setLoader(false); 
          return;
        }

        if (confirmResult.paymentIntent.status === 'succeeded') {
          bookingDetailsData.current.bookingId = confirmResult.paymentIntent.id;
          bookingDetailsData.current.pin = confirmResult.paymentIntent.client_secret;
          toast.success("Payment success!");
          navigate('/booking-confirmation', { state: bookingDetailsData.current });
        } else if (['requires_capture', 'requires_live_capture'].includes(confirmResult.paymentIntent.status)) {
          const captureConfig = {
            method: 'POST',
            url: `${config.paymentCapture}`,
            data: { paymentIntentId: paymentIntendId },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
              "Authorization": `Bearer ${tokena}`
            }
          };

          const captureResponse = await axios(captureConfig);

          if (captureResponse.error) {
            toast.error(captureResponse.data.message);
            setLoader(false);
            return;
          }

          bookingDetailsData.current.bookingId = captureResponse.data.data.booking_id;
          bookingDetailsData.current.pin = captureResponse.data.data.bookings.pin;
          toast.success(captureResponse.data.message || 'Payment success!');
          navigate('/booking-confirmation', { state: captureResponse.data.data });
        }
      } else if (paymentData.data.status === 200) {
        bookingDetailsData.current.bookingId = paymentData.data.data.booking_id;
        bookingDetailsData.current.pin = paymentData.data.data.bookings.pin;
        toast.success(paymentData.data.message || 'Payment success!');
        navigate('/booking-confirmation', { state: paymentData.data.data });
      } else if (paymentData.error) {
        toast.error(paymentData.error);
        setLoader(false);
      } else {
        toast.error('Something went wrong!');
        setLoader(false);
      }
    } catch (err) {
      toast.error(err?.response || 'Something went wrong!');
      setLoader(false);
    }
  };
  const confirmBookingSavedCard = (cardId) => {
    const token = storedUser?.token?.access_token || guestToken;

    if (!token) {
      toast.error('auth token required.');
      setLoader(false);
      return; // Do not proceed if no token is available
    }
    setCardList((cards) =>
      cards.map((card) => {
        let isProcessing = false;
        if (card.id === cardId) isProcessing = true;
        card.isProcessing = isProcessing;
        return card;
      })
    );
    const orderData = {
      booking_id: bookingId,
      card_id: cardId,
    };
    const configs = {
      method: "POST",
      url: `${config.paymentChargeSaveCard}`,
      data: {
        ...orderData,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${token}`
      }
    };

    axios(configs)
      .then((paymentData) => {
        if (paymentData?.data?.status === 200) {
          toast.success(paymentData?.data?.message || "Payment success!");
          navigate("/booking-confirmation", { state: bookingDetailsData.current });
        } else {
          toast.error("Something went wrong!");
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setLoader(false);
      });
  };
  const removeCard = async (cardId) => {
    const token = storedUser?.token?.access_token || guestToken;

    if (!token) {
      toast.error('auth token required.');
      setLoader(false);
      return; // Do not proceed if no token is available
    }
    setRemovingCard((cards) => [...cards, cardId]);
    let newCardList = cardlists.filter((card) => card.id !== cardId);
    setCardList(newCardList);
    const configs = {
      method: "POST",
      url: `${config.paymentCardRemove}`,
      data: {
        booking_id: bookingId,
        card_id: cardId,
        is_save_card: true,
        stripeToken: 'pk_test_51JxEoxEtkeGM0TzZpoHaxbES9rBcObZqQrhnm9DQtQS6NpXkXGDypPaH6TzCMKwrcT2R5QNxN9MDvKVPbYZwXPaJ00zJifCdlA',
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${token}`
      }
    };
  };
  // 
  async function initBooking() {
    setLoading(true);
    try {
      if (firstName && selectedcar.id) {
        localStorage.setItem("selected_car", selectedcar.id);

        let requestbody = {
          source: "API",
          passenger_email: email,
          passenger_name: `${firstName} ${lastName}`,
          passenger_mobile: phoneNumber,
          sys_id: selectedcar.id,
          timezone: timezone,
          start_point: storedSearch?.pickup,
          end_point: storedSearch?.dropoff,
          special_instructions: specialInstructions,
          flight_no: flightNumber,
        };
        if (storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour')
          delete requestbody.end_point
        if (!requestbody.special_instructions)
          delete requestbody.special_instructions;

        if (!requestbody.flight_no) delete requestbody.flight_no;
        const token = storedUser?.token?.access_token || guestToken;
        const configs = {
          method: "POST",
          url: `${config.BookingConfirm}`,
          data: requestbody,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            "Authorization": `Bearer ${token}`
          }
        };

        let bookingDetailsData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: phoneNumber,
          flightNumber: flightNumber,
          specialInstructions: specialInstructions,
          fare: selectedcar?.fare,
          bookingId: selectedcar?.id,
          passenger: locationDetails?.passengers,
          luggage: locationDetails?.bags,
          waitingTime: selectedcar?.waiting_time,
          car: selectedcar?.car_class,
          pickupLocation: locationDetails.pickupLocation,
          dropLocation: locationDetails?.dropoffLocation,
          duration: locationDetails?.duration,
          distance: locationDetails?.distance,
          pickupDate: locationDetails?.pickupDate,
          pickupTime: locationDetails?.pickupTime,
          return_booking_id: locationDetails?.return_booking_id,
          return_date_time: locationDetails?.return_date_time,
          cancellationDetails: locationDetails?.cancellationDetails,
          pin: "",
          car_raw: selectedcar,
        };

        const bookingResponse = await axios(configs).catch((e) => {
          if (!!e?.response?.data?.data?.timezone) {
            toast.error("Invoice payment is not available in your region.");
          }
        });

        if (
          bookingResponse?.data.status === 200 &&
          bookingResponse?.data?.data?.booking_id &&
          bookingResponse?.data?.data?.is_confirm
        ) {
          setLoading(false);
          setLoader(false);
          bookingDetailsData.bookingId =
            bookingResponse?.data?.data?.booking_id;

          bookingDetailsData.pin = bookingResponse?.data?.data?.data?.pin ?? "";

          bookingDetailsData.luggage =
            bookingResponse?.data?.data?.data?.traveler?.num_bags ??
            bookingDetailsData.luggage;

          // @ts-ignore
          bookingDetailsData.return_booking_id =
            bookingResponse?.data?.data?.data?.return_booking_id;
          bookingDetailsData.return_date_time =
            bookingResponse?.data?.data?.data?.return_date_time;
          bookingDetailsData.destination =
            bookingResponse?.data?.data?.data?.destination;
          bookingDetailsData.pickup =
            bookingResponse?.data?.data?.data?.pickup;
          bookingDetailsData.cancellationDetails =
            bookingResponse?.data?.data?.data?.cancellationDetails;

          navigate("/booking-confirmation-invoice", { state: { ...bookingDetailsData } });
        } else {
          setLoading(false);
          setLoader(false);
        }
      } else {
      }
      setLoading(false);
      setLoader(false);
    } catch (e) {
      setLoading(false);
      setLoader(false);
    }
  }
  return (
    <>
      <section className='py-4 color1BG resultPageTopRow' style={{ position: 'relative' }}>
        <ResultSearch cars={cars} setTabIndex={setTabIndex} setCars={setCars} setLoading={setLoading} locationDetails={locationDetails} setLocationDetails={setLocationDetails} setTimezone={setTimezone} />
      </section>
      {/*  */}
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <Loading />
        </div>
      ) : (
        <>
          <section className='py-4 color1BG'>
            <Col xxl={'10'} xl={'10'} className='col-11 mx-auto mb-4'>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className={'destinations_tabs'} default={"ltr"}>
                <TabList>
                  <Tab disabled>RIDE DETAILS</Tab>
                  <Tab onClick={() => { setPaymentPageReady(false); setBookingId(null); window.location.reload(); }}>CHOOSE A VEHICLE</Tab>
                  <Tab disabled>PAYMENT</Tab>
                </TabList>
                <p className='fw-bold my-4' style={{}}>{cars?.length} results are available for you.</p>
                <TabPanel>tab not design</TabPanel>
                <TabPanel>
                  <Row className='align-items-start vehicleUl'>
                    <Col xxl={'8'} xl={'8'} className={'mb-4 mb-xl-0'}>
                      {isLoading ? (
                        <div className="flex justify-center items-center h-96">
                          <Loading />
                        </div>
                      ) : (
                        <>
                          {cars?.length ? (
                            cars.map((car, index) => {
                              return (
                                <VehicleUL
                                  car={car}
                                  key={index}
                                  index={index}
                                  goToPassenger={goToPassenger}
                                />
                              );
                            })
                          ) : (
                            <div className="flex justify-center items-center h-96">
                              <p className="text-lg font-bold ">Thank you for your interest! Unfortunately, we are fully booked at the moment. Please feel free to reach out to us via email or WhatsApp, and we will do our best to accommodate your request.</p>
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                    <Col xxl={'4'} xl={'4'} className={'mb-4 mb-xl-0'}>
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Summary</h6>

                          <small className='d-block text-uppercase'>PICKUP POINT</small>
                          <p className='fw-bold secondaryText mb-0'>{storedSearch?.pickup}</p>

                          {(storedSearch?.booking_type === 'p_to_p' || storedSearch?.booking_type === 'intercity') &&
                            <>
                              <small className='d-block text-uppercase'>DROP-OFF POINT</small>
                              <p className='fw-bold secondaryText mb-0'> {storedSearch?.dropoff}</p>
                            </>
                          }



                          <small className='d-block text-uppercase'>DEPARTURE DATE & TIME</small>
                          <p className='fw-bold secondaryText mb-0'> {storedSearch?.date}</p>
                          {(storedSearch?.return_date === null || storedSearch?.return_date === 'null') ?
                            <></>
                            :
                            (storedSearch?.booking_type === "p_to_p" || storedSearch?.booking_type === "intercity") ?
                              <>
                                <small className='d-block text-uppercase'>RETURN DATE & TIME</small>
                                <p className='fw-bold secondaryText mb-0'> {storedSearch?.return_date === null || storedSearch?.return_date === 'null' ? 'N/A' : storedSearch?.return_date}</p>
                              </>
                              : <></>
                          }
                          {(storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour') &&
                            <>
                              <small className='d-block text-uppercase'>NOTE</small>
                              <p className='fw-bold secondaryText mb-0'> Hourly or city tour bookings are available for local travel within city limits, with drop-offs required to be within the same boundaries.</p>
                            </>
                          }
                        </Card.Body>
                      </Card>
                      {/*  */}
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Route Details</h6>
                          <Map pickupGeometry={locationDetails?.pickupGeometry} dropoffGeometry={locationDetails?.dropoffGeometry} />

                          {(storedSearch?.booking_type === 'p_to_p' || storedSearch?.booking_type === 'intercity') &&
                            <>
                              <small className='d-block text-uppercase mt-4'>ESTIMATED TRIP TIME</small>
                              <p className='fw-bold secondaryText'>{locationDetails?.duration}</p>

                              <small className='d-block text-uppercase'>ESTIMATED DISTANCE</small>
                              <p className='fw-bold secondaryText mb-0'>{locationDetails?.distance}</p>
                            </>
                          }
                          {(storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour') &&
                            <>
                              <small className='d-block text-uppercase mt-4'>DURATION SELECTED</small>
                              <p className='fw-bold secondaryText'>{locationDetails?.duration}</p>

                              <small className='d-block text-uppercase'>KILOMETERS INCLUDED</small>
                              <p className='fw-bold secondaryText mb-0'>{locationDetails?.distance}</p>
                            </>
                          }


                        </Card.Body>
                      </Card>
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Price Includes</h6>
                          <ul className='list-unstyled'>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Included taxes</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Flat Rate</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Certified & reliable drivers</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Free Cancellation 24 Hours Before</li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row className='align-items-start vehicleUl'>
                    <Col xxl={'8'} xl={'8'} lg={'7'} className={'mb-4 mb-xl-0'}>
                      <Form className={'paymentFrom'}>
                        {isPaymentPageReady === false &&
                          <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
                            <Card.Body className='p-4'>
                              <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Passenger Details</h6>
                              <div className='mb-4'>
                                <Form.Label className='mb-0'>Email Address <span className={'orangeText'}>*</span></Form.Label>
                                <Form.Control type="email" value={email} onChange={handleInputChange(setEmail)} isInvalid={!!errors.email} placeholder="abc-xyz@gmail.com" />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                              </div>
                              <Row className='d-md-flex align-items-center mb-4'>
                                <Col lg={'6'} className={'mb-4 mb-lg-0'}>
                                  <Form.Label className='mb-0'>First Name <span className={'orangeText'}>*</span></Form.Label>
                                  <Form.Control type="text" value={firstName} onChange={handleInputChange(setFirstName)} isInvalid={!!errors.firstName} placeholder="Enter your first name" />
                                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                </Col>
                                <Col lg={'6'} className={''}>
                                  <Form.Label className='mb-0'>Last Name <span className={'orangeText'}>*</span></Form.Label>
                                  <Form.Control type="text" value={lastName} onChange={handleInputChange(setLastName)} isInvalid={!!errors.lastName} placeholder="Enter your last name" />
                                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                </Col>
                              </Row>
                              <div className='mb-4'>
                                <Form.Label className='mb-0'>Phone Number <span className={'orangeText'}>*</span></Form.Label>
                                <PhoneInput country={'us'} value={phoneNumber} onChange={handlePhoneChange} containerClass={`phone-input-container ${errors.phoneNumber ? 'is-invalid' : ''}`} />
                                <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                              </div>
                              <div className='mb-4'>
                                <Form.Label className='mb-0'>Flight Number <span className={'orangeText'}>*</span></Form.Label>
                                <Form.Control type="text" value={flightNumber} onChange={handleInputChange(setFlightNumber)} isInvalid={!!errors.flightNumber} placeholder="e.g. UK1234" />
                                <Form.Control.Feedback type="invalid">{errors.flightNumber}</Form.Control.Feedback>
                              </div>
                              <div>
                                <Form.Label className='mb-0'>Special Instructions (Optional) </Form.Label>
                                <Form.Control type="text" value={specialInstructions} onChange={handleInputChange(setSpecialInstructions)} placeholder="e.g. English speaking driver" />
                              </div>
                              {!!availablePaymentMethods.is_invoice && (
                                <div className="mt-4">
                                  <div className="d-flex flex-col gap-2 mb-3" style={{ gap: '12px' }}>
                                    {!!availablePaymentMethods.is_credit_card && (
                                      <label
                                        htmlFor="creditCard"
                                        className="d-flex align-items-center gap-2"
                                      >
                                        <input
                                          style={{ height: '32px', width: '26px' }}
                                          type="radio"
                                          required
                                          value={"creditCard"}
                                          id="creditCard"
                                          name="paymentMethod"
                                          onChange={() => {
                                            setPaymentMethod("creditCard");
                                          }}
                                          checked={paymentMethod === "creditCard"}
                                        />

                                        <span> Credit Card</span>
                                      </label>
                                    )}

                                    <label
                                      htmlFor="invoice"
                                      className="d-flex align-items-center gap-2"
                                    >
                                      <input
                                        style={{ height: '32px', width: '26px' }}
                                        type="radio"
                                        required
                                        value={"invoice"}
                                        id="invoice"
                                        name="paymentMethod"
                                        onChange={() => {
                                          setPaymentMethod("invoice");
                                        }}
                                        checked={paymentMethod === "invoice"}
                                      />

                                      <span> Invoice</span>
                                    </label>
                                  </div>
                                </div>
                              )}
                              <div className='mt-4'>
                                <p className='d-flex align-items-center mb-5' style={{ gap: '10px' }}>
                                  <InputGroup.Checkbox variant={''} aria-label="" />
                                  <span className='secondaryText' style={{ fontSize: '18px', fontWeight: '400', }}>I accept <Link to={'/terms-conditions'}><b>conditions of transport</b></Link> *</span>
                                </p>
                              </div>

                              {storedUser?.token?.access_token && (
                                paymentMethod === 'creditCard' ?
                                  <Button onClick={handleSubmit} disabled={Loader} className='allView-btn mt-3'>{Loader ? <BouncingLoader /> : 'Save & Continue'}  </Button>
                                  :
                                  <Button type="button" onClick={() => { setLoader(true); initBooking(); }} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Confirm Booking'}</Button>
                              )}
                              {!storedUser?.token?.access_token && (
                                paymentMethod === 'creditCard' ?
                                  <Button onClick={handleGuestLoginSuccess} disabled={Loader} className='allView-btn mt-3'>{Loader ? <BouncingLoader /> : 'Save & Continue'}  </Button>
                                  :
                                  <Button type="button" onClick={() => { setLoader(true); initBooking(); }} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Confirm Booking'}</Button>
                              )}
                            </Card.Body>
                          </Card>
                        }
                        {/*  */}
                        {isPaymentPageReady && <>
                          <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
                            <Card.Body className='p-4'>
                              <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Payment Method</h6>
                              {paymentMethod === 'creditCard' &&
                                <Tabs className={'cardButton'}>
                                  <TabList>
                                    <Tab>
                                      <Button variant={''} className={'text-center p-0'} style={{ maxWidth: '170px', minWidth: '170px', maxHeight: '115px', minHeight: '115px', }}>
                                        <img className='img-fluid mb-2' src={paymentCard} alt={'icon'} />
                                        <p className='mb-0 color2Text' style={{ fontSize: '16px', fontWeight: '400', }}>Add New Card</p>
                                      </Button>
                                    </Tab>
                                    {cardlists?.length > 0 &&
                                      cardlists.map((val, index) => {
                                        return (
                                          <Tab style={{ backgroundColor: '#fff', border: 'solid 1px #505050', }} key={index}>
                                            <Button variant={''} className={'text-start'} style={{ maxWidth: '170px', minWidth: '170px', maxHeight: '115px', minHeight: '115px', }}>
                                              <img className='img-fluid mb-2' src={val.brand === 'Visa' ? paymentCard1 : paymentCard2} alt={'icon'} />
                                              <p className='mb-0 color2Text' style={{ fontSize: '16px', fontWeight: '400', }}>**** **** **** {val.last4}</p>
                                              <p className='mb-0 color2Text' style={{ fontSize: '12px', fontWeight: '400', }}>Exp: {val.exp_month}/{val.exp_year}</p>
                                            </Button>
                                          </Tab>
                                        );
                                      })
                                    }
                                  </TabList>
                                  <TabPanel>
                                    <Row className='d-md-flex align-items-center mb-4'>
                                      <Col lg={'6'} className={''}>
                                        <Form.Label className='mb-0'>Cardholder Name</Form.Label>
                                        <Form.Control type="text" placeholder="John Duo" value={cardName} onChange={(e) => setCardName(e.target.value)} />
                                      </Col>
                                    </Row>
                                    <Row className='d-md-flex align-items-center'>
                                      <Col lg={'12'} className={'mb-4 mb-lg-0'}>
                                        <CardElement options={CardElementOptions} />
                                      </Col>
                                    </Row>
                                    <Row className='d-md-flex align-items-center'>
                                      <Col lg={'12'} className={'mb-4 mb-lg-0'}>
                                        <p className='d-flex align-items-center mt-3' style={{ gap: '10px' }}>
                                          <InputGroup.Checkbox defaultChecked={isSaveCard} onChange={() => setIsSaveCard(!isSaveCard)} variant={''} aria-label="" />
                                          <span className='secondaryText' style={{ fontSize: '18px', fontWeight: '400', }}>Save my card for faster payments</span>
                                        </p>
                                      </Col>
                                    </Row>
                                    <Button type="button" onClick={() => { setLoader(true); confirmBooking(elements.getElement(CardElement)); }} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Confirm Booking'}</Button>
                                  </TabPanel>
                                  {cardlists?.length > 0 &&
                                    cardlists.map((val, index) => {
                                      return (
                                        <TabPanel key={index}>
                                          <Button type="button" onClick={() => { setLoader(true); confirmBookingSavedCard(val.id); }} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Select & Pay'}</Button>
                                          <Button type="button" onClick={() => removeCard(val.id)} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Remove'}</Button>
                                        </TabPanel>
                                      );
                                    })
                                  }
                                </Tabs>
                              }
                              {paymentMethod === 'invoice' &&
                                <Button type="button" onClick={() => { setLoader(true); initBooking(); }} className='allView-btn mt-3' disabled={Loader}>{Loader ? <BouncingLoader /> : 'Confirm Booking'}</Button>
                              }
                            </Card.Body>
                          </Card>
                        </>
                        }
                      </Form>
                    </Col>
                    <Col xxl={'4'} xl={'4'} lg={'5'} className={''}>
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Selected Transport</h6>
                          {Object.keys(selectedcar).length === 0 ? (
                            <p>No Selected Transport</p>
                          ) : (<>
                            <p className='text-center'><img className='img-fluid' src={selectedcar?.fleet_image} alt='icon' /></p>
                            <Row className='gx-0 px-4 align-items-center justify-content-between mb-3'>
                              <Col sm={'6'} className=''>
                                <div className='d-flex align-items-center'><img className='img-fluid me-2' src={userIcon} alt='icon' /><p className='mb-0 fw-semibold'>{selectedcar?.num_passengers} Passanger{selectedcar?.num_passengers > 1 ? 's' : ''}</p></div>
                              </Col>
                              <Col sm={'6'} className=''>
                                <div className='d-flex align-items-center'><img className='img-fluid me-2' src={breifIcon} alt='icon' /><p className='mb-0 fw-semibold'>{selectedcar?.luggage_capacity} Suitcase{selectedcar?.luggage_capacity > 1 ? 's' : ''}</p></div>
                              </Col>
                            </Row>
                            {/*  */}
                            <Row className='gx-0 px-4 align-items-center justify-content-between'>
                              <Col sm={'6'} className=''>
                                <div className='d-flex align-items-center'><img className='img-fluid me-2' src={meetgreetIcon} alt='icon' /><p className='mb-0 fw-semibold'>{selectedcar?.meet_greet === true ? 'Meet & Greet' : 'N/A'}</p></div>
                              </Col>
                              <Col sm={'6'} className=''>
                                <div className='d-flex align-items-center'><img className='img-fluid me-2' src={timeIcon} alt='icon' /><p className='mb-0 fw-semibold'>{selectedcar?.waiting_time} min waiting time</p></div>
                              </Col>
                            </Row>
                            {/*  */}
                            <hr />
                            <h4 className={'mb-1'} style={{ fontSize: '24px', fontWeight: '600', lineHeight: '28px', }}>{selectedcar?.car_class.replace("_", " ")} - {selectedcar?.manufacturer} {selectedcar?.model}</h4>
                            <p>or similar</p>
                            <h6 className={'mb-0'} style={{ fontWeight: '700', fontSize: '40px', lineHeight: '48px', }}>{selectedcar?.fare}</h6>
                            <p className='mb-0'>All Price includes taxes.</p>
                          </>)}
                        </Card.Body>
                      </Card>
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Transfer Details</h6>

                          <small className='d-block text-uppercase'>PICKUP POINT</small>
                          <p className='fw-bold secondaryText mb-0'>{storedSearch?.pickup}</p>
                          {(storedSearch?.booking_type === 'p_to_p' || storedSearch?.booking_type === 'intercity') &&
                            <>
                              <small className='d-block text-uppercase'>DROP-OFF POINT</small>
                              <p className='fw-bold secondaryText mb-0'> {storedSearch?.dropoff}</p>
                            </>
                          }

                          <small className='d-block text-uppercase'>DEPARTURE DATE & TIME</small>
                          <p className='fw-bold secondaryText mb-0'> {storedSearch?.date}</p>

                          {(storedSearch?.return_date === null || storedSearch?.return_date === 'null') ?
                            <></>
                            :
                            (storedSearch?.booking_type === "p_to_p" || storedSearch?.booking_type === "intercity") ?
                              <>
                                <small className='d-block text-uppercase'>RETURN DATE & TIME</small>
                                <p className='fw-bold secondaryText mb-0'> {storedSearch?.return_date === null || storedSearch?.return_date === 'null' ? 'N/A' : storedSearch?.return_date}</p>
                              </>
                              : <></>
                          }
                          {(storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour') &&
                            <>
                              <small className='d-block text-uppercase'>NOTE</small>
                              <p className='fw-bold secondaryText mb-0'> Hourly or city tour bookings are available for local travel within city limits, with drop-offs required to be within the same boundaries.</p>
                            </>
                          }
                          <small className='d-block text-uppercase'>PASSENGERS/BAGS</small>
                          <p className='fw-bold secondaryText mb-0'> {storedSearch?.pass} Passenger{storedSearch?.pass > 1 ? 's' : ''} / {storedSearch?.bags} Bag{storedSearch?.bags > 1 ? 's' : ''}</p>
                          {(storedSearch?.booking_type === 'p_to_p' || storedSearch?.booking_type === 'intercity') &&
                            <>
                              <small className='d-block text-uppercase'>ESTIMATED TRIP TIME</small>
                              <p className='fw-bold secondaryText mb-0'> {selectedcar?.duration}</p>

                              <small className='d-block text-uppercase'>ESTIMATED DISTANCE</small>
                              <p className='fw-bold secondaryText mb-0'>{selectedcar?.distance}</p>
                            </>
                          }
                          {(storedSearch?.booking_type === 'hourly' || storedSearch?.booking_type === 'city_tour') &&
                            <>
                              <small className='d-block text-uppercase'>DURATION SELECTED</small>
                              <p className='fw-bold secondaryText mb-0'> {selectedcar?.duration}</p>

                              <small className='d-block text-uppercase'>KILOMETERS INCLUDED</small>
                              <p className='fw-bold secondaryText mb-0'>{selectedcar?.distance}</p>
                            </>
                          }
                          <small className='d-block text-uppercase'>TRIP TOTAL</small>
                          <h5 className='fw-bold secondaryText mb-0' style={{ fontSize: '28px', }}> {selectedcar?.fare}</h5>

                        </Card.Body>
                      </Card>

                      {/*  */}
                      <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                        <Card.Body className='p-4'>
                          <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Price Includes</h6>
                          <ul className='list-unstyled'>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Included taxes</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Flat Rate</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Certified & reliable drivers</li>
                            <li style={{ fontSize: '18px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Free Cancellation 24 Hours Before</li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </Col>
            {/*  */}
            <Col xxl={'10'} xl={'10'} lg={'11'} className='mx-auto col-11'>
              <section className='feel_wrap bg_1 py-5'>
                <div className='col-11 mx-auto' style={{ position: 'relative', zIndex: '1' }}>
                  <h4 className='title '>looking for <span className='mainText'>private bus?</span></h4>
                  <p className='text-white'>Need a private bus for larger groups? We've got you covered with spacious and<br /> comfortable options for all your group travel needs. </p>
                  <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                    <a href="mailto:support@limowide.com" className='SendEmail-btn btn d-flex align-items-center justify-content-center'>Send Email</a>
                    <a className='StartChat-btn btn d-flex align-items-center justify-content-center' target='_blank' href='https://wa.me/447458148595'><img className='img-fluid me-2' src={whatsappIMG} alt='icon' /> Start Chat</a>
                  </div>
                </div>
              </section>
            </Col>
          </section>
        </>
      )}
    </>
  );
};

export default ResultsPage;
