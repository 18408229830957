import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import axios from "axios";
import config from '../config';
// import { Helmet } from "react-helmet-async";
// 
import BouncingLoader from '../components/BouncingLoader';
import { toast } from "react-toastify";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const email = useState(sessionStorage.getItem("reset_pwd_mail"));
    const [error, setError] = useState(null);
    const strongPasswordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let query = useQuery();
    const [resetStatus, setResetStatus] = useState(false);
    useEffect(() => {

    }, [query]);

    useEffect(() => {
        if (!email[0]) navigate("/forgot-password");
    }, [email,navigate]);
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleOtpChange = (e) => {
        setOtp(e.target.value.replace(/\D/, ""));
    };

    const resetPassword = async (e) => {
        e.preventDefault();
        if (!otp) {
            setError("OTP is mandatory");
            return;
        }
        if (!password) {
            setError("Password is mandatory");
            return;
        }
        if (!confirmPassword) {
            setError("Confirm Password is mandatory");
            return;
        }
        if (password !== confirmPassword) {
            setError("Password and Confirm Password not matching");
            return false;
        }
        if (!strongPasswordRegex.test(password)) {
            setError("Your password must be 8 characters long");
            return;
        }
        setLoading(true); // set loading state to true
        axios.post(`${config.ResetPassword}`, {
            email: email[0],
            token: otp,
            password: password,
            confirm_password: confirmPassword,
        }, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
          }
        },
        ).then(result => {
            setResetStatus(true);
        })
        .catch(error => {
            if(error?.response?.data?.data?.token[0]){
                toast.error(error?.response?.data?.data?.token[0]);
            }
            toast.error(Object.values(error.response.data.data)[0]);
        })
        .finally(() => {
          setLoading(false); // reset loading state
        });
    };


    return (
        <main className="container-fluid mt-5 pb-7 bgimage">
            <Row className="justify-content-center">
                <Col lg={'5'} className="">
                    <Card className="shadow-lg" style={{ border: 'none' }}>
                        <Row className="g-0">
                            {resetStatus && (
                                <Col md={'12'} className="px-5 py-4">
                                    <div className="flex place-content-center text-center pt-4 pb-3">
                                        <svg
                                            style={{ filter: 'sepia(1)' }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            id="body_1"
                                            width={102}
                                            height={77}
                                        >
                                            <g transform="matrix(1.54 0 0 1.54 12.499998 -0)">
                                                <path
                                                    d="M50 25C 50.000004 31.90356 47.559227 37.796116 42.67767 42.67767C 37.796116 47.559227 31.90356 50.000004 25 50C 18.096441 50.000004 12.203884 47.559227 7.3223305 42.67767C 2.4407768 37.796116 0 31.90356 0 25C 0 18.096441 2.4407768 12.203884 7.3223305 7.3223305C 12.203884 2.4407768 18.096441 0 25 0C 31.90356 0 37.796116 2.4407768 42.67767 7.3223305C 47.559227 12.203884 50.000004 18.096441 50 25C 50.000004 25.290918 49.994926 25.581747 49.98477 25.872488"
                                                    stroke="none"
                                                    fill="#25AE88"
                                                    fillRule="nonzero"
                                                />
                                                <path
                                                    d="M38 15L22 33L12 25"
                                                    stroke="#FFFFFF"
                                                    strokeWidth={2}
                                                    strokeLinecap="square"
                                                    fill="none"
                                                />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                                <g />
                                            </g>
                                        </svg>
                                    </div>
                                    <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>Password Changed</h2>
                                    <p className='text-center pragraphText' style={{ lineHeight: '20px' }}>Your password has been changed successfully</p>
                                    <Button variant={''} type="submit" className="loginbtn w-100" onClick={() => { sessionStorage.clear(); navigate("/signin"); }}>Back To Login</Button>
                                </Col>
                            )}
                            {!resetStatus && (
                                <Col md={'12'} className="px-5 py-4">
                                    <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>Set your New Password</h2>
                                    <form onSubmit={resetPassword} className="loginform mt-5">
                                        <div className="mb-3">
                                            <Form.Control required type="text" value={otp} name="otpToReset" autoComplete="one-time-code" placeholder="Enter OTP which you got on email ID"
                                                className={"" + (error && !otp ? "border-red-700" : "border-blue-400")}
                                                onChange={handleOtpChange}
                                                maxLength={6}
                                                onBlur={() => {
                                                    setError("");
                                                    if (!otp) {
                                                        setError("OTP is mandatory");
                                                    }
                                                }} />
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control required type="password" value={password} name="password" autoComplete="new-password" placeholder="Enter your New password"
                                                className={"" + (error && !strongPasswordRegex.test(password) ? "border-red-700" : "border-blue-400")}
                                                onChange={(e) => {
                                                    setPassword(e.target.value.slice(0, 20));
                                                }}
                                                onBlur={() => {
                                                    setError("");
                                                    if (!strongPasswordRegex.test(password)) {
                                                        setError("Your password must be 8 characters long");
                                                    }
                                                }}
                                                maxLength={20} />
                                        </div>
                                        <div className="mb-3">
                                            <Form.Control required type="password" name="confirmPassword" autoComplete="new-password" placeholder="Confirm your new password"
                                                className={
                                                    "py-2  pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                                                    (error && password !== confirmPassword
                                                        ? "border-red-700"
                                                        : "border-blue-400")
                                                }
                                                onChange={(e) => {
                                                    setConfirmPassword(e.target.value.slice(0, 20));
                                                }}
                                                maxLength={20}
                                                onBlur={() => {
                                                    setError("");
                                                    if (password !== confirmPassword) {
                                                        setError("Passwords do not match");
                                                    }
                                                }} />
                                        </div>
                                        {error && (
                                            <div>
                                                <p className="grow-0	text-red-500 mt-4">{error}</p>
                                                {error === "Your password must be 8 characters long" && (
                                                    <div>
                                                        <p className="text-red-500 mt-2">
                                                            1 uppercase & 1 lowercase character
                                                        </p>
                                                        <p className="text-red-500 mt-2">
                                                            1 number & Special Character
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <Button variant={''} type="submit" className="loginbtn w-100">
                                            {loading ? <BouncingLoader /> : 'Submit'}  </Button>

                                    </form>
                                </Col>
                            )}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </main>

    );
};

export default ResetPassword;
