import React,{useEffect} from 'react';
import { Breadcrumb,Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const AffiliateWebmasters = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)',borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Affiliate & <span className=''>Web Masters</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Affiliate & Web Masters</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>         
      {/*  */}
      <section className='py-4'>
      <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <h2>Join Our Affiliate & Webmasters Network Today!</h2>

          <p style={{ fontFamily: 'var(--FontFamily_Figtree)' }}>At Limowide, we believe in collaboration and creating opportunities for our partners. Our Affiliate & Webmasters Program is designed for individuals and businesses looking to earn commissions by promoting our premium limousine services. With a reputation for excellence and a commitment to customer satisfaction, partnering with Limowide opens up exciting earning potential.</p>

          <h3>Why Partner with Limowide?</h3>
          <ul>
            <li><strong>Industry-Leading Services:</strong> Be part of a reputable brand known for its luxurious fleet of vehicles, professional chauffeurs, and exceptional customer service.</li>
            <li><strong>Attractive Commissions:</strong> Our competitive commission structure rewards you for every booking generated through your referrals. Earn commissions on various services, including airport transfers, corporate events, weddings, and more.</li>
            <li><strong>Wide Range of Offerings:</strong> With services catering to various needs, you can easily promote our products to a diverse audience. From corporate travel to special occasions, there’s something for everyone at Limowide.</li>
            <li><strong>Marketing Support:</strong> We provide our affiliates with promotional materials, including banners, text links, and custom content to help you effectively market our services. Our dedicated support team is here to assist you every step of the way.</li>
            <li><strong>Easy Tracking and Reporting:</strong> Our user-friendly affiliate dashboard allows you to track your referrals and commissions in real-time. You'll have access to detailed reports to monitor your performance and optimize your strategies.</li>
          </ul>

          <h3>How It Works</h3>
          <ol>
            <li><strong>Sign Up:</strong> Join our Affiliate & Webmasters Program by filling out a simple application form on our website. Once approved, you’ll gain access to our marketing materials and affiliate dashboard.</li>
            <li><strong>Promote Limowide:</strong> Utilize your website, blog, social media, or any other platform to promote our services using the provided links and promotional content.</li>
            <li><strong>Earn Commissions:</strong> Whenever a customer books a service through your referral link, you earn a commission. It’s that simple!</li>
            <li><strong>Get Paid:</strong> Enjoy timely payments through various available methods. Your earnings are just a few clicks away.</li>
          </ol>

          <h3>Who Can Join?</h3>
          <ul>
            <li>Website owners and bloggers in the travel, lifestyle, or event planning niches</li>
            <li>Social media influencers with a relevant audience</li>
            <li>Travel agents and consultants</li>
            <li>Anyone passionate about luxury travel and looking to monetize their platform</li>
          </ul>

          <h3>Join Us Today!</h3>
          <p style={{ fontFamily: 'var(--FontFamily_Figtree)' }}>Ready to start earning with Limowide? <a href="https://www.limowide.com/affiliate-signup" target="_blank">Sign up now</a> and become an integral part of our Affiliate & Webmasters Program. We look forward to building a successful partnership together!</p>

          <p style={{ fontFamily: 'var(--FontFamily_Figtree)' }}>For any inquiries, please contact our affiliate support team at <a href="mailto:partner@Limowide.com">partner@Limowide.com</a>. Let’s drive success together!</p>
        </Col>
      </section>
    </>
  );
};

export default AffiliateWebmasters;
