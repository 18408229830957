import React, { useState } from 'react';

import { Col, Dropdown, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { isAuthenticated } from '../utils/auth';
import { Link, useLocation } from 'react-router-dom';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import companyLogo from '../images/logo.png';
import profile from '../images/profile.png';
import { getStoredUser } from '../utils/localStorageUtils';
const DriverHeader = (props) => {

  const storedUsers = getStoredUser();
  return (
    <>
      <Navbar expand="lg" className={`py-1 fixed-top header-bg`}>
        <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className={'mx-auto col-11'}>
          <div className='d-flex'>
            <Link className={'navbar-brand m-0 p-0'} to="/d-profile"><img className='img-fluid' width={'100'} src={companyLogo} alt="logo" /></Link>
            <Nav className="ms-auto align-items-center d-flex" style={{ gap: "10px", flexDirection: 'row' }}>
              {isAuthenticated() ? (
                <>
                  <Link to={'/d-profile'} className='d-flex align-items-center' style={{ gap: "10px" }}>
                    <div className='userprofile'>
                      <img src={profile} alt='' />
                    </div>
                    <p className='m-0 text-white' id="profilename">{storedUsers.first_name}</p>
                  </Link>
                </>
              ) : (
                <>
                <Link className="btn-signin" to="/signin">Sign In</Link>
                </>
              )}
            </Nav>
          </div>
          {/* </Navbar.Collapse> */}
        </Col>
      </Navbar>
    </>
  );
};

export default DriverHeader;
