import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';
import Loading from '../components/Loading';
import config from '../config';
import { handleApiError } from '../utils/errorHandler';
import { Helmet } from 'react-helmet-async';
// 
import ExperienceUL from '../components/ExperienceUL';
// 
import banner from '../images/banner_01.jpg';
import icon1 from '../images/icon1.svg';
import icon2 from '../images/icon2.svg';
import icon3 from '../images/icon3.svg';
import Search from '../components/Search';

// optionsprojects
const optionsprojects = {
  spaceBetween: 50,
  modules: [Autoplay],
  breakpoints: {
    400: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    700: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1300: {
      slidesPerView: 4,
    },
    1500: {
      slidesPerView: 5,
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
};

const LimousineService = () => {
  const currentUrl = window.location.href;
  const [isLoading, setLoading] = useState(false);
  const [coverimage, setCoverImage] = useState(banner);
  const [cities, setCities] = useState([]);
  const [sections, setSections] = useState([]);
  const [cityDetails, setCityDetails] = useState({});
  useEffect(() => {
    setLoading(true);
    const fetchdata = () => {

      axios.get(`${config.CitySeoLimousineService}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      }).then(result => {
        if (result?.data[0]?.other_cities) {
          setCities(result?.data[0]?.other_cities);
          setSections(result?.data[0]?.sections);
          setCoverImage(result?.data[0]?.cover_image);
          setCityDetails(result?.data[0]);
        }
      })
        .catch(error => {
          handleApiError(error)
        })
        .finally(() => {
          setLoading(false); // reset loading state
        });
    };    
    fetchdata();
    window.scrollTo(0, 0)
  }, []);


  return (
    <>
      <main>
      <Helmet>
          <meta name="robots" content="index, follow" />
          <title>{cityDetails?.meta_title}</title>
          <link rel="canonical" href={currentUrl} />
          <meta name="title" content={cityDetails?.meta_title} />
          <meta name="description" content={cityDetails?.meta_description} />
          <meta name="keywords" content={cityDetails?.meta_keywords}></meta>
          <meta property="og:title" content={cityDetails.page_title} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="WebPage" />
          <meta property="og:description" content={cityDetails.meta_description} />
          <meta name="twitter:title" content={cityDetails.page_title} />
          <meta name="twitter:description" content={cityDetails.meta_description} />
          <meta name="twitter:url" content={currentUrl} />
          <meta property="og:image" content={coverimage} />
        </Helmet>
        <section className="banner d-flex align-items-center justify-content-center image_01" style={{ backgroundImage: `url(${coverimage})`, height: 'calc(100vh - 50vh)' }}>
          <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
            <h1>Limousine <span className=''>Service</span></h1>
          </Col>
        </section>
        {/*  */}
        <Search />
        {/*  */}
        <section className='howItWork position-relative' style={{ paddingBottom: '60px', paddingTop: '60px' }}>
          <Col xxl={'9'} xl={'10'} className='col-11 mx-auto bgImage1'>
            <h5 className='text-center howItWorkTitle mb-5'>how it works</h5>
            <Row className=''>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon1} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>01</span>
                  <h5 className='secondaryText mb-2 position-relative' style={{ fontWeight: '600', fontSize: '18px', }}>SELECT INFORMATION</h5>
                </div>
                <p className='mb-0'>Enter your pickup location and time and select your car.</p>
              </Col>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon2} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>02</span>
                  <h5 className='secondaryText mb-2' style={{ fontWeight: '600', fontSize: '18px', }}>PROCEED TO PAYMENT</h5>
                </div>
                <p className='mb-0'>Check the booking details and select your preferred payment method.</p>
              </Col>
              <Col xl={'4'} lg={'4'} md={'4'} className='mb-4 mb-lg-0 px-md-5 text-center text-md-start'>
                <div className='mx-md-0 mx-auto secondaryBG d-flex align-items-center justify-content-center mb-4' style={{ borderRadius: '16px', maxHeight: '100px', minHeight: '100px', maxWidth: '100px', minWidth: '100px', }}><img className='img-fluid' src={icon3} alt='icon' /></div>
                <div className='position-relative'>
                  <span className={'secondaryText position-absolute d-md-block d-none'} style={{ fontWeight: '700', left: '-70px', top: '-10px', lineHeight: '40px', fontSize: '48px', }}>03</span>
                  <h5 className='secondaryText mb-2' style={{ fontWeight: '600', fontSize: '18px', }}>ENJOY THE RIDE</h5>
                </div>
                <p className='mb-0'>Our Limowide driver will pick you up at the specified place and time.</p>
              </Col>
            </Row>
          </Col>
        </section>
        {/*  */}
        {isLoading ? (
          <div className="flex justify-center items-center h-96">
            <Loading />
          </div>
        ) : (
          <>
            {sections?.length ? (
              sections.map((val, index) => {
                if (index % 2 === 0) {
                  return (
                    <section key={index} className='' style={{ paddingBottom: '160px', borderRadius: '48px', paddingTop: '60px' }}>
                      <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
                        <Row className={'align-items-center bgImage'} style={{ backgroundPosition: 'center right' }}>
                          <Col xxl={'6'} xl={'6'} className={''}>
                            <h5 className='text-start allTile mb-4'>{val?.title}</h5>
                            <div className='description mb-4' style={{ textAlign: 'justify' }}
                              dangerouslySetInnerHTML={{ __html: val?.description }}
                            />
                          </Col>
                          <Col xxl={'6'} xl={'6'} className={'mb-4 mb-lg-0 text-center'}>
                            <div className='whiteBG p-3 dropShadow' style={{ borderRadius: '8px' }}>
                              <img className='img-fluid w-100' style={{ minHeight: '430px', objectFit: 'cover', maxHeight: '400px', borderRadius: '8px', }} src={val?.image} alt={val?.image_alt} />
                            </div>                           
                          </Col>
                        </Row>
                      </Col>
                    </section>
                  );
                } else {
                  return (
                    <section key={index} className='howItWork' style={{ paddingBottom: '60px', paddingTop: '160px' }}>
                      <Col xxl={'9'} xl={'10'} className='col-11 mx-auto bgImage'>
                        <Row className={'align-items-center'}>
                          <Col xxl={'6'} xl={'6'} className={'mb-4 mb-lg-0 text-center'}>
                            <div className='whiteBG p-3 dropShadow' style={{ borderRadius: '8px' }}>
                              <img className='img-fluid w-100' style={{ minHeight: '430px', objectFit: 'cover', maxHeight: '400px', borderRadius: '8px', }} src={val?.image} alt={val?.image_alt} />
                            </div>                           
                          </Col>
                          <Col className={''} xxl={'6'} xl={'6'}>
                            <h5 className='text-start allTile mb-4'>{val?.title}</h5>
                            <div className='description mb-4' style={{ textAlign: 'justify' }}
                              dangerouslySetInnerHTML={{ __html: val?.description }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </section>
                  );
                }
              })
            ) : (
              <div className="flex justify-center items-center h-96">
                <p className="text-lg font-bold">No Record Found!</p>
              </div>
            )}
            {/*  */}
            <section className='destinations' style={{ paddingBottom: '60px', backgroundImage: 'none', paddingTop: '60px' }}>
              <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
                <h5 className='text-center allTile mb-4'>Other Cities</h5>
                <Swiper {...optionsprojects} className='mt-5'>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {cities.length > 0 ? (
                        cities.map((val, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <ExperienceUL
                                id={index}
                                title={val.page_title}
                                mainimg={val.thumbnail_image}
                                lcationtext={val.city_name + ', ' + val.country_code}
                                slug={val.slug}
                              />
                            </SwiperSlide>
                          );
                        })
                      ) : (
                        <div className="flex justify-center items-center h-96">
                          <p className="text-lg font-bold ">No Record Found!</p>
                        </div>
                      )}
                    </>
                  )}
                </Swiper>
              </Col>
            </section>
          </>
        )}
      </main>
    </>
  );
};

export default LimousineService;
