import React, { useState, useRef } from 'react';
import {
  useLoadScript,
  StandaloneSearchBox,
} from '@react-google-maps/api';

const libraries = ['places'];

const MapComponent = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA4psHg_qdEKq2bD7ya5c7xw-ZjxZobB-s', // Replace with your API key
    libraries,
  });

  const [pickup, setPickup] = useState({ name: '', address: '', lat: null, lng: null });
  const [dropoff, setDropoff] = useState({ name: '', address: '', lat: null, lng: null });
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  const onPickupChanged = () => {
    const place = pickupRef.current.getPlaces()[0];
    if (place && place.geometry) {
      setPickup({
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const onDropoffChanged = () => {
    const place = dropoffRef.current.getPlaces()[0];
    if (place && place.geometry) {
      setDropoff({
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  return (
    <div>
      <h3>Pickup Location</h3>
      <StandaloneSearchBox
        onLoad={(autocomplete) => (pickupRef.current = autocomplete)}
        onPlacesChanged={onPickupChanged}
        options={{
          types: ['airport']
        }}
      >
        <input
          type="text"
          placeholder="Enter pickup location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            padding: `12px`,
            marginBottom: `12px`,
            borderRadius: `3px`,
            fontSize: `16px`,
            outline: `none`,
          }}
        />
      </StandaloneSearchBox>

      {pickup.lat && pickup.lng && (
        <div>
          <h4>Pickup Details</h4>
          <p>Name: {pickup.name}</p>
          <p>Address: {pickup.address}</p>
          <p>Latitude: {pickup.lat}</p>
          <p>Longitude: {pickup.lng}</p>
        </div>
      )}

      <h3>Dropoff Location</h3>
      <StandaloneSearchBox
        onLoad={(autocomplete) => (dropoffRef.current = autocomplete)}
        onPlacesChanged={onDropoffChanged}
        options={{
          types: ['airport']
        }}
      >
        <input
          type="text"
          placeholder="Enter dropoff location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            padding: `12px`,
            marginBottom: `12px`,
            borderRadius: `3px`,
            fontSize: `16px`,
            outline: `none`,
          }}
        />
      </StandaloneSearchBox>

      {dropoff.lat && dropoff.lng && (
        <div>
          <h4>Dropoff Details</h4>
          <p>Name: {dropoff.name}</p>
          <p>Address: {dropoff.address}</p>
          <p>Latitude: {dropoff.lat}</p>
          <p>Longitude: {dropoff.lng}</p>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
