// src/utils/errorHandler.js

import { toast } from 'react-toastify';

export const handleApiError = (error) => {
  let errorMessage = '';

  if (error.response) {
    const responseData = error.response.data;
    if (responseData.detail) {
      errorMessage = responseData.detail;
    }
    if (responseData.data) {
      const errorDetails = responseData.data;
      const errorMessages = [];

      for (const key in errorDetails) {
        if (errorDetails[key].length > 0) {
          errorMessages.push(`${errorDetails[key].join(', ')}`);
        }
      }

      if (errorMessages.length > 0) {
        errorMessage += ` ${errorMessages.join(' | ')}`;
      }
    }
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = 'An error occurred';
  }

  toast.error(errorMessage);
  console.log('API call error', error);
};
