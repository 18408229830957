import React from 'react';
import {Button} from 'react-bootstrap';

const PopularTagsUL = (props) => {
  return (
    <>
      <Button variant={''} className="btn-outline-primary me-2">{props.tagText}</Button>
    </>
  )
}

export default PopularTagsUL;