import React, { useState } from 'react';

import { Col, Dropdown, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { isAuthenticated } from '../utils/auth';
import { Link, useLocation } from 'react-router-dom';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import companyLogo from '../images/logo.png';
import profile from '../images/profile.png';
import { getStoredUser } from '../utils/localStorageUtils';
export const currencyList = [
  { value: "USD", label: "🇺🇸 USD" },
  { value: "INR", label: "🇮🇳 INR" },
  { value: "EUR", label: "🇪🇺 EUR" },
  { value: "YEN", label: "🇯🇵 YEN" },
  { value: "AED", label: "🇦🇪 AED" },
  { value: "AUD", label: "🇦🇺 AUD" },
  { value: "CAD", label: "🇨🇦 CAD" },
  { value: "CHF", label: "🇨🇭 CHF" },
  { value: "GBP", label: "🇬🇧 GBP" },
  { value: "SAR", label: "🇸🇦 SAR" },
  { value: "NOK", label: "🇳🇴 NOK" },
  { value: "SEK", label: "🇸🇪 SEK" },
  { value: "OMR", label: "🇴🇲 OMR" },
  { value: "KWD", label: "🇰🇼 KWD" },
  { value: "DKK", label: "🇩🇰 DKK" },
  { value: "JOD", label: "🇯🇴 JOD" },
  { value: "BHD", label: "🇧🇭 BHD" },
  { value: "CNY", label: "🇨🇳 CNY" },
];
const Header = (props) => {

  const storedUsers = getStoredUser();
  const [inputValue, setInputValue] = useState("");

  // Default to show only the first 5 options
  const defaultOptions = currencyList.slice(0, 5);

  // Show all options when the user types
  const filteredOptions = currencyList.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );
  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/blogs';
  const isFlightBookingHidden = location.pathname === '/blog-detail' || location.pathname === '/blogs';
  const [currencyVal, setCurrencyVal] = useState(localStorage.getItem("currencyValue") || "EUR");
  return (
    <>
      <Navbar expand="lg" className={`py-1 fixed-top ${isHomePage ? 'header' : 'header-bg'}`}>
        <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className={'mx-auto col-11'}>
          <div className='d-flex'>
            <Link className={'navbar-brand m-0 p-0'} to="/"><img className='img-fluid' width={'100'} src={companyLogo} alt="logo" /></Link>
            {/* <Navbar.Toggle className='d-none d-lg-none' aria-controls="basic-navbar-nav" /> */}
            {/* <Button variant={''} style={{width:'60px'}} onClick={handleShowCompanies} className='d-flex d-lg-none'><img className='img-fluid' src={menudots} alt='icon'/></Button> */}
            {/* <Navbar.Collapse id="basic-navbar-nav d-none d-lg-flex"> */}
            <Nav className="ms-auto align-items-center d-flex" style={{ gap: "10px", flexDirection: 'row' }}>
              {isFlightBookingHidden ??
                <Form.Control type="text" className='blogsearch me-lg-5 pe-lg-3' placeholder="Search Blog...." />
              }
    <Select
      // options={inputValue ? filteredOptions : defaultOptions}
      options={currencyList}
      value={currencyList.find((option) => option.value === currencyVal)}
      onInputChange={(value) => setInputValue(value)}
      onChange={(selectedOption) => {
        setCurrencyVal(selectedOption.value);
        localStorage.setItem("currencyValue", selectedOption.value);
        window.location.reload();
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "#182239",
          color: "#fff",
          border: "none",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "#000" : "#182239",
          backgroundColor: state.isSelected ? "#FAE069" : "#fff",
        }),
      }}
      placeholder="Select Currency"
    />

              {isAuthenticated() ? (
                <>
                  <Link to={'/profile'} className='d-flex align-items-center' style={{ gap: "10px" }}>
                    <div className='userprofile'>
                      <img src={profile} alt='' />
                    </div>
                    <p className='m-0 text-white' id="profilename">{storedUsers.first_name}</p>
                  </Link>
                </>
              ) : (
                <>
                <Link className="btn-signin" to="/signin">Sign In</Link>
                  {/* <Dropdown className="d-sm-none d-inline dropend" autoClose="inside">
                    <Dropdown.Toggle variant={''} className={'btn-signin mainBG secondaryText'} id="dropdown-autoclose-inside">Account</Dropdown.Toggle>
                    <Dropdown.Menu className='view'>
                      /* <Dropdown.Item href="#">Menu Item</Dropdown.Item> 
                      <Link className="nav-link me-lg-4" to="/register">Register</Link>
                      <hr className='my-2' />
                      <Link className="btn-signin w-100 mainBG secondaryText" style={{ maxWidth: '100%', minWidth: '100%' }} to="/signin">Sign In</Link>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className='d-sm-flex d-none' style={{ gap: '10px' }}>
                    <Link className="nav-link me-lg-4" to="/register">Register</Link>
                    <Link className="btn-signin" to="/signin">Sign In</Link>
                  </div> */}
                </>
              )}
            </Nav>
          </div>
          {/* </Navbar.Collapse> */}
        </Col>
      </Navbar>
      {/* Companies Offcanvas */}
      {/* <Offcanvas className="headeroffcanvas" show={showCompanies} placement='end' onHide={handleCloseCompanies} {...props}>
        <Offcanvas.Header closeButton className="">
          <Offcanvas.Title className="w-100">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {isFlightBookingHidden ??
            <Form.Control type="text" className='blogsearch me-lg-5 pe-lg-3' placeholder="Search Blog...." />
          }
          <Form.Select style={{ backgroundColor: '#182239', color: '#fff', border: 'none' }}>
            <option value="USD">USD 🇺🇸</option>
            <option value="INR">INR 🇮🇳</option>
            <option value="EUR">EUR 🇪🇺</option>
            <option value="YEN">YEN 🇯🇵</option>
            <option value="AED">AED 🇦🇪</option>
            <option value="AUD">AUD 🇦🇺</option>
            <option value="CAD">CAD 🇨🇦</option>
            <option value="CHF">CHF 🇨🇭</option>
            <option value="GBP">GBP 🇬🇧</option>
            <option value="SAR">SAR 🇸🇦</option>
            <option value="NOK">NOK 🇳🇴</option>
            <option value="SEK">SEK 🇸🇪</option>
            <option value="OMR">OMR 🇴🇲</option>
            <option value="KWD">KWD 🇰🇼</option>
            <option value="DKK">DKK 🇩🇰</option>
            <option value="JOD">JOD 🇯🇴</option>
            <option value="BHD">BHD 🇧🇭</option>
            <option value="CNY">CNY 🇨🇳</option>
          </Form.Select>
          {isAuthenticated() ? (
            <>
              <Link to={'/profile'} className='d-flex align-items-center' style={{ maxWidth: '150px', minWidth: '150px', gap: "10px" }}>
                <div className='userprofile'>
                  <img src={profile} alt='' />
                </div>
                <p className='m-0 text-white' id="profilename" style={{ color: 'black' }}>{storedUsers.first_name} {storedUsers.last_name}</p>
              </Link>
            </>
          ) : (
            <>
              <Link className="nav-link me-lg-4" to="/register" style={{ color: 'black' }}>Register</Link>
              <Link className="btn-signin" to="/signin" style={{ color: 'black' }}>Sign In</Link>
            </>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer d-flex align-items-center">
          <NavLink to="/setting" className="offcanvas-link">Field Settings</NavLink>
        </div>
      </Offcanvas> */}
    </>
  );
};

export default Header;
