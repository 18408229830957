import React, { useEffect } from 'react';
import { Breadcrumb, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)',borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Privacy <span className=''>Policy</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Privacy Policy</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>
      {/* <section className='py-4 breadcrumb_wrap'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto' style={{ position: 'relative', zIndex: '1' }}>
          <h4 className='FontFamilyFigtree text-center whiteText' style={{ fontSize: '28px', fontWeight: '600' }}>Privacy Policy</h4>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/">Privacy Policy</Link></Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section> */}
      {/*  */}
      <section className='py-4'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <div className="flex space-y-4 flex-col">
            <p className="text-2xl font-bold">
              Limowide Privacy Policy
            </p>
            <p>
              This Privacy Policy describes how your personal information is
              collected, used, and shared when you visit or make a purchase from
              https://limowide.com/ (the “Site”).
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">PERSONAL INFORMATION WE COLLECT</p>
            <p>
              When you visit the Site, we automatically collect certain
              information about your device, including information about your web
              browser, IP address, time zone, and some of the cookies that are
              installed on your device. Additionally, as you browse the Site, we
              collect information about the individual web pages or products that
              you view, what websites or search terms referred you to the Site,
              and information about how you interact with the Site. We refer to
              this automatically-collected information as “Device Information.”
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">
              We collect Device Information using the following technologies:
            </p>
            <p className="ml-4">
              – “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For more
              information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.
            </p>
            <p className="ml-4">
              – “Log files” track actions occurring on the Site, and collect data
              including your IP address, browser type, Internet service provider,
              referring/exit pages, and date/time stamps.
            </p>
            <p className="ml-4">
              – “Web beacons,” “tags,” and “pixels” are electronic files used to
              record information about how you browse the Site.
            </p>
            <p>
              Additionally, when you make a purchase or attempt to make a purchase
              through the Site, we collect certain information from you, including
              your name, billing address, shipping address, payment information
              (including credit card numbers & name), email address, and phone
              number. We refer to this information as “Order Information.”
            </p>
            <p>
              When we talk about “Personal Information” in this Privacy Policy, we
              are talking both about Device Information and Order Information.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">
              HOW DO WE USE YOUR PERSONAL INFORMATION?
            </p>
            <p>
              We use the Order Information that we collect generally to fulfill
              any orders placed through the Site (including processing your
              payment information, arranging for shipping, and providing you with
              invoices and/or order confirmations). Additionally, we use this
              Order Information to:
            </p>
            <p>Communicate with you;</p>
            <p>Screen our orders for potential risk or fraud; and</p>
            <p>
              When in line with the preferences you have shared with us, provide
              you with information or advertising relating to our products or
              services.
            </p>
            <p>
              We use the Device Information that we collect to help us screen for
              potential risk and fraud (in particular, your IP address), and more
              generally to improve and optimize our Site (for example, by
              generating analytics about how our customers browse and interact
              with the Site, and to assess the success of our marketing and
              advertising campaigns).
            </p>
            <p>
              We share your Personal Information with third parties to help us use
              your Personal Information, as described above. For example, we use
              Shopify to power our online store–you can read more about how
              Shopify uses your Personal Information here:
              https://www.shopify.com/legal/privacy. We also use Google Analytics
              to help us understand how our customers use the Site–you can read
              more about how Google uses your Personal Information here:
              https://www.google.com/intl/en/policies/privacy/. You can also
              opt-out of Google Analytics here:
              https://tools.google.com/dlpage/gaoptout.
            </p>
            <p>
              Finally, we may also share your Personal Information to comply with
              applicable laws and regulations, to respond to a subpoena, search
              warrant or other lawful requests for information we receive, or to
              otherwise protect our rights.
            </p>
            <p>
              As described above, we use your Personal Information to provide you
              with targeted advertisements or marketing communications we believe
              may be of interest to you. For more information about how targeted
              advertising works, you can visit the Network Advertising
              Initiative’s (“NAI”) educational page at
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">
              You can opt out of targeted advertising by:
            </p>
            <p>
              [[ COMMON LINKS INCLUDE: FACEBOOK –
              https://www.facebook.com/settings/?tab=ads GOOGLE –
              https://www.google.com/settings/ads/anonymous BING –
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              ]]
            </p>
            <p>
              Additionally, you can opt out of some of these services by visiting
              the Digital Advertising Alliance’s opt-out portal at:
              http://optout.aboutads.info/.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">DO NOT TRACK</p>
            <p>
              Please note that we do not alter our Site’s data collection and use
              practices when we see a Do Not Track signal from your browser.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">YOUR RIGHTS</p>
            <p>
              If you are a European resident, you have the right to access
              personal information we hold about you and to ask that your personal
              information be corrected, updated, or deleted. If you would like to
              exercise this right, please contact us through the contact
              information below.
            </p>
            <p>
              Additionally, if you are a European resident we note that we are
              processing your information in order to fulfill contracts we might
              have with you (for example if you make an order through the Site),
              or otherwise to pursue our legitimate business interests listed
              above. Additionally, please note that your information will be
              transferred outside of Europe, including to Canada and the United
              States.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">DATA RETENTION</p>
            <p>
              When you place an order through the Site, we will maintain your
              Order Information for our records unless and until you ask us to
              delete this information.
            </p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">MINORS</p>
            <p>The Site is not intended for individuals under the age of 10.</p>
          </div>
          <div className="flex space-y-4 flex-col mt-2">
            <p className="text-lg font-bold">CHANGES</p>
            <p>
              We may update this privacy policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal or regulatory reasons.
            </p>
          </div>
          <div>
            <p className="text-lg font-bold">CONTACT US</p>
            <p>
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please contact
              us by e-mail at support@limowide.com or by mail using the details
              provided below:
            </p>
            <p> 98, Godstone Road, Kenley, Surrey, CR8 5AB, UK</p>
          </div>
        </Col>
      </section>
    </>
  );
};

export default PrivacyPolicy;
