import React from 'react';

const CategoriesUL = (props) => {
  return (
    <>
      <ul className='list-unstyled'>
        <li className='d-flex align-items-center justify-content-between mb-3'>
          <h6 className='' style={{fontWeight:'bold'}}>{props.title}</h6>
          <span>{props.number}</span>
        </li>
      </ul>
    </>
  )
}

export default CategoriesUL;