import React, { useState } from 'react';

import { Col, Dropdown, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { isAuthenticated } from '../utils/auth';
import { Link, useLocation } from 'react-router-dom';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import routeIcon from '../images/routeIcon.svg';
import profileIcon from '../images/profileIcon.svg';
import location_button_limowide from '../images/location_button_limowide.svg';
import { getStoredUser } from '../utils/localStorageUtils';

const DriverFooter = (props) => {

  const storedUsers = getStoredUser();
  return (
    <>
                    <section className='fixed-bottom whiteBG' style={{zIndex:'9999999999'}}>
                    <Col className='col-11 mx-auto'>
                        <div className='d-flex align-items-center justify-content-between p-3'>
                            <Link to="/d-profile" className='position-relative d-block text-center'>
                                <img className='img-fluid' src={routeIcon} alt='icon' />
                                <p className='mb-0 secondaryText'>My Job</p>
                            </Link>
                            <Link to="/d-profile" className='d-block mx-auto start-0 end-0 position-absolute text-center' style={{ top: '-30px', }}>
                                <img className='img-fluid' src={location_button_limowide} alt='icon' />
                            </Link>
                            <Link to="/driver-profile" className='position-relative d-block text-center'>
                                <img className='img-fluid' src={profileIcon} alt='icon' />
                                <p className='mb-0 secondaryText'>Profile</p>
                            </Link>
                        </div>
                    </Col>
                </section>
    </>
  );
};

export default DriverFooter;
