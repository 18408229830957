import React from 'react';
import { Card } from 'react-bootstrap';

const CardsprojectsUL = (props) => {
    return (
        <>
            <Card key={props.id} className={'mb-4 cardColo'} style={{ border: 'none', backgroundColor:props.cardColor, borderRadius: '24px', }}>
                <Card.Body className='text-center'>
                    <img className='img-fluid mb-3' style={{borderRadius:'12px',}} src={props.serviseIMG} alt='icon'/>
                    <div className='text-center'><img className='img-fluid w-auto mx-auto' src={props.serviseIMGLogo} alt='icon'/></div>
                </Card.Body>
            </Card>
        </>
    )
}

export default CardsprojectsUL;