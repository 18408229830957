import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Button, Row, InputGroup, Card } from "react-bootstrap";
import axios from 'axios';
import { getStoredUser } from '../utils/localStorageUtils';
import config from '../config';
import Loading from '../components/Loading';
import { toast } from 'react-toastify';
import { isAuthenticated } from '../utils/auth';
// 
import unlockicon from '../images/unlockicon.svg';

const Profile = () => {
    const navigate = useNavigate();
  const storedUser = getStoredUser();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setLoading] = useState(false);



  const handleSubmit = async () => {
    setLoading(true);
    const pwdconfig = {
      method: "POST",
      url: `${config.changePassword}`,
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
        "Authorization": `Bearer ${storedUser?.token.access_token}`
      }
    };

    if (oldPassword !== '' && newPassword !== '' && confirmPassword !== '') {
      await axios(pwdconfig)
        .catch((e) => {
            setLoading(false);  
          if (e.response.data.data && e.response.data.data.old_password) {
            toast.error(e.response.data.data.old_password[0]);
          }
          if (e.response.data.data && e.response.data.data.non_field_errors) {
            toast.error(e.response.data.data.non_field_errors[0]);
          }
        })
        .then((response) => {
          if (response) {
            setLoading(false);
            toast.success("password Updated Successfully.");
            navigate('/profile');
          }
        });

    }else{
        setLoading(false);
        toast.error('Please fill all fields');
    }

  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isAuthenticated()) {
        navigate('/'); // Navigate to the homepage if the user is authenticated
      }
  }, [navigate]);
  return (
    <>
      {/*  */}
      <section className='py-4 color1BG'>
        <Col xxl={'9'} xl={'9'} lg={'10'} className='col-11 mx-auto'>
        <Card className='whiteBG mb-4' style={{ border: 'none', borderRadius: '24px', }}>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-96">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <Card.Body className='p-4 p-lg-5'>
                        <h6 className={'secondaryText fw-bold mb-0'} style={{ fontSize: '28px' }}>Update Password</h6>
                        <p>Please Update Your Password Before Access Your Dashboard</p>
                        <hr />
                        <Row className={''}>
                          <Col className={''} xxl={'12'} xl={'12'} lg={'12'}>
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Enter Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className={'mb-0 ps-2'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                            {/*  */}
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Set New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className={'mb-0 ps-2'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                            {/*  */}
                            <InputGroup className="mb-3">
                              <InputGroup.Text className='color1BG' style={{ border: 'none' }} id="basic-addon1"><img className='img-fluid' src={unlockicon} alt='' /></InputGroup.Text>
                              <Form.Control type={'password'} placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={'mb-0 ps-2'} aria-label="Username" aria-describedby="basic-addon1" />
                            </InputGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Button variant={''} className={'allView-btn'} onClick={handleSubmit} > Update</Button>
                      </Card.Body>
                    </>
                  )}
                </Card>
        </Col>
      </section>
    </>
  );
};

export default Profile;
