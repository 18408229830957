import React, { useState, useRef } from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { Col, Row, } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
// 

import vanImg from '../images/vanImg.png';
import SocialLinks from '../components/SocialLinks';

const BookingVoucherP = () => {
    const location = useLocation();
    const [bookingDetailsData] = useState(location.state ?? {});
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <main ref={componentRef}>

            <section className='secondaryBG py-xl-4 py-3 text-center'>
                <Col className='container-fluid'>
                    <Row className='w-100 d-flex align-items-center'>
                        <Col className='text-md-start text-center col-6'>
                            <h1 className='allTile text-white fw-bold FontFamilyRobotoCondensed' style={{ fontSize: '28px', }}>BOOKING CONFIRMATION</h1>
                            <Link to={'/'} className={'mainText d-block fw-semibold FontFamilyRobotoCondensed'}>www.limowide.com</Link>
                        </Col>
                        <Col className='text-md-end text-center col-6'>
                            <div className='d-flex align-items-center justify-content-center justify-content-md-end mb-2' style={{ gap: '10px' }}>
                                <SocialLinks />
                            </div>
                            <div className="d-flex justify-content-center justify-content-md-end ">
                                <button onClick={handlePrint} className=" mb-md-0 btn text-white text-uppercase" style={{ borderRadius: '80px', fontSize: '14px', border: 'solid 2px #fff' }}>Download PDF</button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </section>
            {/*  */}
            <section className='py-4 whiteBG'>
                <Col className='container-fluid'>
                    <p className='m-0'><strong>Dear {`${bookingDetailsData?.traveler?.passenger_name}`}</strong>, Your reservation with Limowide has been confirmed. The service is described in detail below. Please double-check the pickup instructions. </p>
                    <div className='mt-2'>
                        <div className='secondaryBG p-3 text-center'><h6 className='m-0 text-white fw-semibold FontFamilyRobotoCondensed'>Your Reservation Number: {bookingDetailsData.booking_id ?? ""}</h6></div>
                        <div className='p-lg-4 p-3 color1BG mb-4'>
                            <Row className={'FontFamilyRobotoCondensed d-flex'}>
                                <Col className='col-4' style={{}}>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>PICKUP POINT</h4>
                                        <p className='m-0'>{bookingDetailsData?.pickup || ""}</p>
                                    </div>
                                    {bookingDetailsData?.destination &&
                                        <div className='mb-1'>
                                            <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>DROPOFF POINT</h4>
                                            <p className='m-0'>{bookingDetailsData?.destination || " "} </p>
                                        </div>
                                    }
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>TRAVEL DATE</h4>
                                        <p className='m-0'>{bookingDetailsData?.arrival_human}</p>
                                    </div>
                                    {(bookingDetailsData?.return_date_time === null || bookingDetailsData?.return_date_time === 'null') ?
                                        <></>
                                        :
                                        <div className='mb-1'>
                                            <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>RETURN DATE</h4>
                                            <p className='m-0'>{bookingDetailsData?.return_date_time}</p>
                                        </div>
                                    }
                                    {(bookingDetailsData?.return_date_time === null || bookingDetailsData?.return_date_time === 'null') ?
                                        <></>
                                        :
                                        <div className='mb-1'>
                                            <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>RETURN BOOKING ID</h4>
                                            <p className='m-0'>{bookingDetailsData?.return_booking_id}</p>
                                        </div>
                                    }
                                    <div className='mb-1'>
                                    {bookingDetailsData?.destination ?
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>ESTIMATED DISTANCE</h4>
                                        :
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>KILOMETERS INCLUDED</h4>
                                    }
                                        <p className='m-0'>{bookingDetailsData?.distance ? `${bookingDetailsData.distance} ` : ""}</p>
                                    </div>
                                    <div className='mb-1'>
                                    {bookingDetailsData?.destination ?
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>ESTIMATED TRIP TIME</h4>
                                        :
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>SELECTED DURATION </h4>
                                    }
                                        <p className='m-0'>{bookingDetailsData?.duration ? `${bookingDetailsData?.duration} ` : ""}</p>
                                    </div>
                                    {bookingDetailsData?.destination ?
                                        <></>
                                        :
                                        <div className='mb-1'>
                                            <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>NOTE</h4>
                                            <p className='m-0'>Hourly or city tour bookings are available for local travel within city limits, with drop-offs required to be within the same boundaries.</p>
                                        </div> 
                                    }
                                </Col>
                                <Col className='col-4' style={{}}>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>NAME</h4>
                                        <p className='m-0'>{`${bookingDetailsData?.traveler?.passenger_name}`}</p>
                                    </div>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>MOBILE</h4>
                                        {!!bookingDetailsData.traveler?.passenger_mobile.length && (
                                            <p className='m-0'>
                                                Mob: {bookingDetailsData.traveler.passenger_mobile}
                                            </p>
                                        )}
                                    </div>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>NO OF PASSENGERS</h4>
                                        <p className='m-0'>{bookingDetailsData?.traveler?.num_passengers}</p>
                                    </div>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>NO OF LUGGAGES</h4>
                                        <p className='m-0'>{bookingDetailsData?.traveler?.num_bags}</p>
                                    </div>
                                    <div className=''>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>FLIGHT NO</h4>
                                        <p className='m-0'>#{bookingDetailsData?.traveler?.flight || ""}</p>
                                    </div>
                                </Col>
                                <Col className='col-4' style={{}}>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>SPECIAL INSTRUCTIONS</h4>
                                        <p className='m-0'> {bookingDetailsData?.traveler?.special_instructions ?? ""}</p>
                                    </div>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>VEHICLE CATEGORY</h4>
                                        <p className='m-0'>{bookingDetailsData?.traveler?.car_category.replace("_", " ")}</p>
                                    </div>
                                    <div className='mb-1'>
                                        <h4 className='fw-semibold blackText mb-1' style={{ fontSize: '12px' }}>FREE CANCELLATION</h4>
                                        {bookingDetailsData?.cancellationDetails?.cancellable && (
                                            <p className='m-0'>
                                                Until :{" "}
                                                {
                                                    bookingDetailsData.cancellationDetails
                                                        .freeCancellationUntil
                                                }
                                            </p>
                                        )}
                                    </div>
                                    <img className='img-fluid w-100' src={bookingDetailsData?.traveler?.fleet_image || vanImg} alt='icon' />
                                </Col>
                            </Row>
                        </div>
                        {/*  */}
                        <div className=''>
                            <h2 className='FontFamilyRobotoCondensed fw-semibold blackText mb-2' style={{}}>Pickup Instructions:</h2>
                            <ul className='FontFamilyRobotoCondensed m-0'>
                                <li style={{ fontSize: '14px' }}>Information you may use during your trip: Please remember to turn on and have your phone fully charged before the pickup. </li>
                                <li className='' style={{ fontSize: '14px' }}>If you cannot find your driver at the stated pickup place, please call the driver directly at the phone number provided in the SMS and email containing the driver and car details. If you cannot reach the driver, please contact Limowide at <strong>+44 7458 148595</strong>, <strong>+15854451333</strong>, <strong>+46766922152</strong>, <strong>+919884273784</strong>, or <strong>+493025559033</strong> </li>
                                <li className='' style={{ fontSize: '14px' }}>Two hours before your pickup, you will receive an SMS with driver and car information.<br />
                                    <strong>Note:</strong> Be informed that we track flights and the driver will arrive at the airport when your flight arrives; If your flight landing time changes, our driver will arrive at the airport appropriately; you do not need to do anything. </li>
                                <li style={{ fontSize: '14px' }}>Integrated Waiting Period:{Number(bookingDetailsData?.traveler?.wait_time) > 0 && bookingDetailsData?.traveler?.wait_time} minutes Beginning at the time of pickup as stated above, the driver will remain for the entire allotted waiting period. Please let your driver know in advance if you need more time to meet him or her (or call our emergency number). Be warned that additional fee may apply if you want driver to wait longer. </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </section>
            {/*  */}
            <section className='p-3 secondaryBG FontFamilyRobotoCondensed'>
                <Col className='container-fluid'>
                    <Row className='justify-content-between  d-flex'>
                        <Col lg={'6'} className='col-8 text-lg-start  mb-3 mb-lg-0'>
                            <h4 className='text-white fw-semibold' style={{ fontSize: '16px', }}>Limowide Limited</h4>
                            <p className='text-white m-0'>Address: 1 Peach Pl, Wokingham RG40 1LY, United Kingdom</p>
                        </Col>
                        <Col lg={'6'} className='col-4  text-lg-end'>
                            <p className='mb-0'><Link to={'tel:'} className='text-white FontFamilyRobotoCondensed'>Telephone:  +44 7458 148595</Link></p>
                            <p className='mb-0'><Link to={'mailto:'} className=' text-white FontFamilyRobotoCondensed'>Email: support@limowide.com</Link></p>
                        </Col>
                    </Row>
                </Col>
            </section>
        </main>
    )
}

export default BookingVoucherP;